/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Pagination from '../components/Pagination/Pagination';
import Paper from '@material-ui/core/Paper';
import { ActionsColumnFormatter } from './ActionColumnFormatter'
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import TipoVariaveisModal from "../../_metronic/layout/components/modal/TipoVariaveisModal";
import VariaveisValoresModal from '../../_metronic/layout/components/modal/VariaveisValoresModal';
import TagsJoinModal from '../../_metronic/layout/components/modal/TagsJoinModal';
import Loading from '../components/Loading/Loading';
import AutocompleteNew from '../../_metronic/layout/components/autocomplete/AutocompleteNew';
import ItemList from '../../_metronic/layout/components/ItemsList';
import Swal from 'sweetalert2';
import Api from '../services/Api';
import { checkModuleAuth } from '../@utils'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  textField: {
    flex: 1,
    marginRight: '15px',
  },
}));

export default function TiposVariaveis() {
  const [variaveisList, setVariaveisList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [input, setInput] = useState('');
  const [busca, setBusca] = useState({ per_page: 10, current_page: 1, filter_name: '' });
  const [modalShow, setModalShow] = useState(false);
  const [modalShowValues, setModalShowValues] = useState(false);
  const [modalVariable, setModalVariable] = useState(null);
  const [tagModalShow, setTagModalShow] = useState(false);
  const [tagUrl, setTagUrl] = useState('');
  const [tagsBusca, setTagsBusca] = useState([]);
  const noEditVariables = [4, 5, 7, 10];

  const user = useSelector(state => state.auth.user);

  const classes = useStyles();

  const history = useHistory();

  const handleEdit = (variavel) => {
    setModalVariable(variavel);
    setModalShow(true);
  };

  const handleEditValues = (variavel) => {
    setModalVariable(variavel);
    setModalShowValues(true);
  };

  const handleAdd = () => {
    setModalVariable(null);
    setModalShow(true);
  };

  const handleRemoveTag = (tag) => {
    const newTags = tagsBusca.filter((i) => i.id !== tag.id);
    setTagsBusca(newTags);
  };

  const handleChangeTag = (tag) => {
    const check = tagsBusca.filter((i) => i.id === tag.id);
    if (check.length > 0) return false;
    const newTags = [...tagsBusca, tag];
    setTagsBusca(newTags);
  };

  const handleTag = (item) => {
    setTagUrl(`/variables/${item.id}/tags`);
    setTagModalShow(true);
  };

  const handleBuscaLimpar = () => {
    setTagsBusca([]);
    setInput('');
    setBusca({ per_page: busca.per_page, current_page: 1, filter_name: '' });
  };

  const handleBuscaInput = (event) => {
    const value = event.target.value;
    setInput(value);
    setBusca({ ...busca, current_page: 1, filter_name: event.target.value });
  };

  const handleRemove = (item) => {
    Swal.fire({
      title: 'Atenção!',
      text: 'Tem certeza que deseja remover esta variável? Todos os dados vinculados serão excluídos.',
      icon: 'warning',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { status } = await Api.delete(`/variables/${item.id}`);
        if (status !== 204) {
          return Swal.fire({
            title: 'Atenção!',
            text: 'Aconteceu um erro na API, tente novamente mais tarde.',
            icon: 'error',
            confirmButtonText: 'Entendi',
          });
        }

        setBusca({ ...busca });
      }
    });
  };

  useEffect(() => {
    const fetchInitalData = async () => {
      const { status, data } = await Api.get('/variables', { params: { ...busca, tags: tagsBusca.map((i) => i.id).join(',') }});
      if (status !== 200) {
        return alert('Problema ao sincronizar as variáveis.');
      }
      setVariaveisList({ pagination: data.pagination, data: data.data.map((i) => ({ ...i, tags: [] })) });
      setLoading(false);
    };
    fetchInitalData();
  }, [busca, tagsBusca]);

  if (loading) return <Loading />

  return (
    <>
      <Card>
        <CardHeader title="Tipos de Variáveis">
          <CardHeaderToolbar>
            <div className="row">
              <div className="col-sm-6 col-12">
                <TextField
                  id="standard-name"
                  label="Busca texto"
                  className={classes.textField}
                  onChange={handleBuscaInput}
                  value={input}
                />
              </div>
              <div className="col-sm-6 col-12">
                <AutocompleteNew
                  handleChange={handleChangeTag}
                  endpoint="/tags/autocomplete"
                  label="Busca tag"
                  variant="4"
                />
              </div>
            </div>
            {user.user_level === 0 &&
              <button
                type="button"
                className="btn btn-primary ml-sm-3 mt-sm-0 mt-5"
                onClick={handleAdd}
              >
                Inserir
              </button>
            }
            {(tagsBusca.length > 0 || busca.filter_name !== '') && <button
              type="button"
              className="btn btn-secondary ml-3 mt-sm-0 mt-5"
              onClick={handleBuscaLimpar}
            >
              Limpar Busca
            </button>}
          </CardHeaderToolbar>
          {tagsBusca.length > 0 &&
            <ItemList
              variant="2"
              items={tagsBusca}
              handleRemove={handleRemoveTag}
              label="label_name"
              key="id"
            />
          }
        </CardHeader>
        <CardBody>
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>Código</TableCell>
                  {user.user_level === 0 && <TableCell>Sistema</TableCell>}
                  <TableCell>Valores</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {variaveisList.data && variaveisList.data.length > 0 ? variaveisList.data.map(variavel => (
                  <TableRow key={variavel.id}>
                    <TableCell component="th" scope="row"><Link to={{ pathname: '/variaveis/listar', query: { variable: variavel } }}>{user.user_level > 0 && variavel.variable_nickname !== null && variavel.variable_nickname !== '' ? variavel.variable_nickname : variavel.name}</Link></TableCell>
                    <TableCell component="th" scope="row">{variavel.code}</TableCell>
                    {user.user_level === 0 && <TableCell component="th" scope="row">{variavel.system_var ? 'Sim' : 'Não'}</TableCell>}
                    <TableCell>
                      {!noEditVariables.includes(variavel.type_id) && variavel.permission !== 'r' && <ActionsColumnFormatter hideHistoric hideRun hideRemove handleEdit={() => handleEditValues(variavel)} />}
                    </TableCell>
                    <TableCell>
                      <ActionsColumnFormatter
                        hideRun
                        showTag
                        hideEdit={!checkModuleAuth(user, 'variables', 'update')}
                        hideRemove={!checkModuleAuth(user, 'variables', 'delete') && user.user_level !== 0}
                        handleTag={() => handleTag(variavel)}
                        handleRemove={() => handleRemove(variavel)}
                        handleEdit={() => handleEdit(variavel)}
                        handleHistoric={ () => history.push({  pathname: '/historico-valores/listar', query: { variable: variavel } })}
                      />
                    </TableCell>
                  </TableRow>
                )) : <TableRow><TableCell colSpan={5} style={{ textAlign: 'center' }}>Nenhuma informação encontrada</TableCell></TableRow>}
              </TableBody>
              <TableFooter>
                {variaveisList.pagination &&
                  <TableRow><Pagination busca={busca} setBusca={setBusca} colSpan={5} {...variaveisList.pagination} /></TableRow>
                }
              </TableFooter>
            </Table>
          </Paper>
        </CardBody>
      </Card>
      <TagsJoinModal
        endpoint={tagUrl}
        label="Variáveis"
        open={tagModalShow}
        setOpen={setTagModalShow}
        />
      <TipoVariaveisModal open={modalShow} setOpen={setModalShow} variavel={modalVariable} user={user} busca={busca} setBusca={setBusca} />
      <VariaveisValoresModal open={modalShowValues} setOpen={setModalShowValues} variavel={modalVariable} user={user} />
    </>
  );
}
