import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core'
import { useLocation } from 'react-router-dom';
import Api from '../services/Api';

import {
  Card,
  CardHeader,
  CardHeaderToolbar
} from "../../_metronic/_partials/controls";
const useStyles = makeStyles(theme => ({
  root: {
    margin: (0, 0, 20, 20)
  },
}));
const SurveyDetails = () => {
  const [surveyDetails, setSurveyDetails] = useState({})
  const classes = useStyles();
  const { query } = useLocation();
  const { id } = query.survey;
  useEffect(() => {
    const fetchInitalData = async () => {
      const { status, data } = await Api.get(`/quizzes/${id}`);
      const getDetailsQuizz = status === 200;
      if (getDetailsQuizz) {
        setSurveyDetails(data)
      }
    };
    if (id) {
      fetchInitalData();
    }
    return () => {

    }
  }, [id])
  const Questions = ({ title, choices }) => {
    return <div className={classes.root}>
      <h3>{`${title}\n`}</h3>
      {choices && choices.map((pergunta => {
        return <h5>- {pergunta.name}</h5>
      }))}
    </div>
  }
  const { name, questions, points } = surveyDetails;
  return <Card>
    <CardHeader title={name}>
      <CardHeaderToolbar>
        <h3>{points} pontos</h3>
      </CardHeaderToolbar>
    </CardHeader>
    {questions && questions.map((item, index) => {
      return <Questions title={item.name} choices={item.options} />
    })}
  </Card>;
};



export default SurveyDetails;
