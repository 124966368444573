/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ModelosEquipamentosModal from '../../_metronic/layout/components/modal/ModelosEquipamentosModal';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Pagination from '../components/Pagination/Pagination';
import Paper from '@material-ui/core/Paper';
import { ActionsColumnFormatter } from './ActionColumnFormatter'
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import Loading from '../components/Loading/Loading';
import Swal from 'sweetalert2';
import Api from '../services/Api';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  textField: {
    flex: 1,
    marginRight: '15px',
  },
}));

export default function GrupoUsuarios() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [input, setInput] = useState('');
  const [busca, setBusca] = useState({ per_page: 10, current_page: 1, filter_name: '' });
  const [modalShow, setModalShow] = useState(false);
  const [modalModelosEquipamentos, setModalModelosEquipamentos] = useState(null);

  const classes = useStyles();

  const handleRemove = (item) => {
    Swal.fire({
      title: 'Atenção!',
      text: 'Tem certeza que deseja remover este modelo?',
      icon: 'warning',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { status, data } = await Api.delete(`/equipments-models/${item.id}`);
        if (status !== 204) {
          Swal.fire({
            title: 'Atenção!',
            text: 'Aconteceu um erro na API, tente novamente mais tarde.',
            icon: 'error',
            confirmButtonText: 'Entendi',
          });
          console.log(data);
        }

        setBusca({ ...busca });
      }
    });
  };

  const handleEdit = (item) => {
    setModalModelosEquipamentos(item);
    setModalShow(true);
  };

  const handleAdd = () => {
    setModalModelosEquipamentos(null);
    setModalShow(true);
  };

  const handleBuscaLimpar = () => {
    setInput('');
    setBusca({ per_page: busca.per_page, current_page: 1, filter_name: '' });
  };

  const handleBuscaInput = (event) => {
    const value = event.target.value;
    setInput(value);
    if (value.length > 3) {
      setBusca({ ...busca, current_page: 1, filter_name: event.target.value });
    }
    if (value.length === 0) {
      setBusca({ ...busca, current_page: 1, filter_name: '' });
    }
  };

  useEffect(() => {
    const fetchInitalData = async () => {
      const { status, data } = await Api.get('/equipments-models', { params: busca });
      if (status !== 200) {
        return alert('teve um problema');
      }

      setList(data);
      setLoading(false);
    };
    fetchInitalData();
  }, [busca]);

  if (loading) return <Loading />

  return (
    <>
      <Card>
        <CardHeader title="Modelos de Equipamentos">
          <CardHeaderToolbar>
            <TextField
              id="standard-name"
              label="Buscar"
              className={classes.textField}
              onChange={handleBuscaInput}
              value={input}
            />
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleAdd}
            >
              Inserir
            </button>
            {(busca.filter_name !== '') && <button
              type="button"
              className="btn btn-secondary ml-3"
              onClick={handleBuscaLimpar}
            >
              Limpar Busca
            </button>}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.data && list.data.length > 0 ? list.data.map(item => (
                  <TableRow key={item.id}>
                    <TableCell><Link to={{ pathname: '/equipamentos/listar', query: { model: item } }}>{item.name}</Link></TableCell>
                    <TableCell>{format(new Date(item.created_at), 'dd/MM/yyyy HH:mm:ss')}</TableCell>
                    <TableCell><ActionsColumnFormatter hideHistoric handleEdit={() => handleEdit(item)} handleRemove={() => handleRemove(item)} /></TableCell>
                  </TableRow>
                )) : <TableRow><TableCell colSpan={3} style={{ textAlign: 'center' }}>Nenhuma informação encontrada</TableCell></TableRow>}
              </TableBody>
              <TableFooter>
                {list.pagination &&
                  <TableRow><Pagination busca={busca} setBusca={setBusca} colSpan={3} {...list.pagination} /></TableRow>
                }
              </TableFooter>
            </Table>
          </Paper>
        </CardBody>
      </Card>
      <ModelosEquipamentosModal open={modalShow} setOpen={setModalShow} modelo={modalModelosEquipamentos} busca={busca} setBusca={setBusca} />
    </>
  );
}
