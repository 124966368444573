/* eslint-disable no-restricted-imports */
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Api from '../../../../app/services/Api';

import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
  },
  field: {
    width: '100%',
  },
  resultBox: {
    position: 'absolute',
    zIndex: 99999,
    marginTop: 5,
    width: '100%',
    maxHeight: 200,
    overflowY: 'auto',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
  },
  resultItem: {
    cursor: 'pointer',
    color: 'inherit',
    padding: 5,
    '&:hover': {
      backgroundColor: '#E4E6EF',
      color: 'inherit',
    },
  },
}));

const boldQuery = (str, query) => {
  const n = str.toUpperCase();
  const q = query.toUpperCase();
  const x = n.indexOf(q);
  if (!q || x === -1) {
      return str; // bail early
  }
  const l = q.length;
  return str.substr(0, x) + '<b>' + str.substr(x, l) + '</b>' + str.substr(x + l);
}

const Autocomplete = ({ label, endpoint, handleChange, variant, filter }) => {
  const [value, setValue] = useState('');
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const wrapperRef = useRef(null);

  const classes = useStyles();

  useEffect(() => {
    function handleClickOutside(event) {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setShow(false);
        }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
}, [wrapperRef]);

  const handleClick = (item, event) => {
    event.preventDefault();
    setShow(false);
    setValue('');
    return handleChange(item);
  }

  useEffect(() => {
    const fetchApiData = async () => {
      const { data, status } = await Api.get(endpoint, { params: { filter_keyword: value, ...filter } });

      setLoading(false);
      if (status !== 200) {
        console.log(data, status);
        return alert('Houve um erro!');
      }
      if (variant === '2') {
        setResult(data.map((i) => ({ label: `${i.equipment_name} > ${i.name} #${i.code}`, id: i.join_id, variable_id: i.id, equipment_id: i.equipment_id })))
      } else if (variant === '3') {
        setResult(data.map((i) => ({ id: i.id, label: `${i.name} - ${i.email}`, ...i })));
      } else if (variant === '4') {
        setResult(data.map((i) => ({ id: i.id, label: `${i.label_name}`, ...i })));
      } else {
        setResult(data.map((i) => ({ id: i.id, label: i.name })));
      }
    }
    fetchApiData();
  }, [value, endpoint, variant, filter]);

  return <div className={classes.container} ref={wrapperRef}>
    <TextField
      className={classes.field}
      id="standard-autocomplete-db"
      value={value}
      onChange={(event) => setValue(event.target.value)}
      onSelect={() => setShow(true)}
      label={label}
      autoComplete='off'
    />
    {show && !loading &&
      <div className={classes.resultBox}>
        {result.length > 0 ? result.map((item) =>
          <a href="#teste" key={item.id} className={classes.resultItem} onClick={(event) => handleClick(item, event)} dangerouslySetInnerHTML={ {__html: boldQuery(item.label, value)}} />
        ) :
          <p className={classes.resultItem}>
            Nenhum dado encontrado.
          </p>}
      </div>
    }
  </div>
};

export default Autocomplete;
