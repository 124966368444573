import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { actions } from './modules/Auth/_redux/authRedux';
import * as appActions from "./modules/Application/_redux/applicationActions";
import {Layout} from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { getUserById } from "./modules/Auth/_redux/authCrud";

export function Routes() {
  const dispatch = useDispatch();
  const user_id = localStorage.getItem('@sii-universe/user_id');
  const token = localStorage.getItem('@sii-universe/token');

  let {isAuthorized} = useSelector(
    ({ auth }) => ({
        isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  const verifyUser = async () => {
    const { data: user, status } = await getUserById(user_id, token.replaceAll('"', ''));
    if (status === 200) {
      const { tags, equipments, variables } = user;
      dispatch(actions.fulfillUser(user));
      dispatch(appActions.renewTag(tags));
      dispatch(appActions.renewEquipament(equipments.map((equipments) => {
        return { ...equipments, tags: equipments.tags.map((tag) => ({ id: tag })) };
      })));
      dispatch(appActions.renewVariable(variables.map((variables) => {
        return { ...variables, tags: variables.tags.map((tag) => ({ id: tag })) };
      })));
    } else {
      dispatch(actions.logout());
    }
  };

  if (isAuthorized) {
    verifyUser();
  }

  return (
      <Switch>
          {!isAuthorized ? (
              /*Render auth page when user at `/auth` and not authorized.*/
              <Route>
                  <AuthPage />
              </Route>
          ) : (
              /*Otherwise redirect to root page (`/`)*/
              <Redirect from="/auth" to="/"/>
          )}

          <Route path="/error" component={ErrorsPage}/>
          <Route path="/logout" component={Logout}/>


          {!isAuthorized ? (
              /*Redirect to `/auth` when user is not authorized*/
              <Redirect to="/auth/login"/>
          ) : (
              <Layout>
                  <BasePage/>
              </Layout>
          )}
      </Switch>
  );
}
