import Api from '../../app/services/Api';

export async function fetchUsers (setFunction) {
  const { status, data } = await Api.get('/users', { params: { per_page: 500000, current_page: 1, filter_name: '' } });
  if (status !== 200) {
    console.log(status, data);
    return alert('teve um problema');
  }
  setFunction(data.data.map((user) => ({ label: user.name, value: user.id }))
    .sort((a, b) => {
      const labelA = a.label.toLowerCase();
      const labelB = b.label.toLowerCase();
      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }
      return 0;
    }));
}