/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */

import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as authActions from '../modules/Auth/_redux/authRedux'; // eslint-disable-line
import { format } from 'date-fns';
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Pagination from '../components/Pagination/Pagination';
import Paper from '@material-ui/core/Paper';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import Loading from '../components/Loading/Loading';
import Swal from 'sweetalert2'; // eslint-disable-line
import Api from '../services/Api'; // eslint-disable-line
import { checkModuleAuth } from '../@utils'; // eslint-disable-line

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  textField: {
    flex: 1,
    marginRight: '15px',
    '&.margin-top-16': {
      marginTop: 16,
    }
  },
}));

export default function HistoricoTarefas() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false); // eslint-disable-line
  const [input, setInput] = useState('');
  const [busca, setBusca] = useState({
    per_page: 10,
    current_page: 1,
    filter_start_period: '',
    filter_end_period: '',
    filter_status: '',
  });

  const classes = useStyles();
  const user = useSelector(state => state.auth.user); // eslint-disable-line
  const dispatch = useDispatch(); // eslint-disable-line
  const location = useLocation();
  const history = useHistory();

  const handleBuscaLimpar = () => {
    setBusca({ per_page: busca.per_page, current_page: busca.current_page,  filter_start_period: '', filter_end_period: '', filter_status: '', });
  };

  const handleBuscaDataInicio = (event) => {
    // const date = format(new Date(event.target.value), 'dd/MM/yyyy HH:mm:ss');
    setBusca({ ...busca, filter_start_period: event.target.value });
  };

  const handleBuscaDataFim = (event) => {
    // const date = format(new Date(`${event.target.value} 23:59`), 'dd/MM/yyyy HH:mm:ss');
    setBusca({ ...busca, filter_end_period: event.target.value });
  };

  const handleBuscaStatus = (event) => {
    const value = event.target.value;
    setInput(value);
    setBusca({ ...busca, filter_status: value });
  };

  const handleReturn = () => {
    history.push({
      pathname: `/tarefas/listar`,
    });
  };

  useEffect(() => {
    const { idTarefa } = location.state;
    const fetchInitalData = async () => {
      const { status, data } = await Api.get(`tasks/${idTarefa}/task-histories`, { params: { ...busca }, });

      if (status !== 200) {
        return alert('teve um problema');
      }
      setList(data);
      setLoading(false);
    };
    fetchInitalData();
  }, [location, busca]);

  if (loading) return <Loading />

  return (
    <>
      <Card>
        <CardHeader title="Histórico de Tarefas">
          <CardHeaderToolbar>
            <TextField
              id="standard-name"
              label="Buscar"
              className={classes.textField}
              onChange={handleBuscaStatus}
              value={input}
            />
            <TextField
              id="standard-date-in"
              className={`${classes.textField} margin-top-16`}
              onChange={handleBuscaDataInicio}
              value={busca.filter_start_period}
              type="date"
            />
            <TextField
              id="standard-date-out"
              className={`${classes.textField} margin-top-16`}
              onChange={handleBuscaDataFim}
              value={busca.filter_end_period}
              type="date"
            />
            {(busca.filter_start_period !== '' || busca.filter_end_period !== '' || busca.filter_status !== '') && <button
              type="button"
              className="btn btn-secondary"
              onClick={handleBuscaLimpar}
              style={{ marginRight: 4 }}
            >
              Limpar Busca
            </button>}
            {checkModuleAuth(user, 'tasks', 'read') && <button
              type="button"
              className="btn btn-primary"
              onClick={handleReturn}
            >
              Voltar
            </button>}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>Data de execução</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Detalhes</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.data && list.data.length > 0 ? list.data.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">{item.task_name}</TableCell>
                    <TableCell component="th" scope="row">{item.end_execution_date ? format(new Date(item.end_execution_date), 'dd/MM/yyyy HH:mm') : '—'}</TableCell>
                    <TableCell component="th" scope="row">{item.status}</TableCell>
                    <TableCell component="th" scope="row">{item.observations ? item.observations : '—'}</TableCell>
                  </TableRow>
                )) : <TableRow><TableCell colSpan={4} style={{ textAlign: 'center' }}>Nenhuma informação encontrada</TableCell></TableRow>}
              </TableBody>
              <TableFooter>
                {list.pagination &&
                  <TableRow><Pagination busca={busca} setBusca={setBusca} colSpan={6} {...list.pagination} /></TableRow>
                }
              </TableFooter>
            </Table>
          </Paper>
        </CardBody>
      </Card>
    </>
  );
}
