
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from 'react';
import Autocomplete from '../../../_metronic/layout/components/autocomplete/Autocomplete';
import Api from '../../services/Api';

const useStyles = makeStyles((theme) => ({
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    width: '28%',
    '&:not(:last-of-type)': {
      marginRight: 29,
    },
    '&.margin-top-16': {
      marginTop: 16,
    },
    '&.full-width': {
      width: '100%',
    },
    '@media (max-width: 1024px)': {
      width: '100%',
      '&:not(:last-of-type)': {
        marginRight: 0,
      },
      '&.margin-top-16': {
        marginTop: 0,
      },
    },
  },
  resize: {
    fontSize: 16,
  },
}));

export const Tag = ({ actions, handleChange, handleChangeSelect, }) => {
  const classes = useStyles();
  const [tagList, setTagList] = useState([]);
  const [valores, setValores] = useState([]); // eslint-disable-line
  const textTypeVariable = [4, 5, 7, 10]; // eslint-disable-line

  useEffect(() => {
    const fetchApiData = async () => {
      const endpoint = '/tags';
      const { data, status } = await Api.get(endpoint);
      if (status !== 200) {
        console.log(data, status);
        return alert('Houve um erro!');
      }

      setTagList(data.data.map((i) => ({ label: `${i.tag_name}`, id: i.id, value: i.tag_name, user_id: i.user_id })))
    };

    fetchApiData();
  }, []);

  // useEffect(() => {
  //   const fetchApiData = async () => {
  //     // const endpoint = `/variables-instances/${actions.tag.id}/tags`;
  //     const endpoint = `/tags`;
  //     const { data, status } = await Api.get(endpoint);

  //     if (status !== 200) {
  //       console.log(data, status);
  //       return alert('Houve um erro!');
  //     }
  //     console.log(data) ;
  //   }
  //   if(typeof actions.tag === 'object') {
  //     fetchApiData();
  //   }
  // }, [actions.tag]);

  return (
    <>
      <br /><br />
      <Autocomplete
        options={tagList}
        value={tagList.find(item => item.value === actions.tag_id) }
        onChange={handleChangeSelect('tag_id', 'actions')}
        label="Tag"
        required
      />
      {actions.tag_id &&
      <>
        {/* <br /> */}
        <TextField
          id="standard-value"
          label="Valor"
          className={`${classes.textField} full-width`}
          type={actions.tag_id.type_id === 5 ? 'number' : 'text'}
          onChange={handleChange('value', 'actions')}
          value={actions.value}
          margin="normal"
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          required
        />
      </>
      }
      {/* {tag && !textTypeVariable.includes(tag.type_id) &&
      <>
        <Autocomplete
          options={valores}
          onChange={handleChangeSelect('valueTag', 'actions')}
          label="Valor"
          required
        />
      </>
      } */}
    </>
  )
};
