import Api from '../../../services/Api';

export const LOGIN_URL = "auth";
export const USER_URL = "users";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "/auth/forgot-password";

export const ME_URL = "api/me";

export function login(email, password) {
  return Api.post(LOGIN_URL, { email, password });
}

export function register(email, fullname, username, password) {
  return Api.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return Api.post(REQUEST_PASSWORD_URL, { email });
}

export async function getUserById(id, token = null) {
  if (token !== null) {
    Api.defaults.headers.Authorization = `Bearer ${token}`;
  }
  return Api.get(`${USER_URL}/${id}`);
}
