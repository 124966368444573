
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Autocomplete from '../../../_metronic/layout/components/autocomplete/Autocomplete';
import Api from '../../services/Api';

import { removeDuplicateObjectInArray } from '../../@utils';

const operations = [
  {
    id: 1,
    label: '> (maior que)',
    value: 'greater_than',
  },
  {
    id: 2,
    label: '< (menor que)',
    value: 'less_than',
  },
  {
    id: 3,
    label: '== (igual a)',
    value: 'equal',
  },
  {
    id: 4,
    label: '!= (diferente de)',
    value: 'not_equal',
  },
  {
    id: 5,
    label: '>= (maior que ou igual a)',
    value: 'greater_than_or_equal_to',
  },
  {
    id: 6,
    label: '<= (menor que ou igual a)',
    value: 'less_than_or_equal_to',
  },
];

const sameOrDifferentOperations =  [
  {
    id: 3,
    label: '== (igual a)',
    value: 'equal',
  },
  {
    id: 4,
    label: '!= (diferente de)',
    value: 'not_equal',
  },
];

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    marginBottom: 12,
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    width: '28%',
    '&:not(:last-of-type)': {
      marginRight: 29,
    },
    '&.margin-top-16': {
      marginTop: 16,
    },
    '&.full-width': {
      width: '100%',
    },
    '@media (max-width: 1024px)': {
      width: '100%',
      '&:not(:last-of-type)': {
        marginRight: 0,
      },
      '&.margin-top-16': {
        marginTop: 0,
      },
    },
  },
  resize: {
    fontSize: 16,
  },
}));

export const Conditional = ({ actions, handleChange, handleChangeSelect, }) => {
  const classes = useStyles();
  const [equipments, setEquipments] = useState([]);
  const [variables, setVariables] = useState([]);
  const [valores, setValores] = useState([]);
  const [changeVariable, setChangeVariable] = useState(false);
  const [loadingEquipment, setLoadingEquipment] = useState(false);
  const [loading, setLoading] = useState(false);
  const textTypeVariable = [4, 5, 7, 10];

  useEffect(() => {
    const fetchApiData = async () => {
      const endpoint = '/variables/instances';
      setLoadingEquipment(true);
      const { data, status } = await Api.get(endpoint, { params: { per_page: 10000 } });

      if (status !== 200) {
        console.log(data, status);
        return alert('Houve um erro!');
      }
      const equipments = data.data.map((equipment) => ({ label: equipment.equipment_name, value: equipment.equipment_id })).filter((i) => i);

      const newEquipments = removeDuplicateObjectInArray(equipments, 'value');
      setEquipments(newEquipments);
      setLoadingEquipment(false);
    }
    fetchApiData();
  }, []);

  useEffect(() => {
    const fetchApiData = async () => {
      const endpoint = '/variables/instances';
      setLoading(true);
      const { data, status } = await Api.get(endpoint, { params: { per_page: 10000 } });

      if (status !== 200) {
        console.log(data, status);
        return alert('Houve um erro!');
      }

      const variables = data.data.map((i) => i.equipment_id === actions.condition_equipment_id.value && ({ label: `${i.name} #${i.code}`, value: i.id, })).filter((i) => i);
      setVariables(variables.filter((i) => i));
      setLoading(false);
    }

    if(actions.condition_equipment_id) {
      fetchApiData();
    }
  }, [actions.condition_equipment_id])

  useEffect(() => {
    const fetchApiData = async () => {
      setChangeVariable(true);
      const { status, data } = await Api.get(`/variables/${actions.condition_variable_id.value}/values`);
      if (status !== 200) {
        return Swal.fire({
          title: 'Atenção!',
          text: 'Você não tem permissão para realizar esta ação.',
          icon: 'warning',
        });
      }
      setValores(data.map((i) => ({ ...i, label: i.name })));
      setChangeVariable(false);
    }
    if (actions.condition_variable_id) {
      fetchApiData();
    }
  }, [actions.condition_variable_id]);

  return (
    <>
      <h3 className={classes.title}>Condicional</h3>
      {loadingEquipment ? <p>CARREGANDO EQUIPAMENTOS</p> :
        <Autocomplete
            options={equipments}
            value={actions.condition_equipment_id && equipments.find(item => item.value === actions.condition_equipment_id.value)}
            onChange={handleChangeSelect('condition_equipment_id', 'actions')}
            label="Equipamento"
        />
      }
      {loading && actions.condition_equipment_id ? <><br /><p>CARREGANDO VARIÁVEIS</p></> : actions.condition_equipment_id && !loading &&
        <>
          <br />
          <Autocomplete
            options={variables}
            value={variables.find(item => item.value === actions.condition_variable_id) }
            onChange={handleChangeSelect('condition_variable_id', 'actions')}
            label="Variável"
            required
          />
          <br />
          <Autocomplete
            options={actions.condition_variable_id && textTypeVariable.includes(actions.condition_variable_id.type_id) && actions.condition_variable_id.type_id !== 5 ? sameOrDifferentOperations : operations}
            value={operations.find(item => item.value  === actions.condition_operation) }
            onChange={handleChangeSelect('condition_operation', 'actions')}
            label="Operação"
            required
          />
        </>
      }
      {actions.condition_variable_id && actions.condition_operation && textTypeVariable.includes(actions.condition_variable_id.type_id) &&
      <>
        <TextField
          id="standard-value"
          label="Valor"
          className={classes.textField}
          type={actions.variable_id.type_id === 5 ? 'number' : 'text'}
          onChange={handleChange('condition_value', 'actions')}
          value={actions.condition_value}
          margin="normal"
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          required
        />
      </>
      }
      {actions.condition_variable_id && actions.condition_operation && !textTypeVariable.includes(actions.condition_variable_id.type_id) &&
      <>
        <br />
        {!changeVariable &&
          <Autocomplete
            options={valores}
            value={valores.find(item => item.value === actions.condition_variable_value_id)}
            onChange={handleChangeSelect('condition_variable_value_id', 'actions')}
            label="Valor"
            required
          />
        }
      </>
      }
    </>
  )
};
