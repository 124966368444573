import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import Api from '../../../services/Api';

function Activation(props) {
  const [message, setMessage] = useState('');

  // Get parameters from url
  const search = new URLSearchParams(props.location.search)
  const token_activation = search.get('token_activation');

  useEffect(() => {

    async function activateUser() {
      try {
        const response = await Api.get(`/activation/${token_activation}`);

        if (response.status === 401 && response.data.code === 100009) {
          setMessage('Token inválido.');
        }

        if (response.status === 401 && response.data.code === 100010) {
          setMessage('Token expirado.');
        }

        if (response.status === 200) {
          setMessage('Usuário ativado com sucesso.');
        }

      } catch (error) {
        console.log(error);
      }
    }

    activateUser();

  }, [token_activation]);

  return (
    <>
      {
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">{message}</h3>
          </div>
          <div className="form-group d-flex flex-wrap flex-center">,
            <a href="/auth/login">
              <button
                className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
              >
                <span>Retornar</span>
              </button>
            </a>
          </div>
        </div>
      }
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Activation));
