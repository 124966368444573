import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect, useDispatch } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import { getUserById } from "../../../modules/Auth/_redux/authCrud";
import { actions } from '../../../modules/Auth/_redux/authRedux';
import * as appActions from "../../../modules/Application/_redux/applicationActions";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const dispatch = useDispatch();
  const { intl } = props;

  const [loading, setLoading] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [forgotPassSuccess, setForgotPassSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Formato de e-mail inválido")
      .min(3, "Mínimo de 3 caracteres")
      .max(50, "Máximo de 50 caracteres")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Mínimo de 3 caracteres")
      .max(50, "Máximo de 50 caracteres")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  useEffect(()=>{
    // Get parameters from url
    const search = new URLSearchParams(props.location.search)
    setResetSuccess(search.get('reset_password_sucess'));
    setForgotPassSuccess(search.get('forgot-password-success'));
  }, [props, resetSuccess])


  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      login(values.email, values.password).then(async ({ data, status }) => {
        disableLoading();
        if (status === 200) {
          localStorage.setItem('@sii-universe/token', JSON.stringify(data.token));
          localStorage.setItem('@sii-universe/user_id', JSON.stringify(data.id));
          const { data: user } = await getUserById(data.id, data.token);
          const { tags, equipaments, variables } = user;
          dispatch(actions.fulfillUser(user));
          dispatch(appActions.renewTag(tags));
          dispatch(appActions.renewEquipament(equipaments.map((equipament) => {
            return { ...equipament, tags: equipament.tags.map((tag) => ({ id: tag })) };
          })));
          dispatch(appActions.renewVariable(variables.map((variables) => {
            return { ...variables, tags: variables.tags.map((tag) => ({ id: tag })) };
          })));
        } else {
          setSubmitting(false);

          if (status === 403) {
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.USER_INACTIVE"
              })
            );
          }

          if (status === 401) {
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          }
        }
      }).catch(() => {
        disableLoading();
        setSubmitting(false);
        setStatus(
          intl.formatMessage({
            id: "GENERAL.ERROR.INTERNAL_ERROR",
          })
        );
      })
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Coloque seu e-mail e senha
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {resetSuccess && !formik.status && (
          <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
            <div className="alert-text font-weight-bold">Senha alterada com sucesso!</div>
          </div>
        )}
        {forgotPassSuccess && !formik.status && (
          <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
            <div className="alert-text font-weight-bold">E-mail de recuperação de senha enviado!</div>
          </div>
        )}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <div className="show-password-container">
            <input
              placeholder="Password"
              type={ showPassword ? "text" : "password" }
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "password"
              )}`}
              name="password"
              {...formik.getFieldProps("password")}
            />

            <span onClick={ () => setShowPassword(!showPassword) } className="login-show-password">
              { showPassword ? <VisibilityOff /> : <Visibility /> }
            </span>
          </div>

          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Entrar</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
