/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Modal } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Snackbar from '../snackbar/Snackbar';
import Autocomplete from '../autocomplete/Autocomplete';
import AutocompleteNew from '../autocomplete/AutocompleteNew';
import Swal from 'sweetalert2';
import Api from '../../../../app/services/Api';
import { fetchUsers } from '../../../_helpers';

import ItemList from '../ItemsList';
import { removeDuplicateObjectInArray } from '../../../../app/@utils';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  textField: {
    width: '40%',
    margin: theme.spacing(3),
  },
  filterText: {
    color: 'blue',
  },
  closeFilter: {
    color: 'red',
    fontWeight: 'bold',
  },
}));

export const phoneMaskInput = (value) => {
  const x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
  return !x[2] ? x[1] : `(${x[1]}) ${x[2] + (x[3] ? `-${x[3]}` : '')}`;
};

const AmbientesModal = ({ open, setOpen, busca, setBusca, ambiente }) => {
  const classes = useStyles();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [messageSnackbar] = useState('');
  const [variantSnackbar] = useState(false);
  const [filterEquipment, setFilterEquipment] = useState({});
  const [equipments, setEquipments] = useState([]);
  const [values, setValues] = useState({
    id: null,
    name: '',
    title: '',
    enviroment_type_id: null,
    description: '',
    variables: [],
    weak_token: '',
    ambient_list: {},
    user_id: 0,
  });
  const [environments_list, setEnvironments_list] = useState("")
  const [weak_token, setToken] = useState("");
  const [users, setUsers] = useState([]);

  const user = useSelector(state => state.auth.user);

  useEffect(() => {
    const fetchTypesEnvironments = async () => {
      const { data } = await Api.get('/enviroment-types');
      setEnvironments_list(data.data.map((i) => ({ id: i.id, label: i.name })));
    }
    const fetchInitalData = async (items) => {
      const { status } = await Api.get('/variables/equipments');
      if (status !== 200) {
        alert('Houve um erro!');
        return setOpen(false);
      }
    };
    const fetchVariablesInitialData = async (item) => {
      const { status, data } = await Api.get(`/enviroments/raw/${item.id}`);
      if (status !== 200) {
        alert('Houve um erro!');
        return setOpen(false);
      }

      const ambienteDto = {
        id: item.id,
        user_id: 0,
        name: item.name,
        title: item.title,
        description: item.description,
        variables: data.variables.map((i) => ({ label: `${i.equipment_name} > ${i.name} #${i.code}`, id: i.join_id, variable_id: i.id, equipment_id: i.equipment_id })),
        weak_token: data.weak_token
      };

      setValues(ambienteDto);
      setToken("");

      if (data.weak_token) {
        setToken(data.weak_token);
      }

      fetchInitalData(ambienteDto.variables.map((i) => i.id));
    };
    const fetchEquipments = async () => {
      const endpoint = "/equipments";

      const { data, status } = await Api.get(endpoint, {
        params: { per_page: 100000 }
      });

      if (status !== 200) {
        console.log(data, status);
        return alert("Houve um erro!");
      }
      const equipments = data.data
        .map(equipment => ({
          label: equipment.name,
          value: equipment.id
        }))
        .filter(i => i)
        .sort((a, b) => {
          const x = a.label.toUpperCase();
          const y = b.label.toUpperCase();
          return x === y ? 0 : x > y ? 1 : -1;
        });

      const newEquipments = removeDuplicateObjectInArray(equipments, "value");
      setEquipments(newEquipments);
    };

    if (open) {
      fetchTypesEnvironments();
      fetchEquipments();

      if (user?.user_level === 0) {
        fetchUsers(setUsers);
      }
      if (ambiente) {
        fetchVariablesInitialData(ambiente);
      } else {
        fetchInitalData();
        setValues({
          id: null,
          name: "",
          title: "",
          description: "",
          variables: [],
          weak_token: "",
          user_id: 0,
        });

        setToken("");
      }
    }
  }, [open]);

  const handleChangeSingle = (value) => {
    const checkVariables = values.variables.filter((i) => i.id === value.id);
    if (checkVariables.length === 0) {
      setValues({ ...values, variables: [...values.variables, value] });
    }
  }

  const handleChangeAmbient = (value) => {
    setValues({ ...values, "enviroment_type_id": value.id });
  }

  const handleFilter = filter => {
    const { value, label } = filter;
    setFilterEquipment({ filter_equipment_id: value, label: label });
  };

  const clearFilter = (ev) => {
    ev.preventDefault();
    setFilterEquipment({});
  }

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    const ambienteDto = {
      ...values,
      variables: values.variables.map((i) => ({ variable_id: i.variable_id, equipment_id: i.equipment_id })),
    };

    if (values.id === null) {
      const { status } = await Api.post('/enviroments', { ...ambienteDto });
      if (status !== 200) {
        return Swal.fire({
          title: 'Atenção!',
          text: 'Houve um problema na comunicação com a API, tente novamente mais tarde.',
          icon: 'warning',
        });
      }
      setBusca({ ...busca });
      setValues({
        id: null,
        name: '',
        title: '',
        enviroment_type_id: null,
        description: '',
        variables: [],
        weak_token: '',
      });
      return setOpen(false);
    }

    const { status } = await Api.put(`/enviroments/${ambiente.id}`, { ...ambienteDto, id: undefined });
    if (status !== 200) {
      return Swal.fire({
        title: 'Atenção!',
        text: 'Houve um problema na comunicação com a API, tente novamente mais tarde.',
        icon: 'warning',
      });
    }
  
    setBusca({ ...busca });
    return setOpen(false);
  }

  const handleRemove = (value) => {
    const newVariables = values.variables.filter((variable) => variable.id !== value.id);
    setValues({ ...values, variables: newVariables });
  };

  const generateToken = () => {
    let weak_token = Math.random().toString(36).replace('0.', '').substring(0, 6);
    setToken(weak_token);
    setValues({ ...values, weak_token });
  }

  const handleChangeOwner = (value) => {
    setValues({...values, user_id: value.value });
  }

  return <Modal show={open} size="lg">
    <Modal.Header closeButton>
      <Modal.Title>{values.id !== null ? 'Editar Ambiente' : 'Novo Ambiente'}</Modal.Title>
    </Modal.Header>
    <form onSubmit={handleSubmit}>
      <Modal.Body>
        <TextField
          id="standard-name"
          label="Nome"
          className={classes.textField}
          value={values.name}
          onChange={handleChange('name')}
          margin="normal"
          required
        />
        <TextField
          id="standard-title"
          label="Titulo"
          className={classes.textField}
          value={values.title}
          onChange={handleChange('title')}
          margin="normal"
          required
        />
        <TextField
          id="standard-description"
          label="Descrição"
          className={classes.textField}
          value={values.description}
          onChange={handleChange('description')}
          margin="normal"
          required
        />
        <div
          className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal"
          style={{ width: "40%", margin: "17px 23px", zIndex: 200 }}
        >
          <Autocomplete
            options={equipments}
            value={filterEquipment}
            onChange={handleFilter}
            label="Filtrar variáveis por Equipamento"
          />
        </div>
        <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal" style={{ width: '40%', margin: 23, zIndex: 100 }}>
          <AutocompleteNew
            handleChange={handleChangeSingle}
            label="Variáveis"
            endpoint="/variables/equipments/autocomplete"
            variant="2"
            filter={filterEquipment}
          />
        </div>

        <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal" style={{ width: '40%', marginLeft: 25, zIndex: 100 }}>
          <Autocomplete
            options={environments_list}
            onChange={handleChangeAmbient}
            label="Tipo de Ambiente"
          />
        </div>

        {
          user?.user_level === 0 && (<div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal" style={{ width: '40%', marginLeft: 25, zIndex: 100 }}>
            <Autocomplete
              options={users}
              onChange={handleChangeOwner}
              label="Dono do ambiente"
            />
          </div>)
        }

        {Object.keys(filterEquipment).length > 0 &&
          <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal" style={{ width: '40%', zIndex: 100, marginTop: "-15%", marginLeft: 25 }}>
            <br />
            <p className={classes.filterText}>Filtrando por {filterEquipment.label} <a href="#teste" onClick={(ev) => clearFilter(ev)} className={classes.closeFilter}>X</a></p>
          </div>}

        <div className="input-group mb-3" style={{ padding: '24px 76px 24px 24px' }}>
          <input type="text" className="form-control" placeholder="Gerar token de acesso rápido..." aria-label="Token"
            aria-describedby="basic-addon2" value={weak_token} disabled="disable" />
          <div className="input-group-append">
            <button className="btn btn-outline-secondary" type="button" onClick={generateToken}>
              Gerar Token
            </button>
          </div>
        </div>

        <Snackbar
          open={showSnackbar}
          setOpen={setShowSnackbar}
          message={messageSnackbar}
          variant={variantSnackbar}
        />
        <ItemList title="Variáveis" items={values.variables} handleRemove={handleRemove} label="label" key="id" />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="contained" color="secondary" className={classes.button} onClick={() => setOpen(false)}>Fechar</Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          type="submit"
        >{values.id !== null ? 'Salvar' : 'Adicionar'}</Button>
      </Modal.Footer>
    </form>
  </Modal>
};

export default AmbientesModal;
