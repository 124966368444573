
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from 'react';
import Autocomplete from '../../../_metronic/layout/components/autocomplete/Autocomplete';
import Api from '../../services/Api';
import { removeDuplicateObjectInArray } from '../../@utils';

export const VariableValueChange = ({ basicInformation, handleChangeSelect, handleChangeTags }) => {
  const [tagList, setTagList] = useState([]);
  const [variables, setVariables] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [loadingEquipment, setLoadingEquipment] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchApiData = async () => {
      const endpoint = '/variables/instances';
      setLoadingEquipment(true);
      const { data, status } = await Api.get(endpoint, { params: { per_page: 10000 } });

      if (status !== 200) {
        console.log(data, status);
        return alert('Houve um erro!');
      }
      const equipments = data.data.map((equipment) => ({ label: equipment.equipment_name, value: equipment.equipment_id })).filter((i) => i);
      const newEquipments = removeDuplicateObjectInArray(equipments, 'value');
      setEquipments(newEquipments);
      setLoadingEquipment(false);
    }
    fetchApiData();
  }, []);

  useEffect(() => {
    const fetchApiData = async () => {
      const endpoint = '/variables/instances';
      setLoading(true);
      const { data, status } = await Api.get(endpoint, { params: { per_page: 10000 } });

      if (status !== 200) {
        console.log(data, status);
        return alert('Houve um erro!');
      }

      const variables = data.data.map((i) => i.equipment_id === basicInformation.equipment_id.value && ({ label: `${i.name} #${i.code}`, equipment_id: i.equipment_id, value: i.id, })).filter((i) => i);
      setVariables(variables.filter((i) => i));
      setLoading(false);
    }

    if(basicInformation.equipment_id) {
      fetchApiData();
    }
  }, [basicInformation.equipment_id])

  useEffect(() => {
    const fetchApiData = async () => {
      const endpoint = '/tags';
      const { data, status } = await Api.get(endpoint, { params: { per_page: 10000 } });
      if (status !== 200) {
        console.log(data, status);
        return alert('Houve um erro!');
      }
      setTagList(data.data.map((i) => ({ label: `${i.tag_name}`, value: i.id, user_id: i.user_id })))
    };

    fetchApiData();
  }, []);

  return (
    <>
      {basicInformation.trigger_type === 'changeVariableValueViaTags' &&
        <Autocomplete
          isMulti
          options={tagList}
          value={basicInformation.tag_ids}
          onChange={handleChangeTags}
          label="Tags"
        />
      }

      {basicInformation.trigger_type === 'variable_value' &&
      <>
        {loadingEquipment ? <p>CARREGANDO EQUIPAMENTOS</p> :
          <Autocomplete
            options={equipments}
            value={basicInformation.equipment_id && equipments.find(item => item.value === basicInformation.equipment_id.value)}
            onChange={handleChangeSelect('equipment_id', 'basicInformation')}
            label="Equipamento"
          />
        }
        {loading && basicInformation.equipment_id ? <><br /><br /><p>CARREGANDO VARIÁVEIS</p></> : basicInformation.equipment_id && !loading &&
        <>
          <br /><br />
          {variables.length ?
            <Autocomplete
              options={variables}
              value={basicInformation.variable_id && variables.find(item => item.value === basicInformation.variable_id.value)}
              onChange={handleChangeSelect('variable_id', 'basicInformation')}
              label="Variável"
            />
          : <p>Não existem variáveis para esse equipamento</p>}
        </>
        }
      </>
      }
    </>
  )
};
