const dataSeries = [
  [{
      "date": "2020-09-01",
      "value": 20000000
    },
    {
      "date": "2020-09-02",
      "value": 10379978
    },
    {
      "date": "2020-09-03",
      "value": 30493749
    },
    {
      "date": "2020-09-04",
      "value": 10785250
    },
    {
      "date": "2020-09-05",
      "value": 33909904
    },
    {
      "date": "2020-09-06",
      "value": 11576838
    },
    {
      "date": "2020-09-07",
      "value": 14413854
    },
    {
      "date": "2020-09-08",
      "value": 15177211
    },
    {
      "date": "2020-09-09",
      "value": 16622100
    },
    {
      "date": "2020-09-10",
      "value": 17381072
    },
    {
      "date": "2020-09-11",
      "value": 18802310
    },
    {
      "date": "2020-09-12",
      "value": 15531790
    },
    {
      "date": "2020-09-13",
      "value": 15748881
    },
    {
      "date": "2020-09-14",
      "value": 18706437
    },
    {
      "date": "2020-09-15",
      "value": 19752685
    },
    {
      "date": "2020-09-16",
      "value": 21096418
    },
    {
      "date": "2020-09-17",
      "value": 25622924
    },
    {
      "date": "2020-09-18",
      "value": 25337480
    },
    {
      "date": "2020-09-19",
      "value": 22258882
    },
    {
      "date": "2020-09-20",
      "value": 23829538
    },
    {
      "date": "2020-09-21",
      "value": 24245689
    },
    {
      "date": "2020-09-22",
      "value": 26429711
    },
    {
      "date": "2020-09-23",
      "value": 26259097
    },
    {
      "date": "2020-09-24",
      "value": 25396183
    },
    {
      "date": "2020-09-25",
      "value": 23107346
    },
    {
      "date": "2020-09-26",
      "value": 28659852
    },
    {
      "date": "2020-09-27",
      "value": 25270783
    },
    {
      "date": "2020-09-28",
      "value": 26270783
    },
    {
      "date": "2020-09-29",
      "value": 27270783
    },
    {
      "date": "2020-09-30",
      "value": 28270783
    }
  ]
]

export default dataSeries;