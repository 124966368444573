import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserById } from "./authCrud";
import Api from '../../../services/Api';

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  RenewTag: "[RenewTag] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API"
};

const initialAuthState = {
  user: undefined,
  renewTags: true,
  authToken: undefined
};

export const reducer = persistReducer(
  { storage, key: "v709-demo1-auth", whitelist: ["authToken", "user"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.RenewTag: {
        const { renewTags } = action.payload;
        return { ...state, renewTags };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        Api.defaults.headers.Authorization = '';
        localStorage.removeItem('@sii-universe/token');
        localStorage.removeItem('@sii-universe/user_id');
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: id => ({ type: actionTypes.Login, payload: { id } }),
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken }
  }),
  renewTag: renewTags => ({ type: actionTypes.RenewTag, payload: { renewTags } }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: id => ({ type: actionTypes.UserRequested, payload: { id } }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } })
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga({ payload }) {
    yield put(actions.requestUser(payload.id));
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested({ payload }) {
    const { data: user } = yield getUserById(payload.id);
    yield put(actions.fulfillUser(user));
  });
}
