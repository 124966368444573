export const checkModuleAuth = (user, module_name, permission_name) => {
  let check = false;
  const { user_level, modules } = user;
  if (user_level < 2) return true;
  if (!modules || modules.length === 0) return false;
  modules.forEach((module) => {
    if (module.module === module_name && module.permissions.includes(permission_name)) check = true;
  });
  return check;
};

export const removeDuplicateObjectInArray = (array, key) => {
  const newArray = [];

  array.forEach((item) => {
    const duplicated  = newArray.findIndex(redItem => {
      return item[key] === redItem[key];
    }) > -1;

    if(!duplicated) {
      newArray.push(item);
    }
  });

  return newArray;
};
