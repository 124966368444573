
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import Autocomplete from '../../../_metronic/layout/components/autocomplete/Autocomplete';
import Api from '../../services/Api';

const actionTask = [
  {
    id: 1,
    label: 'Habilitar',
    value: 'enable',
  },
  {
    id: 0,
    label: 'Desabilitar',
    value: 'disable',
  },
  {
    id: 2,
    label: 'Executar',
    value: 'execute',
  },
];

export const AutocompleteTask = ({ actions, handleChangeSelect }) => {
  const [tasks, setTasks] = useState([]);
  useEffect(() => {
    const fetchApiData = async () => {
      const endpoint = '/tasks';
      const { data, status } = await Api.get(endpoint, { per_page: 100000 });
      if (status !== 200) {
        console.log(data, status);
        return alert('Houve um erro!');
      }

      setTasks(data.data.map((i) => ({ label: `${i.name}`, value: i.id })))
    };

    fetchApiData();
  }, []);
  return (
    // <AutocompleteNew
    //   handleChange={handleChange}
    //   label={label ? label : "Tarefas"}
    //   endpoint="/tasks/autocomplete"
    //   variant="2"
    // />
    <>
      <br />
      <Autocomplete
        options={tasks}
        value={tasks.find(item => item.value === actions.action_task_id) }
        onChange={handleChangeSelect('action_task_id', 'actions')}
        label="Tarefa"
        required
      />
      {actions.action_task_id &&
      <>
        <br /><br />
        <Autocomplete
          options={actionTask}
          onChange={handleChangeSelect('task_action_type', 'actions')}
          value={actionTask.find(item => item.value === actions.task_action_type) }
          label="Ação na tarefa"
          required
        />
      </>
      }
    </>
  )
};
