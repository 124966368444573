/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}));

const Loading = () => {
  const [show, setShow] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 100);
  }, []);

  if (!show) return <></>;

  return <div className={classes.root}>Carregando</div>
};

export default Loading;
