/* eslint-disable no-restricted-imports */
import { FormControlLabel, Switch } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import Autocomplete from '../../../_metronic/layout/components/autocomplete/Autocomplete';
import { Scheduling } from './Scheduling';
import { VariableValueChange } from "./VariableValueChange";

const useStyles = makeStyles(theme => ({
  textField: {
    marginBottom: theme.spacing(3),
    '&.width-48': {
      width: '48%',
      marginTop: theme.spacing(3),
    },
    '&.no-margin-top': {
      marginTop: 0,
    },
    '@media (max-width: 1024px)': {
      width: '100%',
      '&.width-48': {
        width: '100%',
      },
    },
  },
  resize: {
    fontSize: 16,
  },
}));

const actionMode = [
  {
    id: 1,
    label: 'Alteração de valor de variável específica',
    value: 'variable_value',
  },
  {
    id: 2,
    label: 'Alteração de valor de variável via tags',
    value: 'changeVariableValueViaTags',
  },
  {
    id: 3,
    label: 'Agendamento',
    value: 'scheduled',
  },
  {
    id: 4,
    label: 'Manual',
    value: 'manual',
  }
];

export const BasicInformation = ({ basicInformation, handleChange, handleChangeSelect, handleEnabledSwitch, handleChangeWeekDays, handleChangeTags }) => {
  const classes = useStyles();

  return (
    <>
      <TextField
        id="standard-name"
        label="Nome"
        className={classes.textField}
        value={basicInformation.name}
        onChange={handleChange('name', 'basicInformation')}
        margin="normal"
        autoComplete="off"
        InputProps={{
          classes: {
            input: classes.resize,
          },
        }}
        required
      />
      <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal" style={{ zIndex: 100 }}>
        <Autocomplete
          options={actionMode}
          value={actionMode.find(item => item.value === basicInformation.trigger_type)}
          onChange={handleChangeSelect('trigger_type', 'basicInformation')}
          label="Modo de acionamento"
          required
        />
      </div>
      {['variable_value', 'changeVariableValueViaTags'].includes(basicInformation.trigger_type) && <>
        <br /><br />
        <VariableValueChange
          basicInformation={basicInformation}
          handleChange={handleChange}
          handleChangeSelect={handleChangeSelect}
          handleChangeTags={handleChangeTags}
        />
        <br />
      </>}
      {basicInformation.trigger_type === 'scheduled' &&
      <>
        <br /><br />
        <Scheduling
          basicInformation={basicInformation}
          handleChange={handleChange}
          handleChangeSelect={handleChangeSelect}
          handleChangeWeekDays={handleChangeWeekDays}
        />
      </>
      }
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <FormControlLabel
          control={<Switch checked={basicInformation.enabled} onChange={handleEnabledSwitch('enabled', 'basicInformation')} />}
          label="Habilitada"
        />
      </div>
    </>
  );
};
