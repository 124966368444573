/* eslint-disable no-restricted-imports */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(() => ({
  header: {
    fontSize: 16,
  },
  headerInline: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  wrapper: {
    width: '100%',
  },
  wrapperInline: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    margin: 10,
    flexWrap: 'wrap',
    '&.wrap': {
      flexWrap: 'wrap',
    }
  },
  item: {
    backgroundColor: '#E4E6EF',
    borderRadius: 7,
    margin: 4,
    padding: '4px 8px',
  },
  close: {
    fontWeight: 'bold',
    color: 'red!important',
    backgroundColor: 'transparent',
    border: 'none'
  },
  noItem: {
    margin: 'auto',
  },
}));

const ItemList = ({ title, items, inLine = false, handleRemove, label, key, variant = '1', containerWrap }) => {

  const classes = useStyles();

  return (
    <div className={inLine ? classes.wrapperInline : classes.wrapper}>
      {inLine ? <span className={classes.headerInline}>{title}:</span> : <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.header}>{title}</TableCell>
          </TableRow>
        </TableHead>
      </Table>}
      <div className={containerWrap ? `${classes.container} wrap` : classes.container}>
        {items?.length > 0 ?
          items.map((i) => (
            <div className={classes.item} key={i[key]}>
              {i[label]}
              {(variant === '1' || !i.system_var) && <button
                title="Deletar"
                className={classes.close}
                onClick={() => handleRemove(i)}
                style={{ marginLeft: 6 }}
              >
                X
              </button>}

            </div>
          ))
          :
          <div className={classes.noItem}>Nenhum item adicionado.</div>
        }

      </div>
    </div>
  );
};

export default ItemList;
