import axios from 'axios';

const url = process.env.REACT_APP_API_URL || 'http://localhost:3000';

const API = axios.create({
  baseURL: url,
  responseType: 'json',
});

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (typeof err.response === 'undefined') {
      return 'die';
    }
    return err.response;
  }
);

export default API;
