/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useDispatch } from 'react-redux';
import * as authActions from '../../../../../app/modules/Auth/_redux/authRedux';
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { checkModuleAuth } from "../../../../../app/@utils";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };
  const user = useSelector(state => state.auth.user);
  const renewTags = useSelector(state => state.auth.renewTags);
  const dispatch = useDispatch();

  useEffect(() => {
    if (renewTags) {
      dispatch(authActions.actions.renewTag(false));
    }
  }, [renewTags]);

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-4-blocks.svg")}/>
            </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>
          {checkModuleAuth(user, 'enviroments', 'read') && <li
              className={`menu-item ${getMenuItemActive("/ambientes/listar", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/ambientes/listar">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Curtains.svg")}/>
            </span>
              <span className="menu-text">Ambientes</span>
            </NavLink>
          </li>}
          {user.user_level === 0 && <li
              className={`menu-item ${getMenuItemActive("/modelos-equipamentos/listar", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/modelos-equipamentos/listar">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/CPU2.svg")}/>
            </span>
              <span className="menu-text">Modelos de equipamentos</span>
            </NavLink>
          </li>}
          {checkModuleAuth(user, 'equipments', 'read') && <li
              className={`menu-item ${getMenuItemActive("/equipamentos/listar", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/equipamentos/listar">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/CPU1.svg")}/>
            </span>
              <span className="menu-text">Equipamentos</span>
            </NavLink>
          </li>}
          {checkModuleAuth(user, 'groups', 'read') && <li
              className={`menu-item ${getMenuItemActive("/grupos-usuarios/listar", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/grupos-usuarios/listar">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}/>
            </span>
              <span className="menu-text">Grupos de Usuários</span>
            </NavLink>
          </li>}
          {checkModuleAuth(user, 'users', 'read') && <li
              className={`menu-item ${getMenuItemActive("/usuarios/listar", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/usuarios/listar">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
              <span className="menu-text">Usuários</span>
            </NavLink>
          </li>}
          {checkModuleAuth(user, 'variables', 'read') && <li
              className={`menu-item ${getMenuItemActive("/tipos-variaveis/listar", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/tipos-variaveis/listar">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Compilation.svg")}/>
            </span>
            <span className="menu-text">Tipos de Variáveis</span>
          </NavLink>
        </li>}
        {checkModuleAuth(user, 'variables-instances', 'read') && <li
          className={`menu-item ${getMenuItemActive("/variaveis/listar", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/variaveis/listar">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Difference.svg")}/>
            </span>
            <span className="menu-text">Variáveis</span>
          </NavLink>
        </li>}
        {checkModuleAuth(user, 'tags', 'read') && <li
          className={`menu-item ${getMenuItemActive("/tags/listar", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/tags/listar">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Price1.svg")}/>
            </span>
            <span className="menu-text">Tags</span>
          </NavLink>
        </li>}
        {checkModuleAuth(user, 'historic-values', 'read') && <li
          className={`menu-item ${getMenuItemActive("/historico-valores/listar", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/historico-valores/listar">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-line2.svg")}/>
            </span>
            <span className="menu-text">Histórico de valores</span>
          </NavLink>
        </li>}
        {checkModuleAuth(user, 'historic-system', 'read') && <li
          className={`menu-item ${getMenuItemActive("/historico-acoes/listar", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/historico-acoes/listar">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Clipboard-list.svg")}/>
            </span>
              <span className="menu-text">Histórico de ações</span>
            </NavLink>
          </li>}
        {checkModuleAuth(user, 'tasks', 'read') && <li
          className={`menu-item ${getMenuItemActive("/tarefas/listar", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/tarefas/listar">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Folder.svg")} />
            </span>
            <span className="menu-text">Tarefas</span>
          </NavLink>
        </li>}
        {user.user_level === 0 && <li
          className={`menu-item ${getMenuItemActive("/questionario/listar", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/questionario/listar">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/Usb-storage.svg")} />
            </span>
            <span className="menu-text">Questionários</span>
          </NavLink>
        </li>}
        </ul>

        {/* end::Menu Nav */}
      </>
  );
}
