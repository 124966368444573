/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../_metronic/_partials/controls";
import { checkModuleAuth } from '../@utils';
import Loading from '../components/Loading/Loading';
import Pagination from '../components/Pagination/Pagination';
import Api from '../services/Api';
import { ActionsColumnFormatter } from './ActionColumnFormatter';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  textField: {
    flex: 1,
    marginRight: '15px',
  },
}));


export default function Tarefas() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [input, setInput] = useState('');
  const [busca, setBusca] = useState({ per_page: 10, current_page: 1, filter_name: '' });

  const classes = useStyles();
  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch(); // eslint-disable-line
  const history = useHistory();

  const handleBuscaLimpar = () => {
    setInput('');
    setBusca({ per_page: busca.per_page, current_page: busca.current_page, filter_name: '' });
  };

  const handleBuscaInput = (event) => {
    const value = event.target.value;
    setInput(value);
    if (value.length > 3) {
      setBusca({ ...busca, filter_name: event.target.value });
    }
    if (value.length === 0) {
      setBusca({ ...busca, filter_name: '' });
    }
  };

  const taskTypeFormatting = (type) => {
    if(type === 'variable_value') {
      return 'Mudança de variável';
    }

    if(type === 'scheduled') {
      return 'Agendamento';
    }

    return 'Manual';
  };

  const formatWeekDays = (days) => {
    return days.split(',').map((day) => {
      if(day === '1') return 'Do';
      if(day === '2') return 'Sg';
      if(day === '3') return 'Te';
      if(day === '4') return 'Qa';
      if(day === '5') return 'Qi';
      if(day === '6') return 'Sx';
      return 'Sa';
    }).join(',');
  };

  const formatTypeScheduling = (type) => {
    if(type === 'no_repetition') return 'Não se repete';
    if(type === 'daily') return 'Todos os dias';
    if(type === 'weekly') return 'Semanal';
  };

  const dateFormatting = (dateNotFormatted, time) => {
    dateNotFormatted = dateNotFormatted.replace(/Z/g,'');
    const date = new Date(dateNotFormatted);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return format(new Date(`${year},${month},${day} ${time}`), 'dd/MM/yyyy HH:mm')
  };

  const hourFormatting = (hour) => {
    return hour.substring(0,5);
  };

  const formattingDayTimeExecution = (item) => {
    return item.schedule_date && item.execution_time ?
    dateFormatting(item.schedule_date, item.execution_time)
    : item.schedule_type === 'daily' && item.execution_time ?
    hourFormatting(item.execution_time)
    : item.schedule_days && item.execution_time ?
    `${formatWeekDays(item.schedule_days)} ${hourFormatting(item.execution_time)}`
    : item.execution_time ?
    hourFormatting(item.execution_time)
    : '—'
  };

  const handleRemove = (item) => {
    Swal.fire({
      title: 'Atenção!',
      text: 'Tem certeza que deseja remover esta tarefa? Todos os dados vinculados a ela serão excluídos.',
      icon: 'warning',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { status, data } = await Api.delete(`/tasks/${item.id}`);
        if (status !== 204) {
          Swal.fire({
            title: 'Atenção!',
            text: 'Aconteceu um erro na API, tente novamente mais tarde.',
            icon: 'error',
            confirmButtonText: 'Entendi',
          });
          console.log(data);
        }

        setBusca({ ...busca });
      }
    });
  };

  const handleHistoric = (item) => {
    history.push({
      pathname: '/historico-tarefas/listar',
      state: {
        idTarefa: item.id,
      },
    });
  };

  const handleRun = (item) => {
    Swal.fire({
      title: 'Atenção!',
      text: 'Tem certeza que deseja executar esta tarefa?',
      icon: 'success',
      confirmButtonText: 'Executar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { status, data } = await Api.post(`/tasks/${item.id}/execute`);
        if (status !== 200) {
          Swal.fire({
            title: 'Atenção!',
            text: 'Aconteceu um erro na API, tente novamente mais tarde.',
            icon: 'error',
            confirmButtonText: 'Entendi',
          });
          console.log(data);
        }

        setBusca({ ...busca });
      }
    });
  };

  const handleAdd = () => {
    history.push({
      pathname: `/nova-tarefa`,
    });
  };

  const handleEdit = (tarefa) => {
    history.push({
      pathname: '/editar-tarefa',
      state: {
        idTarefa: tarefa.id,
      },
    });
  };

  useEffect(() => {
    const fetchInitalData = async () => {
      const { status, data } = await Api.get('/tasks', { params: busca });
      if (status !== 200) {
        return alert('teve um problema');
      }
      setList(data);
      setLoading(false);
    };
    fetchInitalData();
  }, [busca]);

  if (loading) return <Loading />

  return (
    <>
      <Card>
        <CardHeader title="Tarefas">
          <CardHeaderToolbar>
            <TextField
              id="standard-name"
              label="Buscar"
              className={classes.textField}
              onChange={handleBuscaInput}
              value={input}
            />
            {checkModuleAuth(user, 'tasks', 'create') && <button
              type="button"
              className="btn btn-primary"
              onClick={handleAdd}
            >
              Inserir
            </button>}
            {(busca.filter_name !== '') && <button
              type="button"
              className="btn btn-secondary ml-3"
              onClick={handleBuscaLimpar}
            >
              Limpar Busca
            </button>}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>Tipo</TableCell>
                  <TableCell>Dia e Hora de Execução</TableCell>
                  <TableCell>Habilitado</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list && list.data.length > 0 ? list.data.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">{item.name}</TableCell>
                    {item.trigger_type === 'scheduled' ?
                      <TableCell component="th" scope="row">{taskTypeFormatting(item.trigger_type)} - {formatTypeScheduling(item.schedule_type)}</TableCell>
                      :
                      <TableCell component="th" scope="row">{taskTypeFormatting(item.trigger_type)}</TableCell>
                    }
                    <TableCell component="th" scope="row">
                      {formattingDayTimeExecution(item)}
                    </TableCell>
                    <TableCell component="th" scope="row">{item.enabled ? 'Sim' : 'Não'}</TableCell>
                    <TableCell>
                      <ActionsColumnFormatter
                        hideEdit={!checkModuleAuth(user, 'tasks', 'update') || item.system_var === 1}
                        hideRemove={!checkModuleAuth(user, 'tasks', 'delete') || item.system_var === 1}
                        hideHistoric={!checkModuleAuth(user, 'tasks', 'historic') || item.system_var === 1}
                        hideRun={!checkModuleAuth(user, 'tasks', 'run') || item.system_var === 1}
                        handleEdit={() => handleEdit(item)}
                        handleRemove={() =>  handleRemove(item)}
                        handleHistoric={() =>  handleHistoric(item)}
                        handleRun={() =>  handleRun(item)}
                      />
                    </TableCell>
                  </TableRow>
                )) : <TableRow><TableCell colSpan={4} style={{ textAlign: 'center' }}>Nenhuma informação encontrada</TableCell></TableRow>}
              </TableBody>
              <TableFooter>
                {list.pagination &&
                  <TableRow><Pagination busca={busca} setBusca={setBusca} colSpan={6} {...list.pagination} /></TableRow>
                }
              </TableFooter>
            </Table>
          </Paper>
        </CardBody>
      </Card>
      {/* <TarefasModal open={modalShow} setOpen={setModalShow} tarefa={modalTarefa} busca={busca} setBusca={setBusca} /> */}
    </>
  );
}
