import React, { useState, useEffect, useMemo } from 'react';
import { Table, TableBody, TableHead, TableRow, TableCell, TableFooter, Paper, makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom';
import Api from '../services/Api';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../_metronic/_partials/controls";
import SurveyModal from '../../_metronic/layout/components/modal/SurveyModal';

import { ActionsColumnFormatter } from './ActionColumnFormatter'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  textField: {
    flex: 1,
    marginRight: '15px',
  },
  statusButtonGreen: {
    width: 24,
    height: 24,
    borderRadius: 24,
    backgroundColor: 'green',
  },
  statusButtonRed: {
    width: 24,
    height: 24,
    borderRadius: 24,
    backgroundColor: 'red',
  },
}));


const Surveys = () => {
  const [modalShow, setModalShow] = useState(false);
  const [quizzes, setQuizzes] = useState([])
  const fetchData = async () => {
    const { status, data } = await Api.get('/quizzes');
    const getQuizzes = status === 200;
    if (getQuizzes) {
      setQuizzes(data)
    }
  }

  useEffect(() => {
    fetchData();
    return () => {
      setQuizzes([])
    }
  }, []);

  useMemo(() => {
    if (!modalShow) {
      fetchData()
    }
  }, [modalShow])

  const classes = useStyles();

  const handleRemove = async (id) => {
    const { status } = await Api.delete(`/quizzes/${id}`);
    const hasDeleted = status === 204;
    if (hasDeleted) {
      fetchData();
    }
  }
  const handleAdd = () => {
    setModalShow(true);
  };
  return <>
    <Card>
      <CardHeader title="Questionários">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary ml-sm-3 mt-sm-0 mt-5"
            onClick={() => handleAdd()}
          >
            Inserir
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Título</TableCell>
                <TableCell>Puntuação</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quizzes.length > 0 ? quizzes.map((questionario) => (
                <TableRow key={questionario.id}>
                  <TableCell component="th" scope="row"><Link to={{ pathname: '/questionario/detalhar', query: { survey: questionario } }}>{questionario.name}</Link></TableCell>
                  <TableCell component="th" scope="row">{questionario.points}</TableCell>
                  <TableCell>
                    <ActionsColumnFormatter
                      hideEdit={true}
                      handleRemove={() => handleRemove(questionario.id)}
                    />
                  </TableCell>
                </TableRow>
              )) : <TableRow><TableCell colSpan={6} style={{ textAlign: 'center' }}>Nenhuma informação encontrada</TableCell></TableRow>}
            </TableBody>
            <TableFooter>
            </TableFooter>
          </Table>
        </Paper>
      </CardBody>
    </Card>
    <SurveyModal open={modalShow} setOpen={setModalShow} />
  </>
};
export default Surveys;
