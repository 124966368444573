/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Snackbar from '../snackbar/Snackbar';
import Swal from 'sweetalert2';
import Api from '../../../../app/services/Api';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  textField: {
    width: '100%',
  },
}));


const VariaveisInstanciaModal = ({ open, setOpen, busca, setBusca, variavel }) => {
  const classes = useStyles();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [messageSnackbar] = useState('');
  const [variantSnackbar] = useState(false);
  const [values, setValues] = useState({
    id: null,
    name: '',
  });

  useEffect(() => {
    if (variavel) setValues({
      id: variavel.instance_id,
      name: variavel.aux_name,
    });
    else setValues({
      id: null,
      name: '',
    });
  }, [open]);

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    const { status } = await Api.put(`/variables/instances/${values.id}`, { name: values.name });
    if (status !== 200) {
      return Swal.fire({
        title: 'Atenção!',
        text: 'Houve um problema na comunicação com a API, tente novamente mais tarde.',
        icon: 'warning',
      });
    }
    setBusca({ ...busca });
    return setOpen(false);
  }

  return <Modal show={open} size="md">
    <Modal.Header closeButton>
      <Modal.Title>Editar Nickname - {variavel?.name || ''}</Modal.Title>
    </Modal.Header>
    <form onSubmit={handleSubmit}>
      <Modal.Body>
        <TextField
          id="standard-name"
          label="Nome Auxiliar"
          className={classes.textField}
          value={values.name}
          onChange={handleChange('name')}
          margin="normal"
        />
        <Snackbar open={showSnackbar} setOpen={setShowSnackbar} message={messageSnackbar} variant={variantSnackbar} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="contained" color="secondary" className={classes.button} onClick={() => setOpen(false)}>Fechar</Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          type="submit"
          disabled={variavel && JSON.stringify(variavel) === JSON.stringify(values)}
        >{values.id !== null ? 'Salvar' : 'Adicionar'}</Button>
      </Modal.Footer>
    </form>
  </Modal>

};

export default VariaveisInstanciaModal;
