/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Snackbar from '../snackbar/Snackbar';
import Autocomplete from '../autocomplete/AutocompleteNew';
import Swal from 'sweetalert2';
import Api from '../../../../app/services/Api';

import ItemList from '../ItemsList';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  textField: {
    width: '40%',
    margin: theme.spacing(3),
  },
}));

export const phoneMaskInput = (value) => {
  const x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
  return !x[2] ? x[1] : `(${x[1]}) ${x[2] + (x[3] ? `-${x[3]}` : '')}`;
};


const UsuarioModal = ({ open, setOpen, busca, setBusca, modelo }) => {
  const classes = useStyles();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [messageSnackbar] = useState('');
  const [variantSnackbar] = useState(false);
  const [values, setValues] = useState({
    id: null,
    name: '',
    variables: [],
  });

  useEffect(() => {
    const fetchModel = async () => {
      const { status, data } = await Api.get(`/equipments-models/${modelo.id}`);
      if (status !== 200) {
        console.log(`ModelosEquipamentosModal fetch 1 /equipments-models/${modelo.id}`, status, data)
        return alert('Houve um erro!')
      }
      setValues({
        id: modelo.id,
        name: modelo.name,
        variables: data.variables.map((i) => ({ label: i.variable_name, id: i.variable_id })),
      });

    };
    if (modelo) {
      fetchModel();
  } else {
    setValues({
      id: null,
      name: '',
      variables: [],
    });
  }
    const fetchInitalData = async () => {
      const { status, data } = await Api.get('/variables/raw');
      if (status !== 200) {
        console.log(`ModelosEquipamentosModal fetch 2 /variables/raw`, status, data)
        return alert('Houve um erro!')
      }
    };
    if (modelo) {
      fetchInitalData();
    }
  }, [open]);

  const handleChangeSingle = (value) => {
    const checkVariables = values.variables.filter((i) => i.id === value.id);
    if (checkVariables.length === 0) {
      setValues({ ...values, variables: [...values.variables, value] });
    }
  }

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    const modelDto = {
      name: values.name,
      variables: values.variables.map((i) => i.id)
    };
    if (values.id === null) {
      const { status, data } = await Api.post('/equipments-models', { ...modelDto });
      if (status !== 200) {
        console.log(status, data);
        const text = status === 401 ? 'Você não tem permissão para realizar esta ação.' : 'Houve um problema na comunicação com a API, tente novamente mais tarde.';
        return Swal.fire({
          title: 'Atenção!',
          text,
          icon: 'warning',
        });
      }

      setBusca({ ...busca });
      setValues({
        id: null,
        name: '',
        variables: [],
      });
      return setOpen(false);
    }

    const { status, data } = await Api.put(`/equipments-models/${modelo.id}`, { ...modelDto });
    if (status !== 200) {
      console.log(status, data);
        const text = status === 401 ? 'Você não tem permissão para realizar esta ação.' : 'Houve um problema na comunicação com a API, tente novamente mais tarde.';
        return Swal.fire({
          title: 'Atenção!',
          text,
          icon: 'warning',
        });
    }

    setBusca({ ...busca });
    return setOpen(false);
  }

  const handleRemove = (value) => {
    const newVariables = values.variables.filter((variable) => variable.id !== value.id);
    setValues({ ...values, variables: newVariables });
  };

  return <Modal show={open} size="lg">
    <Modal.Header closeButton>
      <Modal.Title>{values.id !== null ? 'Editar Modelo' : 'Novo Modelo'}</Modal.Title>
    </Modal.Header>
    <form onSubmit={handleSubmit}>
      <Modal.Body>
        <TextField
          id="standard-name"
          label="Nome"
          className={classes.textField}
          value={values.name}
          onChange={handleChange('name')}
          margin="normal"
          required
        />
        <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal" style={{ width: '40%', margin: 23 }}>
          <Autocomplete
            handleChange={handleChangeSingle}
            endpoint="/variables/raw/autocomplete"
            label="Tipo de Variáveis"
          />
        </div>
        <Snackbar open={showSnackbar} setOpen={setShowSnackbar} message={messageSnackbar} variant={variantSnackbar} />

        <ItemList title="Variáveis" items={values.variables} handleRemove={handleRemove} label="label" key="id" />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="contained" color="secondary" className={classes.button} onClick={() => setOpen(false)}>Fechar</Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          type="submit"
        >{values.id !== null ? 'Salvar' : 'Adicionar'}</Button>
      </Modal.Footer>
    </form>
  </Modal>

};

export default UsuarioModal;
