/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Api from '../../../../app/services/Api';
import SocketClient from '../../../../app/services/Socket';
import Swal from 'sweetalert2';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { checkModuleAuth } from '../../../../app/@utils'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  textField2: {
    width: '40%',
    margin: theme.spacing(3),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  table: {
    marginTop: '10px',
  },
  tagNameTable: {
    width: '90%',
  },
}));

const ValoresModal = ({ open, setOpen, variavel, user }) => {
  const [valores, setValores] = useState([]);
  const [valorAtual, setValorAtual] = useState(null);
  const classes = useStyles();
  const valoresRef = useRef();
  const textType = [4, 5, 7, 10];
  const [values, setValues] = useState({
    value: '',
  });

  valoresRef.current = valores;

  useEffect(() => {
    const fetchApiData = async () => {
      const { status, data } = await Api.get(`/variables/${variavel.id}/values`);
      if (status !== 200) {
        return Swal.fire({
          title: 'Atenção!',
          text: 'Você não tem permissão para realizar esta ação.',
          icon: 'warning',
        });
      }
      setValores(data);
      setValorAtual(variavel.historic_value);
      setValues({ value: variavel.historic_value })
      SocketClient.on(
        `equipment/${variavel.equipment_id}/new_value`,
        ({ value, variable_id }) => {
          if (variavel.id === variable_id) {
            setValorAtual(value);
            setValues({ value: variavel.historic_value });
          }
        }
      );
    };
    if (variavel) {
      fetchApiData();
    }
  }, [variavel]);

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleValueSend = async (item) => {
    if (!checkModuleAuth(user, 'variables-instances', 'update')) return false;
    const valorDto = {
      equipment_id: variavel.equipment_id,
      variable_id: variavel.id,
      variable_code: variavel.code,
      value: item.value,
    };
    console.log('Enviando', valorDto);
    const { status, data } = await Api.post('/mqtt/historic-web-values', { ...valorDto });
    console.log('Enviado', status, data);
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    const valorDto = {
      equipment_id: variavel.equipment_id,
      variable_id: variavel.id,
      variable_code: variavel.code,
      value: values.value,
    };
    const { status, data } = await Api.post('/mqtt/historic-web-values', { ...valorDto });
    console.log('Enviado', status, data, valorDto);
  }
  const handleRefreshData = async (item) => {
    const data = {
      code: item.code,
      equipment_id: item.equipment_id,
    };

    const { status } = await Api.post('/mqtt/refresh-value', { ...data });
    if (status !== 204) {
      console.log(status);
      return Swal.fire({
        title: 'Atenção!',
        text: 'Houve um problema na comunicação com a API, tente novamente mais tarde.',
        icon: 'warning',
      });
    }
  };

  return <Modal show={open} size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Valores - {variavel?.name} ({variavel?.code})</Modal.Title>
    </Modal.Header>
    <form onSubmit={handleSubmit}>
      <Modal.Body>
        {variavel && textType.includes(variavel.type_id) &&
          <TextField
            id="standard-value"
            label="Valor"
            className={classes.textField}
            type={variavel.type_id === 5 ? 'number' : 'text'}
            onChange={handleChange('value')}
            value={values.value}
            margin="normal"
            required
          />
        }
        {variavel && !textType.includes(variavel.type_id) &&
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Valores</TableCell>
                <TableCell>Ação</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {valores.length > 0 ? valores.map((valor) => {
                return <TableRow key={valor.id}>
                  <TableCell component="th" scope="row" className={classes.tagNameTable}>{user.user_level > 0 && valor.value_nickname !== null && valor.value_nickname !== '' ? valor.value_nickname : valor.name}</TableCell>
                  <TableCell>
                    {valor.value !== valorAtual && <Button disabled={!checkModuleAuth(user, 'variables-instances', 'update')} variant="contained" color="secondary" className={classes.button} onClick={() => handleValueSend(valor)}>Ativar</Button>}
                  </TableCell>
                </TableRow>
              }) : <TableRow><TableCell colSpan={2} style={{ textAlign: 'center' }}>Nenhuma informação encontrada</TableCell></TableRow>}
            </TableBody>
          </Table>
        }
      </Modal.Body>

      <Modal.Footer>
        <Button variant="contained" color="secondary" className={classes.button} onClick={() => setOpen(false)}>Fechar</Button>
        {variavel && textType.includes(variavel.type_id) && checkModuleAuth(user, 'variables-instances', 'update') &&
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            type="submit"
            disabled={values.value === ''}
          >Atualizar</Button>
        }
        {checkModuleAuth(user, 'variables-instances', 'update') && variavel?.permission !== 'w' &&
          <a
            title="Atualizar valores"
            className="btn btn-success btn-light btn-hover-success btn-sm mx-3"
            onClick={() => handleRefreshData(variavel)}
          >
            <span className="svg-icon svg-icon-md svg-icon-success">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Code/refresh-svgrepo-com.svg")}
                alt={"Refresh values"}
              />
            </span>
          </a>
        }
      </Modal.Footer>
    </form>
  </Modal>

};

export default ValoresModal;
