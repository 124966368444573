import { createSlice } from "@reduxjs/toolkit";

const initialApplicationState = {
  tags: [],
  equipaments: [],
  variables: [],
};
export const callTypes = {
  list: "list",
  action: "action"
};

const removeTag = (list, tag, state) => {
  let newTags = list.filter((subTag) => subTag.id !== tag.id);
  const newEquips = state.equipaments.map((equip) => {
    return { ...equip, tags: equip.tags.filter((subTag) => subTag.id !== tag.id) };
  });
  state.equipaments = [...newEquips];

  newTags.map((subTag) => {
    if (subTag.parent_id === tag.id) {
      newTags = removeTag(newTags, subTag, state);
    }
    return subTag;
  });
  return newTags;
};

export const applicationSlice = createSlice({
  name: "application",
  initialState: initialApplicationState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // renewTags
    tagRenew: (state, action) => {
      state.error = null;
      state.tags = action.payload.tags;
    },
    // renewTags
    equipamentRenew: (state, action) => {
      state.error = null;
      state.equipaments = action.payload.equipaments;
    },
    // renewTags
    variableRenew: (state, action) => {
      state.error = null;
      state.variables = action.payload.variables;
    },
    // createTag
    tagCreated: (state, action) => {
      state.error = null;
      state.tags.push(action.payload.tag);
    },
    // removeTag
    tagRemoved: (state, action) => {
      state.error = null;
      const newTags = removeTag(state.tags, action.payload.tag, state);
      state.tags = [...newTags];
    },
    // updateTag
    tagUpdated: (state, action) => {
      state.error = null;
      const newTags = state.tags.map((tag) => {
        if (tag.id === action.payload.tag.id) {
          return { ...tag, ...action.payload.tag };
        }
        return tag;
      });
      state.tags = [...newTags];
    },
    // updateEquipament
    equipamentUpdated: (state, action) => {
      state.error = null;
      const newEquipaments = state.equipaments.map((equipament) => {
        if (equipament.id === action.payload.equipament.id) {
          return { ...equipament, ...action.payload.equipament };
        }
        return equipament;
      });
      state.equipaments = [...newEquipaments];
    },
    // updateVariable
    variableUpdated: (state, action) => {
      state.error = null;
      const newVariables = state.variables.map((variable) => {
        if (variable.id === action.payload.variable.id) {
          return { ...variable, ...action.payload.variable };
        }
        return variable;
      });
      state.variables = [...newVariables];
    },
  }
});
