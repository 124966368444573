/* eslint-disable no-restricted-imports */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ChartBar from './ChartBarExample'
import { ActionsColumnFormatter } from './ActionColumnFormatter'
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
}));

function createData(quarto, andar, status, energia, title) {
  return { quarto, andar, status, energia, title };
}

const rows = [
  createData('Quarto 1', '1º andar', 'Ocupado'   , [65, 80, 80, 91, 56], ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio']),
  createData('Quarto 2', '1º andar', 'Ocupado'   , [65, 80, 80, 91, 56], ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio']),
  createData('Quarto 3', '1º andar', 'Ocupado'   , [65, 80, 80, 91, 56], ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio']),
  createData('Quarto 4', '1º andar', 'Livre'     , [65, 80, 80, 91, 56], ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio']),
  createData('Quarto 5', '1º andar', 'Em limpeza', [65, 80, 80, 91, 56], ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio']),
];

export default function SimpleTable() {
  const classes = useStyles();

  const mystyle = {
    width: 300,
    height: 300,
  };

  return (
  <Card>
      <CardHeader title="Lista exemplo">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={console.log()}
          >
            Inserir
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
            <TableCell>Ações</TableCell>
              <TableCell>Quarto</TableCell>
              <TableCell align="right">Localização</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="left">Consumo de energia</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.quarto}>
                <TableCell>
                  <ActionsColumnFormatter />
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.quarto}
                </TableCell>
                <TableCell align="right">{row.andar}</TableCell>
                <TableCell align="right">{row.status}</TableCell>
                <TableCell align="left">
                  <div style={mystyle}>
                    <ChartBar
                      values="{row.energia}"
                      title="{row.title}"
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      </CardBody>
    </Card>
  );
}
