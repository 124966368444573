/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '../autocomplete/Autocomplete';
import Api from '../../../../app/services/Api';
import Swal from 'sweetalert2';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  textField2: {
    width: '40%',
    margin: theme.spacing(3),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  table: {
    marginTop: '10px',
  },
  tagNameTable: {
    width: '90%',
  },
}));

const VariableModal = ({ open, setOpen, variavel, user, busca, setBusca }) => {
  const classes = useStyles();
  const [typeList, setTypeList] = useState([]);
  const [, setLoading] = useState(true);
  const [values, setValues] = useState({
    id: null,
    name: '',
    code: '',
    typeId: { label: 'Selecione um tipo', value: 0 },
    isStatus: { label: 'Não', value: 0 },
    permission: { label: 'Leitura/Escrita', value: 'rw' },
    systemVar: { label: 'Não', value: 0 },
    tags: [],
    tagId: null,
  });

  const handleChangeSingleType = (value) => {
    return setValues({ ...values, typeId: value });
  }

  const handleChangeSingleSystem = (value) => {
    return setValues({ ...values, systemVar: value });
  }

  const handleChangeSingleSensor = (value) => {
    return setValues({ ...values, isStatus: value });
  }

  const handleChangeSinglePermission = (value) => {
   return setValues({ ...values, permission: value });
  }

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    const newVariable = {
      name: values.name,
      type_id: values.typeId.value,
      is_status: values.isStatus.value ? true : false,
      permission: values.permission.value,
      system_var: values.systemVar.value ? true : false,
      tags: values.tags,
      code: values.code,
    };
    if (user.user_level !== 0) {
      const { status } = await Api.post(`/variables/${values.id}/tags`, { tags: values.tags })
      if (status !== 204) {
        if (status === 401) {
          return Swal.fire({
            title: 'Atenção!',
            text: 'Você não tem permissão para realizar esta ação.',
            icon: 'warning',
          });
        }
        return Swal.fire({
          title: 'Atenção!',
          text: 'Houve um problema na comunicação com a API, tente novamente mais tarde.',
          icon: 'warning',
        });
      }
      const response = await Api.put(`/variables/${values.id}`, { ...newVariable, tags: undefined });
      if (response.status !== 200) {
        if (response.status === 401) {
          return Swal.fire({
            title: 'Atenção!',
            text: 'Você não tem permissão para realizar esta ação.',
            icon: 'warning',
          });
        }
        console.log(response.status, response.data);
        return Swal.fire({
          title: 'Atenção!',
          text: 'Houve um problema na comunicação com a API, tente novamente mais tarde.',
          icon: 'warning',
        });
      }
      setBusca({ ...busca });
      return setOpen(false);
    }

    if (!newVariable.type_id) {
      return Swal.fire({
        title: 'Atenção!',
        text: 'É necessário selecionar um tipo para a variável.',
        icon: 'warning',
      });
    }

    if (!newVariable.code) {
      return Swal.fire({
        title: 'Atenção!',
        text: 'Campo código obrigatório.',
        icon: 'warning',
      });
    }

    if (values.id === null) {
      const { status, data } = await Api.post('/variables', { ...newVariable });
      if (status !== 200) {
        if (status === 401) {
          return Swal.fire({
            title: 'Atenção!',
            text: 'Você não tem permissão para realizar esta ação.',
            icon: 'warning',
          });
        }
        console.log(status, data);
        return Swal.fire({
          title: 'Atenção!',
          text: 'Houve um problema na comunicação com a API, tente novamente mais tarde.',
          icon: 'warning',
        });
      }
      setBusca({ ...busca });
      setValues({
        id: null,
        name: '',
        code: '',
        isStatus: { label: 'Não', value: 0 },
        systemVar: { label: 'Não', value: 0 },
        permission: { label: 'Leitura/Escrita', value: 'rw' },
        typeId: null,
        tags: [],
        tagId: null,
      });
      return setOpen(false);
    }
    const { status, data } = await Api.put(`/variables/${values.id}`, { ...newVariable, tags: undefined });
    if (status !== 200) {
      if (status === 401) {
        return Swal.fire({
          title: 'Atenção!',
          text: 'Você não tem permissão para realizar esta ação.',
          icon: 'warning',
        });
      }
      console.log(status, data);
      return Swal.fire({
        title: 'Atenção!',
        text: 'Houve um problema na comunicação com a API, tente novamente mais tarde.',
        icon: 'warning',
      });
    }
    setBusca({ ...busca });
    return setOpen(false);
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      const responseType = await Api.get('/variables/types');
      if (responseType.status !== 200) {
        console.log(`TipoVariaveisModal fetch /variables/types`, responseType.status, responseType.data)
        alert('Houve um erro!');
        return setOpen(false);
      }
      const newTypeList = responseType.data.map((type) => {
        return { label: type.name, value: type.id }
      });
      setTypeList(newTypeList);

      if (variavel) {
        const responseTags = await Api.get(`/variables/${variavel.id}/tags`);
        if (responseTags.status !== 200) {
          console.log(`TipoVariaveisModal fetch /variables/${variavel.id}/tags`, responseTags.status, responseTags.data)
          alert('Houve um erro!');
          return setOpen(false);
        }
        let permission = { label: 'Leitura/Escrita', value: 'rw' };
        if (variavel.permission === 'r') {
          permission = { label: 'Leitura', value: 'r' };
        }
        if (variavel.permission === 'w') {
          permission = { label: 'Escrita', value: 'w' };
        }

        setValues({
          id: variavel.id,
          name: user.user_level > 0 && variavel.variable_nickname !== null && variavel.variable_nickname !== '' ? variavel.variable_nickname : variavel.name,
          code: variavel.code,
          isStatus: variavel.is_status ? { label: 'Sim', value: 1 } : { label: 'Não', value: 0 },
          permission: permission,
          systemVar: variavel.system_var ? { label: 'Sim', value: 1 } : { label: 'Não', value: 0 },
          typeId: { label: variavel.type_name, value: variavel.type_id },
          tags: responseTags.data.filter((tag) => !tag.system_var).map((i) => i.id),
          tagId: null,
        });
      } else {
        setValues({
          id: null,
          name: '',
          code: '',
          isStatus: { label: 'Não', value: 0 },
          permission: { label: 'Leitura/Escrita', value: 'rw' },
          systemVar: { label: 'Não', value: 0 },
          typeId: { label: 'Selecione um tipo', value: 0 },
          tags: [],
          tagId: null,
        });
      }
      setLoading(false);
    };
    fetchInitialData();
  }, [open]);

  return <Modal show={open} size={user.user_level === 0 ? 'lg' : 'md'}>
    <Modal.Header closeButton>
      <Modal.Title>{values.id !== null ? 'Editar Tipo de Variável' : 'Novo Tipo de Variável'}</Modal.Title>
    </Modal.Header>
    <form onSubmit={handleSubmit}>
      <Modal.Body>
        <TextField
          id="standard-name"
          label="Nome"
          className={user.user_level === 0 ? classes.textField2 : classes.textField}
          value={values.name}
          onChange={handleChange('name')}
          margin="normal"
        />
        {user.user_level === 0 && <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal" style={{ width: '40%', margin: 20 }}>
          <Autocomplete
            options={typeList}
            onChange={handleChangeSingleType}
            value={values.typeId}
            label="Tipo"
            placeholder="Selecione um tipo..."
          />
        </div>}
        {user.user_level === 0 && <TextField
          id="standard-code"
          label="Código"
          className={user.user_level === 0 ? classes.textField2 : classes.textField}
          value={values.code}
          onChange={user.user_level === 0 ? handleChange('code') : () => {}}
          readOnly
          margin="normal"
        />}
        {user.user_level === 0 && <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal" style={{ width: '40%', margin: 20 }}>
          <Autocomplete
            options={[
              { label: 'Não', value: 0 },
              { label: 'Sim', value: 1 },
            ]}
            onChange={handleChangeSingleSystem}
            value={values.systemVar}
            label="Sistema"
            inputId="react-native-single-2"
            placeholder="É uma variável sistemica?"
          />
        </div>}
        {user.user_level === 0 && <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal" style={{ width: '40%', margin: 20 }}>
          <Autocomplete
            options={[
              { label: 'Não', value: 0 },
              { label: 'Sim', value: 1 },
            ]}
            onChange={handleChangeSingleSensor}
            value={values.isStatus}
            label="Sensor"
            inputId="react-native-single-2"
            placeholder="É um sensor?"
          />
        </div>}
        {user.user_level === 0 && <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal" style={{ width: '40%', margin: 20 }}>
          <Autocomplete
            options={[
              { label: 'Leitura/Escrita', value: 'rw' },
              { label: 'Leitura', value: 'r' },
              { label: 'Escrita', value: 'w' },
            ]}
            onChange={handleChangeSinglePermission}
            value={values.permission}
            label="Permissão"
            inputId="react-native-single-2"
            placeholder="Tipo de permissão"
          />
        </div>}

      </Modal.Body>

      <Modal.Footer>
        <Button variant="contained" color="secondary" className={classes.button} onClick={() => setOpen(false)}>Fechar</Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          type="submit"
        >{values.id !== null ? 'Salvar' : 'Adicionar'}</Button>
      </Modal.Footer>
    </form>
  </Modal>

};

export default VariableModal;
