/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import SVG from "react-inlinesvg";
import { useSelector } from "react-redux"; // eslint-disable-line
import { useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import TarefasModal from '../../_metronic/layout/components/modal/TarefasModal';
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../_metronic/_partials/controls";
import { ActionTable, BasicInformation } from '../components/InsertOrEditTask';
import Api from '../services/Api';
import { removeDuplicateObjectInArray } from '../@utils';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  form: {
    padding: theme.spacing(3),
  },
  title: {
    fontSize: '1.1rem',
    fontWeight: 'normal',
  },
  actions: {
    alignItems: 'center',
    display: 'flex',
  },
  subtitle: {
    margin: 0,
    marginRight: 10,
    fontSize: 16
  },
  button: {
    borderRadius: '50%',
    margin: 0,
    minWidth: 0,
    padding: 0,
    /* width: 30px, */
  },
  svg: {
    fill: 'white'
  },
}));

export default function InserirEditarTarefa() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [isEdit, setIsEdit] = useState(false);
  const { user } = useSelector(state => state.auth);
  const [open, setOpen] = useState(false);

  const [basicInformation, setBasicInformation] = useState({
    id: null,
    name: '',
    trigger_type: '',
    schedule_type: '',
    enabled: true,
    schedule_days: [],
    execution_time: null,
    schedule_date: null,
    finishRepeatIn: null,
    closing_date: null,
    equipment_id: null,
    tag_ids: [],
    variable_id: null,
  });

  const [actions, setActions] = useState({
    action_type: '',
    equipment_id: null,
    variable_id: null,
    variable_value_id: null,
    tag_id: null,
    value: null,
    action_task_id: '',
    task_action_type: '',
    addedActions: [],
    condition_value: null,
    condition_operation: null,
    condition_equipment_id: null,
    condition_variable_id: null,
    condition_variable_value_id: null,
    enableConditional: false,
  });

  const returnPreviousPage = () => {
    Swal.fire({
      title: 'Atenção!',
      text: 'Tem certeza que deseja cancelar esta tarefa? Todos os dados vinculados a ela serão excluídos.',
      icon: 'warning',
      confirmButtonText: 'Cancelar',
      cancelButtonText: 'Fechar',
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const status = 200;
        // const { status, data } = await Api.delete(`/tarefas/${item.id}`);
        if (status !== 200) {
          Swal.fire({
            title: 'Atenção!',
            text: 'Aconteceu algum erro, tente novamente mais tarde.',
            icon: 'error',
            confirmButtonText: 'Entendi',
          });
        }
        history.goBack();
      }
    });
  };

  const handleChange = (name, type) => event => {
    if(type === 'basicInformation') {
      setBasicInformation({ ...basicInformation, [name]: event.target.value });
    }

    if(type === 'actions') {
      if(actions.action_type.value === 'delay') {
        const maxValue = 300000;
        const value = event.target.value > maxValue ? maxValue : event.target.value;
        return setActions({ ...actions, [name]: Math.abs(value) });
      }
      setActions({ ...actions, [name]: event.target.value });
    }
  };

  const handleAddActions = (value, action) => {
    if(action === 'dragDrop') {
      return setActions({ ...actions, addedActions: value });
    }

    if(action === 'delete') {
      if(isEdit && value.id) {
        return Swal.fire({
          title: 'Atenção!',
          text: 'Tem certeza que deseja remover esta ação? Todos os dados vinculados a ela serão excluídos.',
          icon: 'warning',
          confirmButtonText: 'Exluir',
          cancelButtonText: 'Fechar',
          showCancelButton: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            const newActions = actions.addedActions.filter((action) => action !== value).map((action) => ({
              ...action,
              order: Math.max(1, action.order - 1),
            }));
            const { status, data } = await Api.delete(`/task-actions/${value.id}`);
            if (status !== 204) {
              Swal.fire({
                title: 'Atenção!',
                text: 'Aconteceu algum erro, tente novamente mais tarde.',
                icon: 'error',
                confirmButtonText: 'Entendi',
              });
              console.log(data);
            }

            return setActions({ ...actions, addedActions: newActions });

          }
        });
      }
      const newAddedActions = actions.addedActions.filter((action) => action !== value);
      return setActions({ ...actions, addedActions: newAddedActions });
    };

    const clearActions = {
      action_type: '',
      equipment_id: null,
      variable_id: null,
      variable_value_id: null,
      tag_id: null,
      value: null,
      action_task_id: '',
      task_action_type: '',
      addedActions: [],
      condition_value: null,
      condition_equipment_id: null,
      condition_operation: null,
      condition_variable_id: null,
      condition_variable_value_id: null,
      enableConditional: false,
    };

    if(action === 'close') {
      return setActions({ ...actions, ...clearActions })
    }

    setActions({ ...clearActions, addedActions: [...actions.addedActions, value] });
  };

  const handleChangeSelect = (name, type) => ( value ) => {
    if(type === 'basicInformation') {
      if(name === 'trigger_type') {
        return setBasicInformation({ ...basicInformation, [name]: value.value, schedule_type: '', enabled: true, schedule_days: [], execution_time: null, schedule_date: null, finishRepeatIn: null, closing_date: null, equipment_id: null, tag_ids: [], variable_id: null, });
      }

      if(name === 'equipment_id') {
        return setBasicInformation({ ...basicInformation, [name]: value, variable_id: null });
      }
      if(['variable_id'].includes(name)) {
        return setBasicInformation({ ...basicInformation, [name]: value });
      }

      if(name === 'schedule_type') {
        return setBasicInformation({ ...basicInformation, [name]: value.value, schedule_days: null, execution_time: null, schedule_date: null, finishRepeatIn: null, closing_date: null })
      }

      setBasicInformation({ ...basicInformation, [name]: value.value });
    }

    if(type === 'actions') {
      if(name === 'action_type') {
        return setActions({...actions,  [name]: value, equipment_id: null, variable_id: null, variable_value_id: null, tag_id: null, value: null});
      }

      if(name === 'variable_id') {
        return setActions({...actions,  [name]: value, variable_value_id: null, tag_id: null, value: null });
      }

      if(name === 'action_task_id') {
        return setActions({...actions,  [name]: value, variable_value_id: null, tag_id: null, value: null, task_action_type: null, });
      }

      setActions({ ...actions, [name]: value });
    }
  };

  const handleChangeWeekDays = (e) => {
    setBasicInformation({ ...basicInformation, schedule_days: Array.isArray(e) ? e.map(x => x) : [] });
  };

  const handleChangeTags = (e) => {
    setBasicInformation({ ...basicInformation, tag_ids: Array.isArray(e) ? e.map(x => x) : [] });
  };

  const handleEnabledSwitch = (name, type) => (event) => {
    if(type === 'basicInformation') {
      setBasicInformation({ ...basicInformation, [name]: event.target.checked });
    }

    if(type === 'actions') {
      if(name === 'enableConditional') {
        return setActions({ ...actions, [name]: event.target.checked, condition_value: null, condition_equipment_id: null, condition_operation: null, condition_variable_id: null, condition_variable_value_id: null, });
      }
      setActions({ ...actions, [name]: event.target.checked });
    }
  };

  useEffect(() => {
    if(location.state && location.state.idTarefa) {
      const { idTarefa: id } = location.state;
      setIsEdit(true);
      setBasicInformation({...basicInformation, id });
    };
  }, [location]);

  useEffect(() => {
    const weeksDays = (days) => {
      if(days) {
        return days.split(',').map((value) => {
          if(value === '1') return { value, label: 'Domingo' };
          if(value === '2') return { value, label: 'Segunda' };
          if(value === '3') return { value, label: 'Terça' };
          if(value === '4') return { value, label: 'Quarta' };
          if(value === '5') return { value, label: 'Quinta' };
          if(value === '6') return { value, label: 'Sexta' };
          return { value, label: 'Sábado' };
        });
      }
    };

    const formattedDate = (date) => {
      if(date) {
        date = date.replace(/Z/, '');
        return format(new Date(date), 'yyyy-MM-dd');
      }
    };

    const formattedTags = async (item) => {
      if(item && item.length > 0) {
        const { status, data } = await Api.get('/tags', { per_page: 100000 });
        if (status !== 200) return null;
        const newTags = removeDuplicateObjectInArray(data.data, 'value');
        return newTags;
      }
    };

    const formattedEquipment = async (item) => {
      if(item) {
        const { status, data } = await Api.get(`/equipments/${item}`);
        if (status !== 200) return null;
        return { label: data.name, value: item };
      }
    };

    const formattedVariable = async (item) => {
      if(item) {
        const { status, data } = await Api.get('/variables', { params: { per_page: 100000 } });
        if (status !== 200) return null;
        const newVariable = data.data.find((arr) => arr.id === item);
        return { label: `${newVariable.name} #${newVariable.code}`, equipment_id: newVariable.equipment_id, value: newVariable.id }
      }
    };

    const createBasicInformation = async (data) => {
      const newBasicInformation = { ...data };
      delete newBasicInformation.actions;
      newBasicInformation.enabled = basicInformation.enabled ? true : false;
      newBasicInformation.schedule_date = formattedDate(newBasicInformation.schedule_date);
      newBasicInformation.closing_date = formattedDate(newBasicInformation.closing_date);
      newBasicInformation.schedule_days = weeksDays(newBasicInformation.schedule_days);
      newBasicInformation.trigger_type = newBasicInformation.tag_ids.length ? 'changeVariableValueViaTags' : newBasicInformation.trigger_type;
      newBasicInformation.tag_ids = await formattedTags(newBasicInformation.tag_ids);
      newBasicInformation.equipment_id = await formattedEquipment(newBasicInformation.equipment_id);
      newBasicInformation.variable_id = await formattedVariable(newBasicInformation.variable_id);
      return newBasicInformation;
    };

    const tagAction = (tag, tags) => {
      if(tag) {
        const tagName = tags.data.find((item) => item.id === tag.tag_id).tag_name;

        return {
          order: tag.order,
          id: tag.id,
          action_type: tag.action_type,
          label: tagName,
          name: `${tagName} ${tag.tag_id} ${tag.value}`,
          tag_id: tag.tag_id,
          value: tag.value,
          valueLabel: tag.value,
          condition_value: tag.condition_value,
          condition_operation: tag.condition_operation,
          condition_equipment_id: tag.condition_equipment_id,
          condition_variable_id: tag.condition_variable_id,
          condition_variable_value_id: tag.condition_variable_value_id,
        };
      }
    };

    const variableAction = (action, variables) => {
      if(action) {
        const variableName = variables.data.find((item) => item.id === action.variable_id).name;

        return {
          order: action.order,
          id: action.id,
          action_type: action.action_type,
          label: variableName,
          name: `${variableName} ${action.id} ${action.value}`,
          variable_id: action.variable_id,
          variable_value_id: action.variable_value_id,
          value: action.value,
          valueLabel: action.value,
          condition_value: action.condition_value,
          condition_operation: action.condition_operation,
          condition_equipment_id: action.condition_equipment_id,
          condition_variable_id: action.condition_variable_id,
          condition_variable_value_id: action.condition_variable_value_id,
        };
      }
    };

    const delayAction = (action) => {
      return {
        order: action.order,
        id: action.id,
        action_type: action.action_type,
        name: `${action.action_type} ${action.value}`,
        value: action.value,
        valueLabel: action.value,
        condition_value: action.condition_value,
        condition_operation: action.condition_operation,
        condition_equipment_id: action.condition_equipment_id,
        condition_variable_id: action.condition_variable_id,
        condition_variable_value_id: action.condition_variable_value_id,
      }
    };

    const taskAction = (action, tasks) => {
      const taskName = tasks.data.find((item) => item.id === action.action_task_id).name;

      return {
        order: action.order,
        id: action.id,
        action_type: action.action_type,
        name: `${taskName} ${action.action_task_id} ${action.task_action_type}`,
        action_task_id: action.action_task_id,
        label: taskName,
        task_action_type: action.task_action_type,
        valueLabel: action.task_action_type,
        condition_value: action.condition_value,
        condition_operation: action.condition_operation,
        condition_equipment_id: action.condition_equipment_id,
        condition_variable_id: action.condition_variable_id,
        condition_variable_value_id: action.condition_variable_value_id,
      }
    };

    const fetchInitalData = async () => {
      const { status, data } = await Api.get(`/tasks/${basicInformation.id}`,);
      if (status !== 200) {
        return alert('teve um problema');
      }

      const newBasicInformation = await createBasicInformation(data);

      const actionsApi = data.actions;
      const { data: tags } = await Api.get('/tags', { params: { per_page: 100000 } });
      const { data: variables } = await Api.get('/variables/instances', { params: { per_page: 100000 } });
      const { data: tasks } = await Api.get('/tasks', { params: { per_page: 100000 } });

      const newActions =  actionsApi.map((action) => {
        if(action.action_type === 'tag') {
          return tagAction(action, tags);
        }

        if(action.action_type === 'variable') {
          return variableAction(action, variables);
        }

        if(action.action_type === 'task') {
          return taskAction(action, tasks);
        }

        if(action.action_type === 'delay') {
          return delayAction(action);
        }

        return false;
      }).filter((i) => i).sort((a, b) => a.order - b.order);

      setBasicInformation({ ...basicInformation, ...newBasicInformation, finishRepeatIn: newBasicInformation.closing_date ? 'in' : 'never', })
      if(newActions.length) {
        setActions({...actions, addedActions: newActions });
      }
    };
    if(basicInformation.id) {
      fetchInitalData();
    }
  }, [basicInformation.id]);

  const handleSubmit = async () => {
    const addActions = (actions) => {
      const newActions = [...actions];
      newActions.forEach((action) => {
        delete action.name;
        delete action.label;
        delete action.valueLabel;
        delete action.equipment_id;
      });
      return newActions;
    };

    const createBasicInformation = (data) => {
      const newBasicInformation = { ...data };
      newBasicInformation.schedule_days = newBasicInformation.schedule_type === 'daily' ? ['1','2','3','4','5','6','7'] : newBasicInformation.schedule_days ? newBasicInformation.schedule_days.map(({value}) => value) : null;
      newBasicInformation.trigger_type = newBasicInformation.trigger_type === 'changeVariableValueViaTags' ? 'variable_value' : newBasicInformation.trigger_type;
      newBasicInformation.tag_ids = newBasicInformation.tag_ids ? newBasicInformation.tag_ids.map(({value}) => value) : [];
      newBasicInformation.equipment_id = newBasicInformation.equipment_id ? newBasicInformation.equipment_id.value : null;
      newBasicInformation.variable_id = newBasicInformation.variable_id ? newBasicInformation.variable_id.value : null;
      delete newBasicInformation.finishRepeatIn;
      delete newBasicInformation.created_at;
      delete newBasicInformation.updated_at;
      delete newBasicInformation.blocked_until_date;
      delete newBasicInformation.user_id;
      delete newBasicInformation.id;
      return newBasicInformation;
    };
    const newBasicInformation = createBasicInformation(basicInformation);
    const newActions = addActions(actions.addedActions);

    if (basicInformation.id === null) {
      const { status, data } = await Api.post(`/tasks`, { ...newBasicInformation, actions: newActions });
      if (status !== 201) {
        return Swal.fire({
          title: 'Atenção!',
          text: Array.isArray(data.errors) ? data.errors.map((error) => error) : data.message,
          icon: 'warning',
        });
      }
      return history.push({
        pathname: '/tarefas/listar',
      });
    }

    const { status, data } = await Api.put(`tasks/${basicInformation.id}`, { ...newBasicInformation })
    if (status !== 200) {
      return Swal.fire({
        title: 'Atenção!',
        text: Array.isArray(data.errors) ? data.errors.map((error) => error) : data.message,
        icon: 'warning',
      });
    }
    const newActionsForApi = removeDuplicateObjectInArray(newActions, 'id');
    newActionsForApi.forEach(async(action) => {
      if(!action.id) {
        const { status, data} = await Api.post(`tasks/${basicInformation.id}/task-actions`, {...action});
        if (status !== 201) {
          return Swal.fire({
            title: 'Atenção!',
            text: Array.isArray(data.errors) ? data.errors.map((error) => error) : data.message,
            icon: 'warning',
          });
        }
      }
    });
    const MILLISECONDS = 1000;
    setTimeout(() => {
      history.push({
        pathname: '/tarefas/listar',
      });
    }, MILLISECONDS);
  };

  const handleAddAction = () => {
    setOpen(true);
  };

  return (
    <>
      <Card>
        <CardHeader title={isEdit ? <>Editar Tarefa - <span className={classes.title}>{user.name} - 10/01/2022 12:00</span>
        </> : 'Nova Tarefa'}>
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-danger ml-3"
              onClick={returnPreviousPage}
            >
              Cancelar
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Paper className={classes.root}>
            <form  className={classes.form}>
              <h3 style={{ marginTop: 16, fontSize: 16 }}>1. Informações Básicas</h3>
              <BasicInformation
                basicInformation={basicInformation}
                handleChange={handleChange}
                handleChangeSelect={handleChangeSelect}
                handleEnabledSwitch={handleEnabledSwitch}
                handleChangeWeekDays={handleChangeWeekDays}
                handleChangeTags={handleChangeTags}
              />

              <div className={classes.actions}>
                <h3 className={classes.subtitle}>2. Ações</h3>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  type="button"
                  onClick={() => handleAddAction()}
                >
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Plus-2.svg")} className={classes.svg} />
                </Button>
              </div>
              {actions.addedActions && actions.addedActions.length > 0 &&
              <>
                <br />
                <ActionTable actions={actions} handleAddActions={handleAddActions} />
              </>
              }
              <br />
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={() => handleSubmit()}
                >
                  {isEdit ? 'Salvar Tarefa' : 'Adicionar Tarefa'}
                </Button>
              </div>

              <TarefasModal
                open={open}
                setOpen={setOpen}
                actions={actions}
                handleAddActions={handleAddActions}
                handleChange={handleChange}
                handleChangeSelect={handleChangeSelect}
                handleEnabledSwitch={handleEnabledSwitch}
              />
            </form>
          </Paper>
        </CardBody>
      </Card>
    </>
  );
}
