import React from 'react';

const useStyles = {
  container: {
    margin: '3px',
    padding: '2px 6px',
    border: '1px solid rgba(0, 0, 0, 0.3)',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};

const Tag = ({ title, onClick }) => {
  return <span onClick={onClick} style={useStyles.container}>{title}</span>
};

export default Tag;
