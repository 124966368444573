import React, { useState } from 'react';
import { makeStyles, Button, TextField } from '@material-ui/core'
import { Modal } from 'react-bootstrap';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers/";
import Api from '../../../../app/services/Api';
const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  table: {
    marginTop: '10px',
  },
  autoComplete: {
    width: '50%',
  },
  tagNameTable: {
    width: '90%',
  },
  viewRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }
}));
const SurveyModal = ({ open, setOpen }) => {
  const classes = useStyles();
  const [optionsQuestion, setOptionsQuestion] = useState([]);
  const [count, setCount] = useState(1)
  const [survey, setSurvey] = useState({
    name: '',
    points: null,
    title_pergunta: '',
    title_option: '',
    questions: [],
  });

  const handleAddOption = (text) => {
    const newQuestion = { order: count, name: text }
    const newArrayQuestions = [...optionsQuestion, newQuestion];
    setOptionsQuestion(newArrayQuestions)
    setCount(count + 1)
    setSurvey({ ...survey, title_option: '' });
  }

  const handleAddQuestionToSurvey = () => {
    const newQuestion = {
      name: survey.title_pergunta,
      order: survey.questions.length + 1,
      options: optionsQuestion
    }
    setSurvey({ ...survey, "questions": survey.questions.push(newQuestion) });
    setSurvey({ ...survey, title_pergunta: '' });
    setOptionsQuestion([])
  }

  const handleChange = title => event => {
    setSurvey({ ...survey, [title]: event.target.value });
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    const { name, points, questions } = survey;
    const newSurveyDto = {
      name: name,
      points: points,
      questions: questions
    }

    const { status } = await Api.post('/quizzes', { ...newSurveyDto });
    const hasCreated = status === 201;
    if (hasCreated) {
      return setOpen(false)
    }
  };

  const removeOption = (id) => {
    const newArrayOptions = optionsQuestion.filter(item => item.order !== id)
    setOptionsQuestion(newArrayOptions)
  }

  const removeQuestion = (id) => {
    const newArrayQuestions = survey.questions.filter(item => item.order !== id)
    setSurvey({ ...survey, "questions": newArrayQuestions });
  }

  return <Modal show={open} size="lg">
    <Modal.Header closeButton>
      <Modal.Title>{'Novo Questionário'}</Modal.Title>
    </Modal.Header>
    <form onSubmit={handleSubmit}>
      <Modal.Body>
        <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal" style={{ width: '70%' }}>
          <TextField
            id="standard-name"
            label="Título do questionário"
            className={classes.textField}
            value={survey.name}
            onChange={handleChange('name')}
            margin="normal"
          />
        </div>
        <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal" style={{ width: '20%', marginLeft: 5 }}>
          <TextField
            id="standard-name"
            label="Pontuação"
            className={classes.textField}
            value={survey.points}
            onChange={handleChange('points')}
            margin="normal"
          />
        </div>

        <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal">
          <TextField
            id="standard-name"
            label="Título do pergunta"
            className={classes.textField}
            value={survey.title_pergunta}
            onChange={handleChange('title_pergunta')}
            margin="normal"
          />
        </div>
        <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal" style={{ width: '80%' }}>
          <TextField
            id="standard-name"
            label="Texto da opção"
            className={classes.textField}
            value={survey.title_option}
            onChange={handleChange('title_option')}
            margin="normal"
          />
        </div>
        <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal" style={{ width: '20%' }}>
          <Button variant="contained" color="secondary" className={classes.button} disabled={survey.title_option === ""} onClick={() => handleAddOption(survey.title_option)}>Adicionar opção</Button>
        </div>
        {optionsQuestion.length > 0 && (
          <>
            <h1>{`Opcões cadastradas:\n`}</h1>
            {optionsQuestion.map(item => (
              <div className={classes.viewRow}>
                <h3>{item.name}</h3>
                <a
                  href="#remove"
                  title="Deletar"
                  className="btn btn-icon btn-light btn-hover-danger btn-sm"
                  onClick={() => removeOption(item.order)}
                >
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} title="Deletar" alt={"Deletar"} />
                  </span>
                </a>
              </div>
            ))}
          </>
        )}
        <Button variant="contained" color="secondary" disabled={survey.title_pergunta === "" || optionsQuestion.length === 0} className={classes.button} onClick={() => handleAddQuestionToSurvey()}>Adicionar pergunta ao questionário</Button>
      </Modal.Body>

      <Modal.Footer>
        {survey.questions.length > 0 && (
          <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal">

            <h2>Perguntas cadastradas:</h2>
            {survey.questions.map(item => (
              <div className={classes.viewRow}>
                <h3>{item.name}</h3>
                <a
                  href="#delete"
                  title="Deletar"
                  className="btn btn-icon btn-light btn-hover-danger btn-sm"
                  onClick={() => removeQuestion(item.order)}
                >
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} title="Deletar" alt={"Deletar"} />
                  </span>
                </a>
              </div>
            ))}
          </div>
        )}
        <Button variant="contained" color="secondary" className={classes.button} onClick={() => setOpen(false)}>Fechar</Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={survey.questions.length === 0}
          type="submit"
        >{'Cadastrar novo questionário'}</Button>
      </Modal.Footer>
    </form>
  </Modal >
};

export default SurveyModal;
