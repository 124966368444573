/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { 
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
} from 'date-fns';
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Pagination from '../components/Pagination/Pagination';
import Swal from 'sweetalert2';
import { ActionsColumnFormatter } from './ActionColumnFormatter'
import Paper from '@material-ui/core/Paper';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import ValoresModal from '../../_metronic/layout/components/modal/ValoresModal';
import GraficoValoresModal from '../../_metronic/layout/components/modal/GraficoValoresModal';
import TagsJoinModal from '../../_metronic/layout/components/modal/TagsJoinModal';
import VariaveisInstanciaModal from '../../_metronic/layout/components/modal/VariaveisInstanciaModal';
import Loading from '../components/Loading/Loading';
import Api from '../services/Api';
import SVG from "react-inlinesvg";
import AutocompleteNew from '../../_metronic/layout/components/autocomplete/AutocompleteNew';
import ItemList from '../../_metronic/layout/components/ItemsList';
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SocketClient from '../services/Socket';
import { checkModuleAuth } from '../@utils'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  chartIcon: {
    cursor: 'pointer',
  },
  textField: {
    flex: 1,
    marginRight: '15px',
    width: '100%',
  },
}));

export default function Variaveis() {
  const { query } = useLocation();
  const [variaveisList, setVariaveisList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [input, setInput] = useState('');
  const [busca, setBusca] = useState(
    {
      per_page: 10,
      current_page: 1,
      filter_name: '',
      filter_equipments: query && query.equipment ? [{ id: query.equipment.id, label: query.equipment.name }] : [],
      filter_enviroments: query && query.enviroment ? [{ id: query.enviroment.id, label: query.enviroment.name }] : [],
      filter_variables: query && query.variable ? [{ id: query.variable.id, label: query.variable.name }] : [],
    }
  );
  const [modalShow, setModalShow] = useState(false);
  const [modalGraficoShow, setModalGraficoShow] = useState(false);
  const [modalVariavelValores, setModalVariavelValores] = useState(null);
  const [modalVariavel, setModalVariavel] = useState(null);
  const [modalVariavelShow, setModalVariavelShow] = useState(false);
  const [tagModalShow, setTagModalShow] = useState(false);
  const [tagUrl, setTagUrl] = useState('');
  const [tagsBusca, setTagsBusca] = useState([]);
  const variaveisRef = useRef();

  variaveisRef.current = variaveisList;

  const classes = useStyles();
  const user = useSelector(state => state.auth.user);

  const history = useHistory();

  useEffect(() => {
    let timer;
    const fetchInitalData = async () => {
      setLoading(true);
      const { status, data } = await Api.get('/variables/instances',
        {
          params:
            {
              ...busca,
              tags: tagsBusca.map((i) => i.id).join(','),
              filter_equipments: busca.filter_equipments.map((i) => i.id).join(','),
              filter_enviroments: busca.filter_enviroments.map((i) => i.id).join(','),
              filter_variables: busca.filter_variables.map((i) => i.id).join(','),
            }
        }
      );
      if (status !== 200) {
        return alert('Problema ao sincronizar as variáveis.');
      }

      const equipsId = [];
      const dataDto = data.data.map((i) => {
        if (!equipsId.includes(i.equipment_id)) {
          equipsId.push(i.equipment_id);
          SocketClient.on(
            `equipment/${i.equipment_id}/new_value`,
            ({ value, variable_id, valueLabel }) => {
              const newVariablesList = variaveisRef.current.data.map((a) => {
                if (a.id === variable_id && a.equipment_id === i.equipment_id) {
                  return { ...a, historic_value: value, historic_value_name: valueLabel, historic_date: new Date(Date.now()) };
                }
                return a;
              });
              setVariaveisList({ ...variaveisRef.current, data: newVariablesList });
            }
          );
        }
        return { ...i, tags: [] };
      });
      setVariaveisList([]);
      setVariaveisList({ pagination: data.pagination, data: dataDto });
      setLoading(false);
    };

    timer = setTimeout(() => fetchInitalData(), 350);

    return () => clearTimeout(timer);
  }, [busca, tagsBusca]);

  const handleValues = (item) => {
    setModalVariavelValores(item);
    setModalShow(true);
  };

  const handleValuesGrafico = (item) => {
    setModalVariavelValores(item);
    setModalGraficoShow(true);
  };

  const handleTag = (item) => {
    setLoading(true);
    setTagUrl(`/variables-instances/${item.instance_id}/tags`);
    setTagModalShow(true);
  };

  const handleRemoveTag = (tag) => {
    setLoading(true);
    const newTags = tagsBusca.filter((i) => i.id !== tag.id);
    setTagsBusca(newTags);
  };

  const handleChangeTag = (tag) => {
    const check = tagsBusca.filter((i) => i.id === tag.id);
    if (check.length > 0) return false;
    setLoading(true);
    const newTags = [...tagsBusca, tag];
    setTagsBusca(newTags);
  };

  const handleRemoveEquipment = (item) => {
    setLoading(true);
    const newItems = busca.filter_equipments.filter((i) => i.id !== item.id);
    setBusca((prev) => ({ ...prev, filter_equipments: newItems }));
  };

  const handleChangeEquipment = (item) => {
    const check = busca.filter_equipments.filter((i) => i.id === item.id);
    if (check.length > 0) return false;
    setLoading(true);
    const newItems = [...busca.filter_equipments, item];
    setBusca((prev) => ({ ...prev, filter_equipments: newItems }));
  };

  const handleRemoveEnviroment = (item) => {
    setLoading(true);
    const newItems = busca.filter_enviroments.filter((i) => i.id !== item.id);
    setBusca((prev) => ({ ...prev, filter_enviroments: newItems }));
  };

  const handleChangeEnviroment = (item) => {
    const check = busca.filter_enviroments.filter((i) => i.id === item.id);
    if (check.length > 0) return false;
    setLoading(true);
    const newItems = [...busca.filter_enviroments, item];
    setBusca((prev) => ({ ...prev, filter_enviroments: newItems }));
  };

  const handleRemoveVariable = (item) => {
    setLoading(true);
    const newItems = busca.filter_variables.filter((i) => i.id !== item.id);
    setBusca((prev) => ({ ...prev, filter_variables: newItems }));
  };

  const handleChangeVariable = (item) => {
    const check = busca.filter_variables.filter((i) => i.id === item.id);
    if (check.length > 0) return false;
    setLoading(true);
    const newItems = [...busca.filter_variables, item];
    setBusca((prev) => ({ ...prev, filter_variables: newItems }));
  };

  const handleBuscaLimpar = () => {
    setLoading(true);
    setTagsBusca([]);
    setInput('');
    const newBusca = { per_page: busca.per_page, current_page: 1, filter_equipments: [], filter_variables: [], filter_enviroments: [], filter_name: '' };
    setBusca(newBusca);
  };

  const handleBuscaInput = (event) => {
    const value = event.target.value;
    setInput(value);
    setBusca({ ...busca, current_page: 1, filter_name: value });
  };

  const handleEdit = (item) => {
    setModalVariavel(item);
    setModalVariavelShow(true);
  }

  const handleRefreshData = async (item) => {
    const data = {
      code: item.code,
      equipment_id: item.equipment_id,
    };

    const { status } = await Api.post('/mqtt/refresh-value', { ...data });
    if (status !== 204) {
      console.log(status);
      return Swal.fire({
        title: 'Atenção!',
        text: 'Houve um problema na comunicação com a API, tente novamente mais tarde.',
        icon: 'warning',
      });
    }
  };

  const customMessage = (date) => {
    const now = new Date();
    const createdAt = new Date(date);
    let message;

    const minutes = differenceInMinutes(now, createdAt);
    const hours = differenceInHours(now, createdAt);
    const days = differenceInDays(now, createdAt);
    const months = differenceInMonths(now, createdAt);
    const years = differenceInYears(now, createdAt);

    if (months > 23) {
      message = `${years} anos`
      return message;
    } else if (days > 89) {
      message = `${months} meses`
      return message;
    } else if (hours > 47) {
      message = `${days} dias`
      return message;
    } else if (minutes > 89) {
      message = `${hours} horas`
      return message;
    } else if (minutes > 1) {
      message = `${minutes} minutos`
      return message;
    }
    
    message = '1 minuto'
    return message;
  }

  if (loading) return <Loading />

  return (
    <>
      <Card>
        <CardHeader>
          <CardHeaderToolbar style={{ minWidth: '100%' }}>
            <div className="row" style={{ width: '100%' }}>
              <div className="col-sm-2 col-12">
                <TextField
                  id="standard-name"
                  label="Busca texto"
                  className={classes.textField}
                  onChange={handleBuscaInput}
                  value={input}
                />
              </div>
              <div className="col-sm-2 col-12">
                <AutocompleteNew
                  handleChange={handleChangeTag}
                  endpoint="/tags/autocomplete"
                  label="Busca tag"
                  variant="4"
                />
              </div>
              <div className="col-sm-2 col-12">
                <AutocompleteNew
                  handleChange={handleChangeEquipment}
                  endpoint="/equipments-autocomplete"
                  label="Equipamentos"
                />
              </div>
              <div className="col-sm-2 col-12">
                <AutocompleteNew
                  handleChange={handleChangeVariable}
                  endpoint="/variables/raw/autocomplete"
                  label="Tipo de Variáveis"
                />
              </div>
              <div className="col-sm-2 col-12">
                <AutocompleteNew
                  handleChange={handleChangeEnviroment}
                  endpoint="/enviroments-autocomplete"
                  label="Ambientes"
                />
              </div>
              {(tagsBusca.length > 0 || (busca.filter_equipments &&
                busca.filter_equipments.length > 0) ||
                (busca.filter_variables &&
                  busca.filter_variables.length > 0) ||
                (busca.filter_enviroments &&
                  busca.filter_enviroments.length > 0) ||
                busca.filter_name !== '') &&
                <div className="col-sm-2 col-12">
                  <button
                    type="button"
                    className="btn btn-secondary mt-5"
                    onClick={handleBuscaLimpar}
                  >
                    Limpar Busca
                  </button>
                </div>
              }
            </div>
          </CardHeaderToolbar>
          {busca.filter_equipments.length > 0 &&
            <ItemList
              title="Equipamentos"
              inLine
              variant="2"
              items={busca.filter_equipments}
              handleRemove={handleRemoveEquipment}
              label="label"
              key="id"
            />
          }
          {busca.filter_enviroments.length > 0 &&
            <ItemList
              title="Ambientes"
              inLine
              variant="2"
              items={busca.filter_enviroments}
              handleRemove={handleRemoveEnviroment}
              label="label"
              key="id"
            />
          }
          {busca.filter_variables.length > 0 &&
            <ItemList
              title="Tipo de Variáveis"
              inLine
              variant="2"
              items={busca.filter_variables}
              handleRemove={handleRemoveVariable}
              label="label"
              key="id"
            />
          }
          {tagsBusca.length > 0 &&
            <ItemList
              title="Tags"
              inLine
              variant="2"
              items={tagsBusca}
              handleRemove={handleRemoveTag}
              label="label_name"
              key="id"
            />
          }
        </CardHeader>
        <CardBody>
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Variável</TableCell>
                  <TableCell>Equipamento</TableCell>
                  <TableCell>Código</TableCell>
                  <TableCell>Valor</TableCell>
                  <TableCell>Modificado em</TableCell>
                  <TableCell>Valores/Gráfico/Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {variaveisList.data && variaveisList.data.length > 0 ? variaveisList.data.map(variavel => (
                  <TableRow key={`${variavel.equipment_id}.${variavel.id}`}>
                    <TableCell component="th" scope="row">
                      {
                        variavel.aux_name &&
                        variavel.aux_name !== null &&
                        variavel.aux_name !== '' ? variavel.aux_name :
                        variavel.variable_nickname &&
                        variavel.variable_nickname !== null &&
                        variavel.variable_nickname !== '' ? variavel.variable_nickname : variavel.name
                      }
                    </TableCell>
                    <TableCell component="th" scope="row">{variavel.equipment_name}</TableCell>
                    <TableCell component="th" scope="row">{variavel.code}</TableCell>
                    <TableCell component="th" scope="row">
                      {variavel.historic_value_name ?
                        <strong>{`${variavel?.value_nickname && variavel?.value_nickname !== '' && variavel?.value_nickname !== null ? variavel.value_nickname : variavel.historic_value_name} (${variavel.historic_value})`}</strong> : variavel.historic_value}
                    </TableCell>
                    <TableCell>{variavel.historic_date ? customMessage(variavel.historic_date) : 'Sem dados'}</TableCell>
                    <TableCell>
                      {variavel.permission !== 'w' && checkModuleAuth(user, 'variables-instances', 'update') && <a
                        title="Atualizar valores"
                        className="btn btn-success btn-light btn-hover-success btn-sm mx-3"
                        onClick={() => handleRefreshData(variavel)}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-success">
                          <SVG
                            src={toAbsoluteUrl("/media/svg/icons/Code/refresh-svgrepo-com.svg")}
                            alt={"Refresh values"}
                          />
                        </span>
                      </a>}
                      {variavel.permission !== 'r' &&
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => handleValues(variavel)}
                        >
                          Ver
                        </button>
                      }

                      <a
                        title="Editar"
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        onClick={() => handleValuesGrafico(variavel)}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-bar1.svg")}
                            alt={""}
                          />
                        </span>
                      </a>

                      <ActionsColumnFormatter handleTag={() => handleTag(variavel)} labelEdit="Nickname" handleEdit={() => handleEdit(variavel)} handleHistoric={ () => history.push({ pathname: '/historico-valores/listar', query: { equipment: { id: variavel.equipment_id, name: variavel.equipment_name  }, variable: { id: variavel.id, name: variavel.name } } }) } hideRemove hideRun showTag />
                    </TableCell>
                  </TableRow>
                )) : <TableRow><TableCell colSpan={8} style={{ textAlign: 'center' }}>Nenhuma informação encontrada</TableCell></TableRow>}
              </TableBody>
              <TableFooter>
                {variaveisList.pagination &&
                  <TableRow><Pagination busca={busca} setBusca={setBusca} colSpan={8} {...variaveisList.pagination} /></TableRow>
                }
              </TableFooter>
            </Table>
          </Paper>
        </CardBody>
      </Card>
      <TagsJoinModal
        endpoint={tagUrl}
        label="Variáveis"
        open={tagModalShow}
        setOpen={setTagModalShow}
        />
      <ValoresModal open={modalShow} user={user} setOpen={setModalShow} variavel={modalVariavelValores}  />
      <GraficoValoresModal open={modalGraficoShow} setOpen={setModalGraficoShow} variable={modalVariavelValores}  />
      <VariaveisInstanciaModal open={modalVariavelShow} user={user} setOpen={setModalVariavelShow} variavel={modalVariavel} busca={busca} setBusca={setBusca} />
    </>
  );
}
