/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as authActions from '../modules/Auth/_redux/authRedux';
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TagModal from '../../_metronic/layout/components/modal/TagModal';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Pagination from '../components/Pagination/Pagination';
import Paper from '@material-ui/core/Paper';
import { ActionsColumnFormatter } from './ActionColumnFormatter'
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import Loading from '../components/Loading/Loading';
import Swal from 'sweetalert2';
import Api from '../services/Api';
import { checkModuleAuth } from '../@utils'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  textField: {
    flex: 1,
    marginRight: '15px',
  },
}));

export default function BuscaTag() {
  const [tagsList, setTagList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [input, setInput] = useState('');
  const [busca, setBusca] = useState({ per_page: 10, current_page: 1, filter_name: '' });
  const [modalShow, setModalShow] = useState(false);
  const [modalTag, setModalTag] = useState(null);

  const classes = useStyles();
  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();

  const handleAdd = () => {
    setModalTag(null);
    setModalShow(true);
  };

  const handleEdit = (tag) => {
    setModalTag(tag);
    setModalShow(true);
  };

  const handleBuscaUsuario = (ev, tag) => {
    ev.preventDefault();
    setBusca({ ...busca, current_page: 1, filter_users: [tag.user_id] });
  };

  const handleBuscaPai = (ev, tag) => {
    ev.preventDefault();
    setBusca({ ...busca, current_page: 1, filter_parents: [tag.parent_id] });
  };

  const handleBuscaLimpar = () => {
    setInput('');
    setBusca({ per_page: busca.per_page, current_page: 1, filter_users: [], filter_parents: [], filter_name: '' });
  };

  const handleBuscaInput = (event) => {
    const value = event.target.value;
    setInput(value);
    if (value.length > 3) {
      setBusca({ ...busca, current_page: 1, filter_name: event.target.value });
    }
    if (value.length === 0) {
      setBusca({ ...busca, current_page: 1, filter_name: '' });
    }
  };

  const handleRemove = (item) => {
    Swal.fire({
      title: 'Atenção!',
      text: 'Tem certeza que deseja remover esta tag? Todos os dados vinculados a ela serão excluídos.',
      icon: 'warning',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { status, data } = await Api.delete(`/tags/${item.id}`);
        if (status !== 200) {
          Swal.fire({
            title: 'Atenção!',
            text: 'Aconteceu um erro na API, tente novamente mais tarde.',
            icon: 'error',
            confirmButtonText: 'Entendi',
          });
          console.log(data);
        }

        dispatch(authActions.actions.renewTag(true));
        setBusca({ ...busca });
      }
    });
  };

  useEffect(() => {
    const fetchInitalData = async () => {
      const { status, data } = await Api.get('/tags', { params: busca });
      if (status !== 200) {
        return alert('teve um problema');
      }
      setTagList(data);
      setLoading(false);
    };
    fetchInitalData();
  }, [busca]);

  if (loading) return <Loading />

  return (
    <>
      <Card>
        <CardHeader title="Tags">
          <CardHeaderToolbar>
            <TextField
              id="standard-name"
              label="Buscar"
              className={classes.textField}
              onChange={handleBuscaInput}
              value={input}
            />
            {checkModuleAuth(user, 'tags', 'create') && <button
              type="button"
              className="btn btn-primary"
              onClick={handleAdd}
            >
              Inserir
            </button>}
            {((busca.filter_users && busca.filter_users.length > 0) || (busca.filter_parents && busca.filter_parents.length > 0) || busca.filter_name !== '') && <button
              type="button"
              className="btn btn-secondary ml-3"
              onClick={handleBuscaLimpar}
            >
              Limpar Busca
            </button>}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  {user.user_level === 0 && <TableCell>Usuário</TableCell>}
                  {user.user_level === 0 && <TableCell>Sistema</TableCell>}
                  <TableCell>Pai</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tagsList.data && tagsList.data.length > 0 ? tagsList.data.map(tag => (
                  <TableRow key={tag.id}>
                    <TableCell component="th" scope="row">{tag.tag_name}</TableCell>
                    {user.user_level === 0 &&
                      <TableCell component="th" scope="row">
                        <a href="#teste" onClick={(ev) => handleBuscaUsuario(ev, tag)}>
                          {tag.user_name}
                        </a>
                      </TableCell>
                    }
                    {user.user_level === 0 && <TableCell component="th" scope="row">{tag.system_var === 1 ? 'Sim' : 'Não'}</TableCell>}
                    <TableCell component="th" scope="row">
                      {tag.parent_id > 0 && <a href="#teste" onClick={(ev) => handleBuscaPai(ev, tag)}>
                        {tag.father_name}
                      </a>}
                    </TableCell>
                    <TableCell>
                      <ActionsColumnFormatter
                        hideHistoric
                        hideRun
                        hideEdit={!checkModuleAuth(user, 'tags', 'update') || tag.system_var === 1}
                        hideRemove={!checkModuleAuth(user, 'tags', 'delete') || tag.system_var === 1}
                        handleEdit={() => handleEdit(tag)}
                        handleRemove={() =>  handleRemove(tag)}
                      />
                    </TableCell>
                  </TableRow>
                )) : <TableRow><TableCell colSpan={5} style={{ textAlign: 'center' }}>Nenhuma informação encontrada</TableCell></TableRow>}
              </TableBody>
              <TableFooter>
                {tagsList.pagination &&
                  <TableRow><Pagination busca={busca} setBusca={setBusca} colSpan={5} {...tagsList.pagination} /></TableRow>
                }
              </TableFooter>
            </Table>
          </Paper>
        </CardBody>
      </Card>
      <TagModal open={modalShow} setOpen={setModalShow} tag={modalTag} busca={busca} setBusca={setBusca} />
    </>
  );
}
