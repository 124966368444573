/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from "react-redux";
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Pagination from '../components/Pagination/Pagination';
import Paper from '@material-ui/core/Paper';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../_metronic/_partials/controls";
import Loading from '../components/Loading/Loading';
import Api from '../services/Api';
import ItemList from '../../_metronic/layout/components/ItemsList';
import AutocompleteNew from '../../_metronic/layout/components/autocomplete/AutocompleteNew';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  textField: {
    flex: 1,
    marginTop: '15px',
    width: '100%',
  },
}));

const defaultProps = {
  per_page: 10,
  current_page: 1,
  filter_equipments: [],
  filter_variables: [],
  filter_enviroments: [],
  filter_tags: [],
  date_in: '',
  date_out: '',
  date_in_raw: '',
  date_out_raw: ''
};

export default function HistoricoValores() {
  const { query } = useLocation();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [busca, setBusca] = useState({
    per_page: 10,
    current_page: 1,
    filter_equipments: query && query.equipment ? [{ id: query.equipment.id, label: query.equipment.name }] : [],
    filter_variables: query && query.variable ? [{ id: query.variable.id, label: query.variable.name }] : [],
    filter_enviroments: query && query.enviroment ? [{ id: query.enviroment.id, label: query.enviroment.name }] : [],
    filter_tags: [],
    date_in: '',
    date_out: '',
    date_in_raw: '',
    date_out_raw: ''
  });

  const classes = useStyles();

  const user = useSelector(state => state.auth.user);

  const handleBuscaLimpar = () => {
    setLoading(true);
    setBusca({ ...defaultProps, per_page: busca.per_page });
  };

  const handleBuscaDataInicio = (event) => {
    const date = format(new Date(event.target.value), 'dd/MM/yyyy HH:mm:ss');
    setLoading(true);
    setBusca({ ...busca, current_page: 1, date_in: date, date_in_raw: event.target.value });
  };

  const handleBuscaDataFim = (event) => {
    const date = format(new Date(event.target.value), 'dd/MM/yyyy HH:mm:ss');
    setLoading(true);
    setBusca({ ...busca, current_page: 1, date_out: date, date_out_raw: event.target.value });
  };

  const handleBuscaAmbiente = (ev, env_id) => {
    ev.preventDefault();
    setLoading(true);
    setBusca({ ...busca, filter_enviroments: [env_id], current_page: 1 });
  };

  const handleRemoveEquipment = (item) => {
    const newItems = busca.filter_equipments.filter((i) => i.id !== item.id);
    setLoading(true);
    setBusca({ ...busca, filter_equipments: newItems });
  };

  const handleChangeEquipment = (item) => {
    const check = busca.filter_equipments.filter((i) => i.id === item.id);
    if (check.length > 0) return false;
    setLoading(true);
    const newItems = [...busca.filter_equipments, item];
    setBusca({ ...busca, filter_equipments: newItems });
  };

  const handleRemoveEnviroment = (item) => {
    const newItems = busca.filter_enviroments.filter((i) => i.id !== item.id);
    setLoading(true);
    setBusca({ ...busca, filter_enviroments: newItems });
  };

  const handleChangeEnviroment = (item) => {
    const check = busca.filter_enviroments.filter((i) => i.id === item.id);
    if (check.length > 0) return false;
    setLoading(true);
    const newItems = [...busca.filter_enviroments, item];
    setBusca({ ...busca, filter_enviroments: newItems });
  };

  const handleRemoveVariable = (item) => {
    const newItems = busca.filter_variables.filter((i) => i.id !== item.id);
    setLoading(true);
    setBusca({ ...busca, filter_variables: newItems });
  };

  const handleChangeVariable = (item) => {
    const check = busca.filter_variables.filter((i) => i.id === item.id);
    if (check.length > 0) return false;
    setLoading(true);
    const newItems = [...busca.filter_variables, item];
    setBusca({ ...busca, filter_variables: newItems });
  };

  const handleRemoveTag = (item) => {
    const newItems = busca.filter_tags.filter((i) => i.id !== item.id);
    setLoading(true);
    setBusca({ ...busca, filter_tags: newItems });
  };

  const handleChangeTag = (item) => {
    const check = busca.filter_tags.filter((i) => i.id === item.id);
    if (check.length > 0) return false;
    setLoading(true);
    const newItems = [...busca.filter_tags, item];
    setBusca({ ...busca, filter_tags: newItems });
  };

  useEffect(() => {
    const fetchInitalData = async () => {
      const { status, data } = await Api.get('/historic-values',
        {
          params: {
            ...busca,
            filter_equipments: busca.filter_equipments.map((i) => i.id).join(','),
            filter_enviroments: busca.filter_enviroments.map((i) => i.id).join(','),
            filter_variables: busca.filter_variables.map((i) => i.id).join(','),
            filter_tags: busca.filter_tags.map((i) => i.id).join(','),
          }
        }
      );
      if (status !== 200) {
        return alert('teve um problema');
      }

      setList(data);
      setLoading(false);
    };
    fetchInitalData();
  }, [busca]);

  if (loading) return <Loading />

  return (
    <>
      <Card>
        <CardHeader>
          <CardHeaderToolbar style={{ minWidth: '100%' }}>
            <div className="row" style={{ width: '100%' }}>
              <div className="col-sm-2 col-12">
                <AutocompleteNew
                  handleChange={handleChangeEquipment}
                  endpoint="/equipments-autocomplete"
                  label="Equipamentos"
                />
              </div>
              <div className="col-sm-2 col-12">
                <AutocompleteNew
                  handleChange={handleChangeVariable}
                  endpoint="/variables/raw/autocomplete"
                  label="Tipo de Variáveis"
                />
              </div>
              <div className="col-sm-2 col-12">
                <AutocompleteNew
                  handleChange={handleChangeEnviroment}
                  endpoint="/enviroments-autocomplete"
                  label="Ambientes"
                />
              </div>
              <div className="col-sm-2 col-12">
                <AutocompleteNew
                  handleChange={handleChangeTag}
                  endpoint="/tags/autocomplete"
                  label="Tags"
                  variant="4"
                />
              </div>
              <div className="col-sm-1 col-12">
                <TextField
                  id="standard-date-in"
                  className={classes.textField}
                  onChange={handleBuscaDataInicio}
                  value={busca.date_in_raw}
                  type="date"
                />
              </div>
              <div className="col-sm-1 col-12">
                <TextField
                  id="standard-date-out"
                  className={classes.textField}
                  onChange={handleBuscaDataFim}
                  value={busca.date_out_raw}
                  type="date"
                />
              </div>
              {(busca.date_in !== '' || busca.date_out !== '' || !!busca.filter_enviroments.length || !!busca.filter_equipments.length || !!busca.filter_variables.length) &&
              <div className="col-sm-2 col-12">
                <button
                  type="button"
                  className="btn btn-secondary mt-5"
                  onClick={handleBuscaLimpar}
                >
                  Limpar Busca
                </button>
              </div>}
            </div>
          </CardHeaderToolbar>
          {busca.filter_equipments.length > 0 &&
            <ItemList
              title="Equipamentos"
              inLine
              variant="2"
              items={busca.filter_equipments}
              handleRemove={handleRemoveEquipment}
              label="label"
              key="id"
            />
          }
          {busca.filter_enviroments.length > 0 &&
            <ItemList
              title="Ambientes"
              inLine
              variant="2"
              items={busca.filter_enviroments}
              handleRemove={handleRemoveEnviroment}
              label="label"
              key="id"
            />
          }
          {busca.filter_variables.length > 0 &&
            <ItemList
              title="Tipo de Variáveis"
              inLine
              variant="2"
              items={busca.filter_variables}
              handleRemove={handleRemoveVariable}
              label="label"
              key="id"
            />
          }
          {busca.filter_tags.length > 0 &&
            <ItemList
              title="Tags"
              inLine
              variant="2"
              items={busca.filter_tags}
              handleRemove={handleRemoveTag}
              label="label_name"
              key="id"
            />
          }
        </CardHeader>
        <CardBody>
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Valor</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Equipamento</TableCell>
                  <TableCell>Ambiente</TableCell>
                  <TableCell>Variável</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.data && list.data.length > 0 ? list.data.map(item => (
                  <TableRow key={item.id}>
                    <TableCell component="th" scope="row">{item.id}</TableCell>
                    <TableCell>{item.value && item.historic_value_name ? <strong>{`${item.historic_value_name} (${item.value})`}</strong> : item.value}</TableCell>
                    <TableCell>{format(new Date(item.created_at), 'dd/MM/yyyy HH:mm:ss')}</TableCell>
                    <TableCell>{item.equipment_name}</TableCell>
                    <TableCell>{item.enviroment_id > 0 ? <a href="#link" onClick={(ev) => handleBuscaAmbiente(ev, item.enviroment_id)}>{item.enviroment_name}</a> : ''}</TableCell>
                    <TableCell>{user.user_level > 0 && item.variable_nickname !== null && item.variable_nickname !== '' ? item.variable_nickname : item.variable_name}</TableCell>
                  </TableRow>
                )) : <TableRow><TableCell colSpan={6} style={{ textAlign: 'center' }}>Nenhuma informação encontrada</TableCell></TableRow>}
              </TableBody>
              <TableFooter>
                {list.pagination &&
                  <TableRow><Pagination busca={busca} setBusca={setBusca} colSpan={6} {...list.pagination} /></TableRow>
                }
              </TableFooter>
            </Table>
          </Paper>
        </CardBody>
      </Card>
    </>
  );
}
