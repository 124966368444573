
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Autocomplete from '../../../_metronic/layout/components/autocomplete/Autocomplete';
import Api from '../../services/Api';
import { removeDuplicateObjectInArray } from '../../@utils';

const useStyles = makeStyles((theme) => ({
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    width: '28%',
    '&:not(:last-of-type)': {
      marginRight: 29,
    },
    '&.margin-top-16': {
      marginTop: 16,
    },
    '&.full-width': {
      width: '100%',
    },
    '@media (max-width: 1024px)': {
      width: '100%',
      '&:not(:last-of-type)': {
        marginRight: 0,
      },
      '&.margin-top-16': {
        marginTop: 0,
      },
    },
  },
  resize: {
    fontSize: 16,
  },
}));

export const Variable = ({ actions, handleChange, handleChangeSelect, }) => {
  const classes = useStyles();
  const [variables, setVariables] = useState([]);
  const [valores, setValores] = useState([]);
  const [changeVariable, setChangeVariable] = useState(false);
  const [equipments, setEquipments] = useState([]);
  const [loadingEquipment, setLoadingEquipment] = useState(false);
  const [loading, setLoading] = useState(false);
  const textTypeVariable = [4, 5, 7, 10];

  useEffect(() => {
    const fetchApiData = async () => {
      const endpoint = '/variables/instances';
      setLoadingEquipment(true);
      const { data, status } = await Api.get(endpoint, { params: { per_page: 10000 } });

      if (status !== 200) {
        console.log(data, status);
        return alert('Houve um erro!');
      }
      const equipments = data.data.map((equipment) => ({ label: equipment.equipment_name, value: equipment.equipment_id })).filter((i) => i);

      const newEquipments = removeDuplicateObjectInArray(equipments, 'value');
      setEquipments(newEquipments);
      setLoadingEquipment(false);
    }
    fetchApiData();
  }, []);

  useEffect(() => {
    const fetchApiData = async () => {
      const endpoint = '/variables/instances';
      setLoading(true);
      const { data, status } = await Api.get(endpoint, { params: { per_page: 10000 } });

      if (status !== 200) {
        console.log(data, status);
        return alert('Houve um erro!');
      }

      const variables = data.data.map((i) => i.equipment_id === actions.equipment_id.value && ({ label: `${i.name} #${i.code}`, equipment_id: i.equipment_id, value: i.id, })).filter((i) => i);
      setVariables(variables.filter((i) => i));
      setLoading(false);
    }

    if(actions.equipment_id) {
      fetchApiData();
    }
  }, [actions.equipment_id])

  useEffect(() => {
    const fetchApiData = async () => {
      setChangeVariable(true);
      const { status, data } = await Api.get(`/variables/${actions.variable_id.value}/values`);
      if (status !== 200) {
        console.log(data, status);
        return Swal.fire({
          title: 'Atenção!',
          text: 'Você não tem permissão para realizar esta ação.',
          icon: 'warning',
        });
      }
      setValores(data.map((i) => ({ ...i, label: i.name })));
      setChangeVariable(false);
    }
    if (actions.variable_id) {
      fetchApiData();
    }
  }, [actions.variable_id]);

  return (
    <>
      <br />
      {loadingEquipment ? <p>CARREGANDO EQUIPAMENTOS</p> :
          <Autocomplete
            options={equipments}
            value={actions.equipment_id && equipments.find(item => item.value === actions.equipment_id.value)}
            onChange={handleChangeSelect('equipment_id', 'actions')}
            label="Equipamento"
          />
      }
      {loading && actions.equipment_id ? <><br /><p>CARREGANDO VARIÁVEIS</p></> : actions.equipment_id && !loading &&
      <>
        <br/>
        <Autocomplete
          options={variables}
          value={variables.find(item => item.value === actions.variable_id) }
          onChange={handleChangeSelect('variable_id', 'actions')}
          label="Variável"
          required
        />
      </>
      }
      {actions.variable_id && textTypeVariable.includes(actions.variable_id.type_id) &&
      <>
        <br />
        <TextField
          id="standard-value"
          label="Valor"
          className={classes.textField}
          type={actions.variable_id.type_id === 5 ? 'number' : 'text'}
          onChange={handleChange('value', 'actions')}
          value={actions.value}
          margin="normal"
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          required
        />
      </>
      }
      {actions.variable_id && !textTypeVariable.includes(actions.variable_id.type_id) &&
      <>
        <br />
        {!changeVariable &&
          <Autocomplete
            options={valores}
            value={valores.find(item => item.value === actions.variable_value_id)}
            onChange={handleChangeSelect('variable_value_id', 'actions')}
            label="Valor"
            required
          />
        }
      </>
      }
    </>
  )
};
