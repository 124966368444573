/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import React, { useState, useMemo, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import Chart from 'react-apexcharts';
import Button from '@material-ui/core/Button';
import Api from '../../../../app/services/Api';
import dayjs from 'dayjs';
import Autocomplete from '../autocomplete/Autocomplete';
import { ActionsColumnFormatter } from '../../../../app/pages/ActionColumnFormatter';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
// import Swal from 'sweetalert2';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  toolbarButtonsWrapper: {
    paddingBottom: '18px'
  },
  toolbarWrapper: {
    width: '40%',
    marginTop: '16px'
  },
  autoCompleteWrapper: {
    width: '50%',
  },
  chartHeaderWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    margin: '8px 8px'
  },
  activeButton: {
    color: '#fff',
    background: '#008FFB',
    border: '1px solid blue',
    borderBottom: '2px solid blue',
    borderRadius: '2px',
    padding: '4px 17px',
  },
  inactiveButton: {
    background: '#fff',
    color: '#222',
    border: '1px solid #e7e7e7',
    borderBottom: '2px solid #ddd',
    borderRadius: '2px',
    padding: '4px 17px',
  },
  spinnerContainer: {
    padding: '120px',
    alignSelf: 'center'
  },
  textField: {
    marginRight: '5px',
  },
  indicatorsWrapper: {
    display: 'flex',
    flexDirection: 'column',
  }
}));



const chartOptions = {
  chart: {
    id: 'line-datetime',
    type: 'line',
    height: 350,
    zoom: {
      enabled: true,
      type: 'x',
      autoScaleYaxis: false,
      zoomedArea: {
        fill: {
          color: '#90CAF9',
          opacity: 0.4
        },
        stroke: {
          color: '#0D47A1',
          opacity: 0.4,
          width: 1
        }
      }
    }
  },
  annotations: {
    yaxis: [{
      y: 30,
      borderColor: '#999',
      label: {
        show: true,
        text: 'Support',
        style: {
          color: "#fff",
          background: '#00E396'
        }
      }
    }],
    xaxis: [{
      borderColor: '#999',
      yAxisIndex: 0,
    }]
  },
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 0,
    style: 'hollow',
  },
  xaxis: {
    type: 'datetime',
    tickAmount: 6,

  },
  yaxis: {
    formatter: (val) => val.toFixed(2)
  },
  fill: {
    type: 'solid',
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      stops: [0, 100]
    }
  },
  stroke: {
    show: true,
    curve: 'smooth',
    lineCap: 'butt',
    colors: undefined,
    width: 2,
    dashArray: 0,
  }

};

const ValoresModal = ({ open, setOpen, variable }) => {

  const [isLoading, setIsLoading] = useState(false);
  const [variablesList, setVariablesList] = useState([]);
  const [currentDateRangeSelect, setCurrentDateRangeSelect] = useState();
  const [extraVariableSelected, setExtraVariableSelected] = useState([]);
  const [variableHistoricData, setVariableHistoricData] = useState([]);
  const [dateTimeFilter, setDateTimeFilter] = useState({
    date_in: dayjs().subtract(1, "week").format("YYYY-MM-DD"),
    date_out: dayjs().format("YYYY-MM-DD"),
  });

  const classes = useStyles();

  const fetchEquipmentInfo = async (items) => {
    const { status, data } = await Api.get('/variables/equipments');
    if (status !== 200) {
      return setOpen(false);
    }
    const variablesListDto = data.map((i) => ({ label: `${i.equipment_name} > ${i.name}`, value: i.join_id, variable_id: i.id, equipment_id: i.equipment_id }));
    setVariablesList(items ? variablesListDto.filter((i) => !items.includes(i.value)) : variablesListDto);
  };


  const fetchVariableData = async (currentVariable, dateBegin, dateEnd) => {
    const { status, data } = await Api.get(`/historic-values/variable/${currentVariable.id || currentVariable.variable_id}`, {
      params: {
        date_in: dateBegin.format("DD/MM/YYYY HH:mm:ss"),
        date_out: dateEnd.format("DD/MM/YYYY HH:mm:ss"),
        date_in_raw: dateBegin.format("YYYY-MM-DD"),
        date_out_raw: dateEnd.format("YYYY-MM-DD")
      }
    });
    if (status !== 200) {
      return null;
    }
    const mappedResponse = data.filter((item) => dayjs(item.created_at).isAfter(dayjs(dateBegin)) && dayjs(item.created_at).isBefore(dayjs(dateEnd))).map((item) => ([item.created_at, item.value]));
    return { name: currentVariable.name || currentVariable.label, data: mappedResponse }
  }

  useEffect(() => {
    updateData(dateTimeFilter)
  }, [dateTimeFilter, extraVariableSelected]);

  useEffect(() => {
    setIsLoading(true);
    fetchEquipmentInfo();
    setExtraVariableSelected([]);
    setIsLoading(false);
  }, [open]);


  async function updateData(dateRange) {
    const variables = extraVariableSelected.concat(variable);
    setIsLoading(true);
    try {
      if (variable) {
        const promiseArray = variables.map((variable) => fetchVariableData(variable, dayjs(dateRange.date_in), dayjs(dateRange.date_out)))
        const solvedPromises = await Promise.all(promiseArray);
        setVariableHistoricData(solvedPromises)
      }

    }
    catch (err) {
      console.log(`GraficoValorsModal fetch /variables/raw`, err)
      alert('Houve um erro!');
    }
    finally {
      setIsLoading(false);
    }

  }

  async function updateDataUsingTimeline(timeline) {
    setCurrentDateRangeSelect(timeline);
    const currentFinalDate = dayjs(dateTimeFilter.date_out);
    switch (timeline) {
      case 'one_day':
        return setDateTimeFilter({ ...dateTimeFilter, date_in: dayjs(currentFinalDate).subtract(1, 'day').format("YYYY-MM-DD") });
      case 'one_week':
        return setDateTimeFilter({ ...dateTimeFilter, date_in: dayjs(currentFinalDate).subtract(1, 'week').format("YYYY-MM-DD") });
      case 'one_month':
        return setDateTimeFilter({ ...dateTimeFilter, date_in: dayjs(currentFinalDate).subtract(1, 'month').format("YYYY-MM-DD") });
      case 'three_months':
        return setDateTimeFilter({ ...dateTimeFilter, date_in: dayjs(currentFinalDate).subtract(3, 'month').format("YYYY-MM-DD") });
      case 'six_months':
        return setDateTimeFilter({ ...dateTimeFilter, date_in: dayjs(currentFinalDate).subtract(6, 'month').format("YYYY-MM-DD") });
      case 'one_year':
        return setDateTimeFilter({ ...dateTimeFilter, date_in: dayjs(currentFinalDate).subtract(1, 'year').format("YYYY-MM-DD") });
      default:
    }
  }

  const handleChangeSingle = (value) => {
    const newVariablesList = variablesList.filter((i) => i.value !== value.value);
    setVariablesList(newVariablesList);
    setExtraVariableSelected([...extraVariableSelected, value]);
  }

  const handleRemove = (value) => {
    const newVariablesList = [...variablesList, value].sort((a, b) => a.value - b.value);
    const newVariables = extraVariableSelected.filter((variable) => variable.value !== value.value);
    setVariablesList(newVariablesList);
    setExtraVariableSelected(newVariables);
  };

  // const handleDateRangeClean = () => {
  //   setDateTimeFilter({ date_in: null, date_out: null });
  // };

  const handleSelectBeginDate = (event) => {
    setDateTimeFilter({ ...dateTimeFilter, date_in: event.target.value });
  };

  const handleSelectEndDate = (event) => {
    setDateTimeFilter({ ...dateTimeFilter, date_out: event.target.value });
  };

  const { min, max, average } = useMemo(() => {
    const { max, min, sum, numberOfItems } = variableHistoricData.reduce((acc, registry) => {
      const registryValues = registry.data.map(registryItem => parseFloat(registryItem[1]));
      const biggestInRegistry = Math.max(...registryValues);
      const smallestInRegistry = Math.min(...registryValues);
      const sum = registryValues.reduce((acc, n) => acc + n, 0);
      return {
        min: Math.min(acc.min, smallestInRegistry),
        max: Math.max(acc.max, biggestInRegistry),
        sum: acc.sum + sum,
        numberOfItems: acc.numberOfItems + registryValues.length
      }
    }, {
      min: 0,
      max: 0,
      sum: 0,
      numberOfItems: 0,
    })
    return {
      max, min, average: sum / numberOfItems
    }


  }, [variableHistoricData])
  return <Modal show={open} size="xl">
    <Modal.Header closeButton>
      <Modal.Title>Histórico dos Valores</Modal.Title>
    </Modal.Header>

    <Modal.Body>

      <>
        <div className={classes.chartHeaderWrapper} >
          <div className={classes.toolbarWrapper}>
            <div className={classes.toolbarButtonsWrapper}>
            <button id="one_day" onClick={() => updateDataUsingTimeline('one_day')} className={currentDateRangeSelect === 'one_day' ? classes.activeButton : classes.inactiveButton}>
              1D
            </button>
            <button id="one_week" onClick={() => updateDataUsingTimeline('one_week')} className={currentDateRangeSelect === 'one_week' ? classes.activeButton : classes.inactiveButton}>
              7D
            </button>
            <button id="one_month" onClick={() => updateDataUsingTimeline('one_month')} className={currentDateRangeSelect === 'one_month' ? classes.activeButton : classes.inactiveButton}>
              1M
            </button>
            <button id="three_months" onClick={() => updateDataUsingTimeline('three_months')} className={currentDateRangeSelect === 'three_months' ? classes.activeButton : classes.inactiveButton}>
              3M
            </button>
            <button id="six_months" onClick={() => updateDataUsingTimeline('six_months')} className={currentDateRangeSelect === 'six_months' ? classes.activeButton : classes.inactiveButton}>
              6M
            </button>
            <button id="one_year" onClick={() => updateDataUsingTimeline('one_year')} className={currentDateRangeSelect === 'one_year' ? classes.activeButton : classes.inactiveButton}>
              1A
            </button>
            </div>
            <TextField
              id="standard-date-in"
              className={classes.textField}
              onChange={handleSelectBeginDate}
              value={dateTimeFilter.date_in}
              type="date"
            />
            <TextField
              id="standard-date-out"
              className={classes.textField}
              onChange={handleSelectEndDate}
              value={dateTimeFilter.date_out}
              type="date"
            />
          </div>
          <div className={classes.autoCompleteWrapper}>
            <div className="MuiFormControl-root MuiFormControl-marginNormal" style={{ maxWidth: '300px' }} >
              <Autocomplete
                options={variablesList}
                onChange={handleChangeSingle}
                label="Variáveis"
                placeholder="Selecione uma variável..."
              />
            </div>


          </div>
        </div>
        {isLoading ? <div style={{ padding: '120px', display: "flex", justifyContent: "center" }} > <div className="spinner spinner-lg spinner-success" /> </div> : (<div id="chart-timeline">
          <Chart options={chartOptions} series={variableHistoricData} />
        </div>)}
        <div className={classes.indicatorsWrapper}>
          <span>Média: {average.toFixed(2)}</span>
          <span>Máxima: {max}</span>
          <span>Mínima: {min}</span>
        </div>
        <div>
          {extraVariableSelected.length > 0 && (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Variáveis</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {extraVariableSelected.length > 0 ? extraVariableSelected.map((variable) => {
                  return <TableRow key={variable.value}>
                    <TableCell component="th" scope="row">{variable.label}</TableCell>
                    <TableCell><ActionsColumnFormatter hideEdit hideHistoric hideRun handleRemove={() => handleRemove(variable)} /></TableCell>
                  </TableRow>
                }) : <TableRow><TableCell colSpan={2} style={{ textAlign: 'center' }}>Nenhuma variável adicionada</TableCell></TableRow>}
              </TableBody>
            </Table>
          )}
        </div>
      </>
    </Modal.Body >

    <Modal.Footer>
      <Button variant="contained" color="secondary" className={classes.button} onClick={() => setOpen(false)}>Fechar</Button>
    </Modal.Footer>
  </Modal >

};

export default ValoresModal;
