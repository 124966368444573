import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";

import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import ListPage from "../app/pages/ListPage";
import AreaPage from "../app/pages/TimeSeriesExample";
import Equipamentos from "./pages/Equipamentos";
import TiposVariaveis from "./pages/TiposVariaveis";
import Variaveis from './pages/Variaveis';
import Tags from "./pages/Tags";
import BuscaTag from "./pages/BuscaTag";
import HistoricoValores from './pages/HistoricoValores';
import HistoricoAcoes from './pages/HistoricoAcoes';
import GrupoUsuarios from './pages/GrupoUsuarios';
import Usuarios from './pages/Usuarios';
import ModelosEquipamentos from './pages/ModelosEquipamentos';
import Ambientes from './pages/Ambientes';
import Questionarios from './pages/Questionarios'
import DetalhesQuestionario from './pages/DetalhesQuestionario'
import Tarefas from "./pages/Tarefas";
import HistoricoTarefas from "./pages/HistoricoTarefas";
import InserirEditarTarefa from "./pages/InserirEditarTarefa";

const BuscaPersonalizada = lazy(() =>
  import("./pages/BuscaPersonalizada")
);

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect


  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/busca-tag" component={BuscaTag} />
        <Route path="/busca-personalizada/listar" component={BuscaPersonalizada} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/examplelistpage" component={ListPage} />
        <Route path="/examplearea" component={AreaPage} />
        <Route path="/ambientes/listar" component={Ambientes} />
        <Route path="/equipamentos/listar" component={Equipamentos} />
        <Route path="/tipos-variaveis/listar" component={TiposVariaveis} />
        <Route path="/variaveis/listar" component={Variaveis} />
        <Route path="/historico-valores/listar" component={HistoricoValores} />
        <Route path="/historico-acoes/listar" component={HistoricoAcoes} />
        <Route path="/grupos-usuarios/listar" component={GrupoUsuarios} />
        <Route path="/usuarios/listar" component={Usuarios} />
        <Route path="/modelos-equipamentos/listar" component={ModelosEquipamentos} />
        <Route path="/perfis-de-acesso/listar" component={ListPage} />
        <Route path="/cenas/listar" component={ListPage} />
        <Route path="/acoes/listar" component={ListPage} />
        <Route path="/historico/listar" component={ListPage} />
        <Route path="/financeiro/listar" component={ListPage} />
        <Route path="/tags/listar" component={Tags} />
        <Route path="/tarefas/listar" component={Tarefas} />
        <Route path="/historico-tarefas/listar" component={HistoricoTarefas} />
        <Route path="/editar-tarefa" component={InserirEditarTarefa} />
        <Route path="/nova-tarefa" component={InserirEditarTarefa} />
        <Route path="/questionario/detalhar" component={DetalhesQuestionario} />
        <Route path="/questionario/listar" component={Questionarios} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
