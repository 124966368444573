import { applicationSlice, callTypes } from "./applicationSlice";

const { actions } = applicationSlice;

export const renewTag = tags => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  dispatch(actions.tagRenew({ tags }));
};
export const renewEquipament = equipaments => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  dispatch(actions.equipamentRenew({ equipaments }));
};
export const renewVariable = variables => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  dispatch(actions.variableRenew({ variables }));
};
export const createTag = tag => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  dispatch(actions.tagCreated({ tag }));
};
export const updateTag = tag => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  dispatch(actions.tagUpdated({ tag }));
};
export const removeTag = tag => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  dispatch(actions.tagRemoved({ tag }));
};
export const updateEquipament = equipament => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  dispatch(actions.equipamentUpdated({ equipament }));
};
export const updateVariable = variable => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  dispatch(actions.variableUpdated({ variable }));
};
