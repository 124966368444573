/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as authActions from '../../../../app/modules/Auth/_redux/authRedux';
import { Modal } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '../autocomplete/Autocomplete';
import Snackbar from '../snackbar/Snackbar';
import Api from '../../../../app/services/Api';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

const TagModal = ({ open, setOpen, tag, busca, setBusca }) => {
  const classes = useStyles();
  const [tagList, setTagList] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [messageSnackbar, setMessageSnackbar] = useState('');
  const [variantSnackbar, setVariantSnackbar] = useState(false);
  const [values, setValues] = useState({
    id: null,
    name: '',
    parent_id: { value: null, label: 'Nada selecionado' },
  });

  const dispatch = useDispatch();

  const handleChangeSingle = (value) => {
    setValues({ ...values, parent_id: value });
  }

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    const newTag = {
      tag_name: values.name,
      parent_id: values.parent_id.value || null,
      icon: 'Shopping/Box2.svg',
      system_var: false,
    };
    if (newTag.tag_name === '') {
      setMessageSnackbar('Nome da Tag obrigatório');
      setVariantSnackbar('warning');
      return setShowSnackbar(true);
    }

    if (values.id === null) {
      setValues({ id: null, name: '', parent_id: { value: null, label: 'Nada selecionado' } });
      const { status } = await Api.post('/tags', { ...newTag, id: undefined });
      if (status !== 200) {
        if (status === 409) {
          setMessageSnackbar('Já existe uma tag com este nome abaixo desta tag pai.');
          setVariantSnackbar('error');
          return setShowSnackbar(true);
        }
        setMessageSnackbar('Houve um erro no servidor, tente novamente mais tarde.');
        setVariantSnackbar('error');
        return setShowSnackbar(true);
      }

      setMessageSnackbar('Tag adicionada com sucesso');
      setVariantSnackbar('success');
      setBusca({ ...busca });
      const { status: status1, data: data1 } = await Api.get('/tags/raw');
      if (status1 !== 200) {
        console.log(`TagModal fetch /tags/raw`, status1, data1)
        alert('Houve um erro!');
        return setOpen(false);
      }
      const newTagList = data1.map((tag) => {
        return { label: tag.tag_name, value: tag.id }
      });
      setTagList(newTagList);
      dispatch(authActions.actions.renewTag(true));
      return setShowSnackbar(true);
    }

    const { status } = await Api.put(`/tags/${values.id}`, { tag_name: newTag.tag_name, parent_id: newTag.parent_id });
    if (status !== 200) {
      if (status === 409) {
        setMessageSnackbar('Já existe uma tag com este nome abaixo desta tag pai.');
        setVariantSnackbar('error');
        return setShowSnackbar(true);
      }
      setMessageSnackbar('Houve um erro no servidor, tente novamente mais tarde.');
      setVariantSnackbar('error');
      return setShowSnackbar(true);
    }

    setBusca({ ...busca });
    const { status: status2, data: data2 } = await Api.get('/tags/raw');
    if (status2 !== 200) {
      console.log(`TagModal fetch /tags/raw`, status2, data2)
      alert('Houve um erro!');
      return setOpen(false);
    }
    const newTagList = data2.map((tag) => {
      return { label: tag.tag_name, value: tag.id }
    });
    setTagList(newTagList);
    dispatch(authActions.actions.renewTag(true));
    return setOpen(false);
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      const { status, data } = await Api.get('/tags/raw');
      if (status !== 200) {
        console.log(`TagModal fetch /tags/raw`, status, data)
        alert('Houve um erro!');
        return setOpen(false);
      }
      const newTagList = data.map((tag) => {
        return { label: tag.tag_name, value: tag.id }
      });
      setTagList(newTagList);
      if (tag) {
        const parentTag = data.filter((i) => i.id === tag.parent_id);
        setValues({
          id: tag.id,
          name: tag.tag_name,
          parent_id: parentTag.length > 0 ? { value: parentTag[0].id, label: parentTag[0].tag_name } : { value: null, label: 'Nada selecionado' }
        });
      } else {
        setValues({
          id: null,
          name: '',
          parent_id: { value: null, label: 'Nada selecionado' },
        });
      }
    };
    fetchInitialData();
  }, [open]);

  return <Modal show={open} size="md">
    <Modal.Header closeButton>
      <Modal.Title>{values.id !== null ? 'Editar Tag' : 'Nova Tag'}</Modal.Title>
    </Modal.Header>
    <form onSubmit={handleSubmit}>
      <Modal.Body>
        <TextField
          id="standard-name"
          label="Nome"
          className={classes.textField}
          value={values.name}
          onChange={handleChange('name')}
          required
          margin="normal"
        />
        <Autocomplete
          options={[{ label: 'Nada selecionado', value: null }, ...tagList]}
          onChange={handleChangeSingle}
          value={values.parent_id}
          label="Tag Pai"
          placeholder="Selecione uma tag..."
        />
        <Snackbar open={showSnackbar} setOpen={setShowSnackbar} message={messageSnackbar} variant={variantSnackbar} />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="contained" color="secondary" className={classes.button} onClick={() => setOpen(false)}>Fechar</Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          type="submit"
          disabled={tag && tag.tag_name === values.name && tag.parent_id === values.parent_id.value}
        >{values.id !== null ? 'Salvar' : 'Adicionar'}</Button>
      </Modal.Footer>
    </form>
  </Modal>

};

export default TagModal;
