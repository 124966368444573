
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
  resize: {
    fontSize: 16,
  },
}));
export const Delay = ({ className, style, actions, handleChange }) => {
  const classes = useStyles();

  return (
    <>
      <br />
      <TextField
        id="standard-delay"
        label="Delay (em milissegundos)"
        className={className}
        style={style}
        value={actions.value}
        onChange={handleChange('value', 'actions')}
        margin="normal"
        type="number"
        step="1"
        InputProps={{
          classes: {
            input: classes.resize,
          },
          inputProps: {
            min: 0,
            max: 300000,
          },
        }}
        required
      />
    </>
  )
};
