import React from 'react';
import { Bar } from 'react-chartjs-2';

export default function SimpleChart(params) {

  var initalRange = [];
  var finalRange = [];
  var colors = [];
  params.values.forEach(value => {
    initalRange.push(params.range[0])
    finalRange.push(params.range[1])

    if (value < params.range[0]) {
      colors.push('#20B2AA');
    }

    if (value >= params.range[0] && value <= params.range[1]) {
      colors.push('#00FF7F')
    }

    if (value > params.range[1]) {
      colors.push('#f5291d')
    }

  });

  const state = {
    labels: params.title,
    datasets: [
      {
      type:'line',
      data: finalRange,
      fill: false,
      borderColor: '#EC932F',
      backgroundColor: '#EC932F',
      pointBorderColor: '#EC932F',
      pointBackgroundColor: '#EC932F',
      pointHoverBackgroundColor: '#EC932F',
      pointHoverBorderColor: '#EC932F',
      pointRadius: 0,
      borderWidth: 2
      },
      {
        type:'line',
        data: initalRange,
        fill: false,
        borderColor: '#EC932F',
        backgroundColor: '#EC932F',
        pointBorderColor: '#EC932F',
        pointBackgroundColor: '#EC932F',
        pointHoverBackgroundColor: '#EC932F',
        pointHoverBorderColor: '#EC932F',
        pointRadius: 0,
        borderWidth: 2
        },
      {
        type: 'bar',
        backgroundColor: colors,
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 1,
        data: params.values
      }
    ]
  }

  return (
    <div>
      <Bar
        data={state}
        options={{
          title: {
            display: false
          },
          legend: {
            display: false
          },
          scales: { xAxes: [{ display: false, }], yAxes: [{ display: false, }], },
        }}
      />
    </div>
  );
}
