/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '../autocomplete/Autocomplete';
import AutocompleteNew from '../autocomplete/AutocompleteNew';
import Swal from 'sweetalert2';
import Api from '../../../../app/services/Api';
import { fetchUsers } from '../../../_helpers';

import ItemList from '../ItemsList';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  table: {
    marginTop: '10px',
  },
  autoComplete: {
    width: '50%',
  },
  tagNameTable: {
    width: '90%',
  },
}));

const EquipamentoModal = ({ open, setOpen, equipamento, busca, setBusca, user }) => {
  const classes = useStyles();
  const [enviromentsList, setEnviromentsList] = useState([]);
  const [values, setValues] = useState({
    id: null,
    user_id: 0,
    name: '',
    enviromentId: null,
    variables: [],
    tags: [],
    tagId: null,
  });
  const [users, setUsers] = useState([]);

  const handleChangeSingleEnv = (value) => {
    return setValues({ ...values, enviromentId: value });
  }

  const handleChangeSingleVar = (value) => {
    const checkVariables = values.variables.filter((i) => i.id === value.id);
    if (checkVariables.length === 0) {
      setValues({ ...values, variables: [...values.variables, { id: value.id, name: value.label }] });
    }
  }

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    const newEquipamento = {
      name: values.name,
      tags: values.tags,
      variables: values.variables.map((i) => i.id),
      user_id: values.user_id,
    };

    const { status, data } = await Api.put(`/equipments/${equipamento.id}`, { ...newEquipamento });
    if (status !== 200) {
      console.log(status, data);
      const text = status === 401 ? 'Você não tem permissão para realizar esta ação.' : 'Houve um problema na comunicação com a API, tente novamente mais tarde.';
      return Swal.fire({
        title: 'Atenção!',
        text,
        icon: 'warning',
      });
    }

    setBusca({ ...busca });
    return setOpen(false);
  };

  const handleVariableRemove = (variable) => {
    const newVariables = values.variables.filter((subVariable) => subVariable.id !== variable.id);
    setValues({ ...values, variables: newVariables });
  };

  const handleChangeOwner = (value) => {
    setValues({...values, user_id: value.value });
  }
  useEffect(() => {
    const fetchInitialData = async () => {
      if (equipamento) {
        const responseTags = await Api.get(`/equipments/${equipamento.id}/tags`);
        if (responseTags.status !== 200) {
          console.log(`EquipamentoModal fetch /equipments/${equipamento.id}/tags`, responseTags.status, responseTags.data)
          alert('Houve um erro!');
          return setOpen(false);
        }

        const responseEnviroments = await Api.get(`/equipments/${equipamento.id}/enviroments`);
        if (responseEnviroments.status !== 200) {
          console.log(`EquipamentoModal fetch /equipments/${equipamento.id}/enviroments`, responseEnviroments.status, responseEnviroments.data)
          alert('Houve um erro!');
          return setOpen(false);
        }

        const newEnviromentsList = responseEnviroments.data.map((enviroment) => {
          return { label: enviroment.name, value: enviroment.id };
        });
        setEnviromentsList(newEnviromentsList);

        const responseVariables = await Api.get(`/equipments/${equipamento.id}/variables`);
        if (responseVariables.status !== 200) {
          console.log(`EquipamentoModal fetch /equipments/${equipamento.id}/variables`, responseVariables.status, responseVariables.data)
          alert('Houve um erro!');
          return setOpen(false);
        }

        setValues({
          id: equipamento.id,
          user_id: 0,
          name: equipamento.name,
          mac_id: equipamento.mac_id,
          variables: responseVariables.data,
          enviromentId: equipamento.enviroment_id,
          tags: responseTags.data.filter((tag) => !tag.system_var).map((i) => i.id),
          tagId: null,
        });
      } else {
        setValues({
          id: null,
          user_id: 0,
          name: '',
          mac_id: '',
          variables: [],
          enviromentId: null,
          tags: [],
          tagId: null,
        });
      }
    };
    fetchInitialData();
    fetchUsers(setUsers);
  }, [equipamento]);

  return <Modal show={open} size="lg">
    <Modal.Header closeButton>
      <Modal.Title>{values.id !== null ? 'Editar Equipamento' : 'Novo Equipamento'}</Modal.Title>
    </Modal.Header>
    <form onSubmit={handleSubmit}>
      <Modal.Body>
        <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal" style={{ width: '72%' }}>
          <TextField
            id="standard-name"
            label="Nome"
            className={classes.textField}
            value={values.name}
            onChange={handleChange('name')}
            margin="normal"
          />
        </div>
        <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal" style={{ width: '24%', marginLeft: 10}}>
          <TextField
            id="standard-mac"
            label="MAC"
            className={classes.textField}
            value={values.mac_id}
            margin="normal"
            disabled
          />
        </div>
        {user.user_level === 0 &&
          <>
            <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal" style={{ width: '48%' }}>
              <Autocomplete
                options={enviromentsList}
                onChange={handleChangeSingleEnv}
                value={values.enviromentId}
                label="Ambiente principal"
                placeholder="Selecione um ambiente..."
              />
            </div>
            <div style={{ width: '45%', marginLeft: 10, display: 'inline-block', marginTop: 23 }}>
              <AutocompleteNew
                handleChange={handleChangeSingleVar}
                endpoint="/variables/raw/autocomplete"
                label="Tipo de Variáveis"
              />
            </div>
            <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal" style={{ width: '48%' }}>
              <Autocomplete
                options={users}
                onChange={handleChangeOwner}
                label="Dono do equipamento"
              />
          </div>
            <ItemList title="Variáveis" items={values.variables} handleRemove={handleVariableRemove} label="name" key="id" />
          </>
        }
      </Modal.Body>

      <Modal.Footer>
        <Button variant="contained" color="secondary" className={classes.button} onClick={() => setOpen(false)}>Fechar</Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          type="submit"
        >{values.id !== null ? 'Salvar' : 'Adicionar'}</Button>
      </Modal.Footer>
    </form>
  </Modal>

};

export default EquipamentoModal;
