/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { ActionsColumnFormatter } from '../../../../app/pages/ActionColumnFormatter';
import Api from '../../../../app/services/Api';
import Swal from 'sweetalert2';
import { checkModuleAuth } from '../../../../app/@utils'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  button2: {
    width: '20%',
    margin: theme.spacing(3),
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  textField2: {
    width: '40%',
    margin: theme.spacing(3),
  },
  textField3: {
    width: '20%',
    margin: theme.spacing(3),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  table: {
    marginTop: '10px',
  },
  tagNameTable: {
    width: '90%',
    padding: '5px',
  },
  rowTablePadding: {
    padding: '5px',
  },
  actionField: {
    minWidth: '120px',
  }
}));

const VariableModal = ({ open, setOpen, variavel, user }) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    id: null,
    name: '',
    value: '',
    values: [],
  });

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleRemove = (item) => {
    Swal.fire({
      title: 'Atenção!',
      text: 'Tem certeza que deseja remover este valor? Todos os dados vinculados serão excluídos.',
      icon: 'warning',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { status, data } = await Api.delete(`/variables/${variavel.id}/value/${item.id}`);
        if (status !== 204) {
          console.log(data, status);
          return Swal.fire({
            title: 'Atenção!',
            text: 'Aconteceu um erro na API, tente novamente mais tarde.',
            icon: 'error',
            confirmButtonText: 'Entendi',
          });
        }

        setValues({ ...values, values: values.values.filter((i) => i.id !== item.id) });
      }
    });
  };

  const handleEdit = (value) => {
    setValues({
      ...values,
      id: value.id,
      name: user.user_level > 0 && value.value_nickname !== null && value.value_nickname !== '' ? value.value_nickname : value.name,
      value: value.value,
    });
  };

  // const handleClean = () => {
  //   setValues({ ...values, id: null, name: '', value: '' });
  // };

  const handleAdd = async () => {
    const newValue = {
      name: values.name,
      value: values.value,
    };

    if (newValue.name === '') {
      return Swal.fire({
        title: 'Atenção!',
        text: 'O campo nome é obrigatório.',
        icon: 'warning',
      });
    }

    if (newValue.value === '') {
      return Swal.fire({
        title: 'Atenção!',
        text: 'O campo valor é obrigatório.',
        icon: 'warning',
      });
    }

    if (values.id !== null) {
      const { data, status } = await Api.put(`/variables/${variavel.id}/value/${values.id}`, { ...newValue });
      if (status !== 200) {
        console.log(data, status)
        return Swal.fire({
          title: 'Atenção!',
          text: 'Houve um problema na comunicação com a API, tente novamente mais tarde.',
          icon: 'warning',
        });
      }

      const newValues = values.values.map((i) => {
        if (i.id === data.id) {
          return data;
        }
        return i;
      });
      return setValues({
        id: null,
        name: '',
        value: '',
        values: newValues,
      });
    }

    const { data, status } = await Api.post(`/variables/${variavel.id}/value`, { ...newValue });
    if (status !== 200) {
      return Swal.fire({
        title: 'Atenção!',
        text: 'Houve um problema na comunicação com a API, tente novamente mais tarde.',
        icon: 'warning',
      });
    }

    setValues({ id: null, name: '', value: '', values: [...values.values, data] });
  };

  const handleValueDefault = async (value) => {
    const newValue = {
      name: value.name,
      value: value.value,
      default: true,
    };

    const { status } = await Api.put(`/variables/${variavel.id}/value/${value.id}`, { ...newValue });
    if (status !== 200) {
      return Swal.fire({
        title: 'Atenção!',
        text: 'Houve um problema na comunicação com a API, tente novamente mais tarde.',
        icon: 'warning',
      });
    }
    const newValues = values.values.map((i) => {
      if (i.id === value.id) return { ...i, default: 1 };
      return { ...i, default: 0 };
    });
    setValues({ ...values, values: newValues });
  };

  useEffect(() => {
    const fetchApiData = async () => {
      const { data, status } = await Api.get(`/variables/${variavel.id}/values`);
      if (status !== 200) {
        return Swal.fire({
          title: 'Atenção!',
          text: 'Houve um problema na comunicação com a API, tente novamente mais tarde.',
          icon: 'warning',
        });
      }
      const newVariavel = {
        id: null,
        name: '',
        value: '',
        values: data,
      };
      setValues(newVariavel);
    };
    if (variavel) {
      fetchApiData();
    } else {
      setValues({
        id: null,
        name: '',
        value: '',
        values: [],
      });
    }
  }, [variavel]);

  return <Modal show={open} size={user.user_level === 0 ? 'lg' : 'md'}>
    <Modal.Header closeButton>
      <Modal.Title>{values.id !== null ? 'Editar Valor' : 'Novo Valor'}</Modal.Title>
    </Modal.Header>
    <form>
      <Modal.Body>
        {checkModuleAuth(user, 'variables', 'update') &&
          <>
            <TextField
              id="standard-name"
              label="Nome"
              className={classes.textField2}
              value={values.name}
              onChange={user.user_level > 0 && values.id === null ? () => {} : handleChange('name')}
              margin="normal"
            />
            <TextField
              id="standard-value"
              label="Valor"
              className={classes.textField3}
              value={values.value}
              onChange={user.user_level === 0 ? handleChange('value') : () => {}}
              margin="normal"
            />
            <Button variant="contained" color="secondary" disabled={user.user_level > 0 && values.id === null} className={classes.button2} onClick={() => handleAdd()}>{values.id === null ? 'Adicionar' : 'Salvar'}</Button>
          </>
        }
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Valor</TableCell>
              <TableCell className={classes.actionField}>Ações</TableCell>
              {user.user_level === 0 && <TableCell>Padrão</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {values.values.length > 0 ? values.values.sort((a, b) => a.value - b.value).map((value, idx) => {
              return <TableRow key={idx}>
                <TableCell component="th" scope="row" className={classes.tagNameTable}>{user.user_level > 0 && value.value_nickname !== null && value.value_nickname !== '' ? value.value_nickname : value.name}</TableCell>
                <TableCell component="th" scope="row" className={classes.tagNameTable}>{value.value}</TableCell>
                <TableCell>
                  <ActionsColumnFormatter
                    hideHistoric
                    hideRun
                    hideEdit={!checkModuleAuth(user, 'variables', 'update') && user.user_level > 0}
                    hideRemove={!checkModuleAuth(user, 'variables', 'delete') && user.user_level > 0}
                    handleEdit={() => handleEdit(value)}
                    handleRemove={() => handleRemove(value)}
                  />
                </TableCell>
                {user.user_level === 0 &&
                  <TableCell component="th" scope="row" className={classes.tagNameTable}>
                    {value.value !== '0' && <Button variant="contained" color={value.default ? 'primary' : 'secondary'} className={classes.button} onClick={value.default ? () => {} : () => handleValueDefault(value)}>{value.default ? 'Ativo' : 'Ativar'}</Button>}
                  </TableCell>
                }
              </TableRow>
            }) : <TableRow><TableCell colSpan={3} style={{ textAlign: 'center' }}>Nenhum valor adicionado</TableCell></TableRow>}
          </TableBody>
        </Table>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="contained" color="secondary" className={classes.button} onClick={() => setOpen(false)}>Fechar</Button>
      </Modal.Footer>
    </form>
  </Modal>

};

export default VariableModal;
