/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */

import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import GruposUsuariosModal from '../../_metronic/layout/components/modal/GruposUsuariosModal';
import TagsJoinModal from '../../_metronic/layout/components/modal/TagsJoinModal';
import EnviromentJoinModal from '../../_metronic/layout/components/modal/EnviromentJoinModal';
import VariablesJoinModal from '../../_metronic/layout/components/modal/VariablesJoinModal';
import PermissionsJoinModal from '../../_metronic/layout/components/modal/PermissionsJoinModal';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Pagination from '../components/Pagination/Pagination';
import Paper from '@material-ui/core/Paper';
import { ActionsColumnFormatter } from './ActionColumnFormatter'
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import AutocompleteNew from '../../_metronic/layout/components/autocomplete/AutocompleteNew';
import ItemList from '../../_metronic/layout/components/ItemsList';
import Loading from '../components/Loading/Loading';
import Swal from 'sweetalert2';
import Api from '../services/Api';
import { checkModuleAuth } from '../@utils'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  textField: {
    flex: 1,
    marginRight: '15px',
  },
}));

export default function GrupoUsuarios() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [input, setInput] = useState('');
  const [busca, setBusca] = useState({ per_page: 10, current_page: 1, filter_name: '' });
  const [modalShow, setModalShow] = useState(false);
  const [enviromentModalShow, setEnviromentModalShow] = useState(false);
  const [variablesModalShow, setVariablesModalShow] = useState(false);
  const [modalGrupoUsuarios, setModalGrupoUsuarios] = useState(null);
  const [tagModalShow, setTagModalShow] = useState(false);
  const [tagUrl, setTagUrl] = useState('');
  const [tagsBusca, setTagsBusca] = useState([]);
  const [permissionModalShow, setPermissionModalShow] = useState(false);
  const [permissionUrl, setPermissionUrl] = useState('');

  const user = useSelector(state => state.auth.user);

  const classes = useStyles();

  const handleRemove = (item) => {
    Swal.fire({
      title: 'Atenção!',
      text: 'Tem certeza que deseja remover este Grupo? Tudo vinculado a ele será perdido, inclusive os usuários.',
      icon: 'warning',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { status, data } = await Api.delete(`/groups/${item.id}`);
        if (status !== 204) {
          Swal.fire({
            title: 'Atenção!',
            text: 'Aconteceu um erro na API, tente novamente mais tarde.',
            icon: 'error',
            confirmButtonText: 'Entendi',
          });
          console.log(status, data);
        }

        setBusca({ ...busca });
      }
    });
  };

  const handleEdit = (item) => {
    setModalGrupoUsuarios(item);
    setModalShow(true);
  };

  const handleTag = (item) => {
    setTagUrl(`/groups/${item.id}/tags`);
    setTagModalShow(true);
  };

  const handleEnviroments = (item) => {
    setTagUrl(`/groups/${item.id}`);
    setEnviromentModalShow(true);
  };

  const handleVariables = (item) => {
    setTagUrl(`/groups/${item.id}`);
    setVariablesModalShow(true);
  };

  const handlePermission = (item) => {
    setPermissionUrl(`/groups/${item.id}`);
    setPermissionModalShow(true);
  };

  const handleAdd = () => {
    setModalGrupoUsuarios(null);
    setModalShow(true);
  };

  const handleBuscaLimpar = () => {
    setInput('');
    setBusca({ per_page: busca.per_page, current_page: 1, filter_name: '' });
  };

  const handleRemoveTag = (tag) => {
    const newTags = tagsBusca.filter((i) => i.id !== tag.id);
    setTagsBusca(newTags);
  };

  const handleChangeTag = (tag) => {
    const check = tagsBusca.filter((i) => i.id === tag.id);
    if (check.length > 0) return false;
    const newTags = [...tagsBusca, tag];
    setTagsBusca(newTags);
  };

  const handleBuscaInput = (event) => {
    const value = event.target.value;
    setInput(value);
    if (value.length > 3) {
      setBusca({ ...busca, current_page: 1, filter_name: event.target.value });
    }
    if (value.length === 0) {
      setBusca({ ...busca, current_page: 1, filter_name: '' });
    }
  };

  useEffect(() => {
    const fetchInitalData = async () => {
      const { status, data } = await Api.get('/groups', { params: { ...busca, tags: tagsBusca.map((i) => i.id).join(',') }});
      if (status !== 200) {
        return alert('teve um problema');
      }
      setList(data);
      setLoading(false);
    };
    fetchInitalData();
  }, [busca, tagsBusca]);

  if (loading) return <Loading />

  return (
    <>
      <Card>
        <CardHeader title="Grupos de Usuários">
          <CardHeaderToolbar>
            <div className="row">
              <div className="col-sm-6 col-12">
                <TextField
                  id="standard-name"
                  label="Busca texto"
                  className={classes.textField}
                  onChange={handleBuscaInput}
                  value={input}
                />
              </div>
              <div className="col-sm-6 col-12">
                <AutocompleteNew
                  handleChange={handleChangeTag}
                  endpoint="/tags/autocomplete"
                  label="Busca tag"
                  variant="4"
                />
              </div>
            </div>
            {checkModuleAuth(user, 'groups', 'create') &&
              <button
                type="button"
                className="btn btn-primary ml-sm-3"
                onClick={handleAdd}
              >
                Inserir
              </button>
            }
            {(tagsBusca.length > 0 || busca.filter_groups || busca.filter_parent || busca.filter_name !== '') && <button

              type="button"
              className="btn btn-secondary ml-3"
              onClick={handleBuscaLimpar}
            >
              Limpar Busca
            </button>}
          </CardHeaderToolbar>
          {tagsBusca.length > 0 &&
            <ItemList
              variant="2"
              items={tagsBusca}
              handleRemove={handleRemoveTag}
              label="label_name"
              key="id"
            />
          }
        </CardHeader>
        <CardBody>
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  {user.user_level === 0 && <TableCell>Usuário</TableCell>}
                  <TableCell>Data</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.data && list.data.length > 0 ? list.data.map(item => (
                  <TableRow key={item.id}>
                    <TableCell>
                      <Link
                        to={checkModuleAuth(user, 'users', 'read') ? { pathname: '/usuarios/listar', query: { group: item } } : {}}
                      >
                        {item.name}
                      </Link>
                    </TableCell>
                    {user.user_level === 0 && <TableCell>{item.user_name}</TableCell>}
                    <TableCell>{format(new Date(item.created_at), 'dd/MM/yyyy HH:mm:ss')}</TableCell>
                    <TableCell>
                      <ActionsColumnFormatter
                        hideHistoric
                        hideRun
                        showPermission={checkModuleAuth(user, 'permissions', 'read')}
                        showTag={checkModuleAuth(user, 'groups', 'update')}
                        showEnviroments={checkModuleAuth(user, 'groups', 'update')}
                        showVariables={checkModuleAuth(user, 'groups', 'update')}
                        handleEnviroments={() => handleEnviroments(item)}
                        handleVariables={() => handleVariables(item)}
                        hideEdit={!checkModuleAuth(user, 'groups', 'update')}
                        hideRemove={!checkModuleAuth(user, 'groups', 'delete')}
                        handleTag={() => handleTag(item)}
                        handlePermission={() => handlePermission(item)}
                        handleEdit={() => handleEdit(item)}
                        handleRemove={() => handleRemove(item)}
                      />
                    </TableCell>
                  </TableRow>
                )) : <TableRow><TableCell colSpan={4} style={{ textAlign: 'center' }}>Nenhuma informação encontrada</TableCell></TableRow>}
              </TableBody>
              <TableFooter>
                {list.pagination &&
                  <TableRow><Pagination busca={busca} setBusca={setBusca} colSpan={4} {...list.pagination} /></TableRow>
                }
              </TableFooter>
            </Table>
          </Paper>
        </CardBody>
      </Card>
      <GruposUsuariosModal open={modalShow} setOpen={setModalShow} grupo={modalGrupoUsuarios} busca={busca} setBusca={setBusca} />
      <TagsJoinModal
        endpoint={tagUrl}
        label="Grupos"
        open={tagModalShow}
        setOpen={setTagModalShow}
        />

      <EnviromentJoinModal
        endpoint={tagUrl}
        open={enviromentModalShow}
        setOpen={setEnviromentModalShow}
        />
      <VariablesJoinModal
        endpoint={tagUrl}
        open={variablesModalShow}
        setOpen={setVariablesModalShow}
        />
      <PermissionsJoinModal
        endpoint={permissionUrl}
        label="Grupos"
        open={permissionModalShow}
        setOpen={setPermissionModalShow}
        />
    </>
  );
}
