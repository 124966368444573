/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */

import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import UsuarioModal from '../../_metronic/layout/components/modal/UsuarioModal';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Pagination from '../components/Pagination/Pagination';
import Paper from '@material-ui/core/Paper';
import { ActionsColumnFormatter } from './ActionColumnFormatter';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import Loading from '../components/Loading/Loading';

import Swal from 'sweetalert2';
import Api from '../services/Api';
import { checkModuleAuth } from '../@utils';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  textField: {
    flex: 1,
    marginRight: '15px',
  },
}));

export default function Usuarios() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [input, setInput] = useState('');
  const [busca, setBusca] = useState({ per_page: 10, current_page: 1, filter_name: '' });
  const [modalShow, setModalShow] = useState(false);
  const [modalUsuario, setModalUsuario] = useState(null);

  const user = useSelector(state => state.auth.user);
  const { query } = useLocation();

  const classes = useStyles();

  const handleRemove = (item) => {
    Swal.fire({
      title: 'Atenção!',
      text: 'Tem certeza que deseja remover este usuário? Todos os usuários vinculados a ele serão excluídos.',
      icon: 'warning',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { status, data } = await Api.delete(`/users/${item.id}`);
        if (status !== 200) {
          Swal.fire({
            title: 'Atenção!',
            text: 'Aconteceu um erro na API, tente novamente mais tarde.',
            icon: 'error',
            confirmButtonText: 'Entendi',
          });
          console.log(data);
        }

        setBusca({ ...busca });
      }
    });
  };

  const handleEdit = (item) => {
    setModalUsuario(item);
    setModalShow(true);
  };

  const handleAdd = () => {
    setModalUsuario(null);
    setModalShow(true);
  };

  const handleBuscaGrupo = (ev, item) => {
    ev.preventDefault();
    console.log('Busca grupo', item.group_id, item);
    setBusca({ ...busca, filter_groups: [item.group_id], current_page: 1 });
  };

  const handleBuscaParent = (ev, item) => {
    ev.preventDefault();
    setBusca({ ...busca, filter_parent: [item.parent_id], current_page: 1 });
  };

  const handleBuscaLimpar = () => {
    setInput('');
    setBusca({ per_page: busca.per_page, current_page: 1, filter_name: '' });
  };

  const handleBuscaInput = (event) => {
    const value = event.target.value;
    setInput(value);
    if (value.length > 3) {
      setBusca({ ...busca, current_page: 1, filter_name: event.target.value });
    }
    if (value.length === 0) {
      setBusca({ ...busca, current_page: 1, filter_name: '' });
    }
  };

  useEffect(() => {
    const fetchInitalData = async () => {
      let buscaCustom = { ...busca };
      if (query && query.group && !busca.filter_groups) {
        buscaCustom = { ...buscaCustom, current_page: 1, filter_groups: [query.group.id] };
      }
      const { status, data } = await Api.get('/users', { params: buscaCustom });
      if (status !== 200) {
        console.log(status, data);
        return alert('teve um problema');
      }
      setList(data);
      setLoading(false);

    };
    fetchInitalData();
  }, [busca]);

  if (loading) return <Loading />

  return (
    <>
      <Card>
        <CardHeader title="Lista de Usuários">
          <CardHeaderToolbar>
            <TextField
              id="standard-name"
              label="Busca texto"
              className={classes.textField}
              onChange={handleBuscaInput}
              value={input}
            />
            {checkModuleAuth(user, 'users', 'create') && <button
              type="button"
              className="btn btn-primary"
              onClick={handleAdd}
            >
              Inserir
            </button>}
            {(busca.filter_groups || busca.filter_parent || busca.filter_name !== '') && <button
              type="button"
              className="btn btn-secondary ml-3"
              onClick={handleBuscaLimpar}
            >
              Limpar Busca
            </button>}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>E-mail</TableCell>
                  <TableCell>Grupo</TableCell>
                  {user.user_level === 0 && <TableCell>Usuário Pai</TableCell>}
                  <TableCell>Telefone</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.data && list.data.length > 0 ? list.data.map(item => (
                  <TableRow key={item.id}>
                    <TableCell><Link to={{ pathname: '/historico-acoes/listar', query: { user: item } }}>{item.name}</Link></TableCell>
                    <TableCell>{item.email}</TableCell>
                    <TableCell><a href="#link" onClick={(ev) => handleBuscaGrupo(ev, item)}>{item.group_name}</a></TableCell>
                    {user.user_level === 0 && <TableCell><a href="#link" onClick={(ev) => handleBuscaParent(ev, item)}>{item.parent_name}</a></TableCell>}
                    <TableCell>{item.phone}</TableCell>
                    <TableCell>{format(new Date(item.created_at), 'dd/MM/yyyy HH:mm:ss')}</TableCell>
                    <TableCell><ActionsColumnFormatter hideHistoric hideRun hideEdit={!checkModuleAuth(user, 'users', 'update')} hideRemove={!checkModuleAuth(user, 'users', 'delete') || user.id === item.id || (user.user_level > 1 && user.user_level > item.user_level)} handleEdit={() => handleEdit(item)} handleRemove={() => handleRemove(item)} /></TableCell>
                  </TableRow>
                )) : <TableRow><TableCell colSpan={7} style={{ textAlign: 'center' }}>Nenhuma informação encontrada</TableCell></TableRow>}
              </TableBody>
              <TableFooter>
                {list.pagination &&
                  <TableRow><Pagination busca={busca} setBusca={setBusca} colSpan={7} {...list.pagination} /></TableRow>
                }
              </TableFooter>
            </Table>
          </Paper>
        </CardBody>
      </Card>
      <UsuarioModal open={modalShow} setOpen={setModalShow} usuario={modalUsuario} busca={busca} setBusca={setBusca} loggedUser={user} />
    </>
  );
}
