/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import AmbientesModal from '../../_metronic/layout/components/modal/AmbientesModal';
import TagsJoinModal from '../../_metronic/layout/components/modal/TagsJoinModal';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Pagination from '../components/Pagination/Pagination';
import Paper from '@material-ui/core/Paper';
import { ActionsColumnFormatter } from './ActionColumnFormatter'
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import Loading from '../components/Loading/Loading';
import AutocompleteNew from '../../_metronic/layout/components/autocomplete/AutocompleteNew';
import ItemList from '../../_metronic/layout/components/ItemsList';
import Swal from 'sweetalert2';
import Api from '../services/Api';
import { checkModuleAuth } from '../@utils';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  textField: {
    flex: 1,
    marginRight: '15px',
  },
}));

export default function Ambientes() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [input, setInput] = useState('');
  const [busca, setBusca] = useState({ per_page: 10, current_page: 1, filter_name: '' });
  const [modalShow, setModalShow] = useState(false);
  const [modalAmbientes, setModalAmbientes] = useState(null);
  const [tagModalShow, setTagModalShow] = useState(false);
  const [tagUrl, setTagUrl] = useState('');
  const [tagsBusca, setTagsBusca] = useState([]);

  const user = useSelector(state => state.auth.user);

  const classes = useStyles();

  const handleRemove = (item) => {
    Swal.fire({
      title: 'Atenção!',
      text: 'Tem certeza que deseja remover este ambiente?',
      icon: 'warning',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { status, data } = await Api.delete(`/enviroments/${item.id}`);
        if (status !== 204) {
          Swal.fire({
            title: 'Atenção!',
            text: 'Aconteceu um erro na API, tente novamente mais tarde.',
            icon: 'error',
            confirmButtonText: 'Entendi',
          });
          console.log(data);
        }

        setBusca({ ...busca });
      }
    });
  };

  const handleEdit = (item) => {
    setModalAmbientes(item);
    setModalShow(true);
  };

  const handleAdd = () => {
    setModalAmbientes(null);
    setModalShow(true);
  };

  const handleRemoveTag = (tag) => {
    const newTags = tagsBusca.filter((i) => i.id !== tag.id);
    setTagsBusca(newTags);
  };

  const handleChangeTag = (tag) => {
    const check = tagsBusca.filter((i) => i.id === tag.id);
    if (check.length > 0) return false;
    const newTags = [...tagsBusca, tag];
    setTagsBusca(newTags);
  };

  const handleTag = (item) => {
    setTagUrl(`/enviroments/${item.id}/tags`);
    setTagModalShow(true);
  };

  const handleBuscaLimpar = () => {
    setTagsBusca([]);
    setInput('');
    setBusca({ per_page: busca.per_page, current_page: 1, filter_name: '' });
  };

  const handleBuscaInput = (event) => {
    const value = event.target.value;
    setInput(value);
    if (value.length > 3) {
      setBusca({ ...busca, current_page: 1, filter_name: event.target.value });
    }
    if (value.length === 0) {
      setBusca({ ...busca, current_page: 1, filter_name: '' });
    }
  };

  useEffect(() => {
    const fetchInitalData = async () => {
      const { status, data } = await Api.get('/enviroments', { params: { ...busca, tags: tagsBusca.map((i) => i.id).join(',') }});
      if (status !== 200) {
        return alert('teve um problema');
      }

      setList(data);
      setLoading(false);
    };
    fetchInitalData();
  }, [busca, tagsBusca]);

  if (loading) return <Loading />

  return (
    <>
      <Card>
        <CardHeader title="Ambientes">
          <CardHeaderToolbar>
            <div className="row">
              <div className="col-sm-6 col-12">
                <TextField
                  id="standard-name"
                  label="Busca texto"
                  className={classes.textField}
                  onChange={handleBuscaInput}
                  value={input}
                />
              </div>
              <div className="col-sm-6 col-12">
                <AutocompleteNew
                  handleChange={handleChangeTag}
                  endpoint="/tags/autocomplete"
                  label="Busca tag"
                  variant="4"
                />
              </div>
            </div>

            {checkModuleAuth(user, 'enviroments', 'create') && <button
              type="button"
              className="btn btn-primary ml-sm-3 mt-sm-0 mt-5"
              onClick={handleAdd}
            >
              Inserir
            </button>}
            {(tagsBusca.length > 0 || busca.filter_name !== '') && <button
                type="button"
                className="btn btn-secondary ml-3 mt-sm-0 mt-5"
                onClick={handleBuscaLimpar}
              >
                Limpar Busca
              </button>}
          </CardHeaderToolbar>
          {tagsBusca.length > 0 &&
            <ItemList
              variant="2"
              items={tagsBusca}
              handleRemove={handleRemoveTag}
              label="label_name"
              key="id"
            />
          }
        </CardHeader>
        <CardBody>
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  {user.user_level === 0 && <TableCell>Usuário</TableCell>}
                  <TableCell>Data</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.data && list.data.length > 0 ? list.data.map(item => (
                  <TableRow key={item.id}>
                    <TableCell><Link to={{ pathname: '/variaveis/listar', query: { enviroment: item }}}>{item.name}</Link></TableCell>
                    {user.user_level === 0 && <TableCell>{item.user_name}</TableCell>}
                    <TableCell>{format(new Date(item.created_at), 'dd/MM/yyyy HH:mm:ss')}</TableCell>
                    <TableCell>
                      <ActionsColumnFormatter
                        hideHistoric
                        hideRun
                        showTag
                        hideEdit={!checkModuleAuth(user, 'enviroments', 'update')}
                        hideRemove={!checkModuleAuth(user, 'enviroments', 'delete')}
                        handleTag={() => handleTag(item)}
                        handleEdit={() => handleEdit(item)}
                        handleRemove={() => handleRemove(item)}
                      />
                    </TableCell>
                  </TableRow>
                )) : <TableRow><TableCell colSpan={4} style={{ textAlign: 'center' }}>Nenhuma informação encontrada</TableCell></TableRow>}
              </TableBody>
              <TableFooter>
                {list.pagination &&
                  <TableRow><Pagination busca={busca} setBusca={setBusca} colSpan={4} {...list.pagination} /></TableRow>
                }
              </TableFooter>
            </Table>
          </Paper>
        </CardBody>
      </Card>
      <AmbientesModal open={modalShow} setOpen={setModalShow} ambiente={modalAmbientes} busca={busca} setBusca={setBusca} />
      <TagsJoinModal
        endpoint={tagUrl}
        label="Ambientes"
        open={tagModalShow}
        setOpen={setTagModalShow}
        />
    </>
  );
}
