import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { useFormik } from "formik";
import Api from '../../../services/Api';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';


function ResetPassword(props) {

  // Get parameters from url
  const search = new URLSearchParams(props.location.search)
  const reset_token = search.get('reset_token');

  const history = useHistory();

  const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required('Senha não preenchida.')
      .min(8, 'Mínimo de 8 caracteres.')
      .matches(/^(?=.*\d)/, 'Deve conter pelo menos um número.')
      .matches(/^(?=.*[A-Z])/, 'Pelo menos um caractere maiúsculo')
      .matches(/^(?=.*[a-z])/, 'Pelo menos um caractere minúsculo'),

    confirmPassword: Yup.string()
      .required('Confirmação de senha não preenchida.')
      .test('passwords-match', 'As senhas não conferem.', function (value) {
        return this.parent.password === value
      })
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: resetPasswordSchema,
    onSubmit: values => {
      values.reset_token = reset_token;
      Api.post('auth/reset-password', values)
        .then(async ({ data, status }) => {
          if (status !== 200) {
            formik.setErrors({ password: `Ocorreu algum problema ao atualizar a senha. 
            Verifique se o token foi expirado ou tente novamente mais tarde.` });
          } else {
            history.push(`/auth/login?reset_password_sucess=true`);
          }
        });
    }
  });

  return (
    <>
      {
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Recuperação de Senha</h3>
          </div>

          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            <div className="form-group fv-plugins-icon-container">
              <div className="text-muted font-weight-bold">
                Digite sua nova senha
              </div>
              <input
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6`}
                name="password"
                onChange={formik.handleChange}
                value={formik.values.password}
              />
              {formik.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.password}</div>
                </div>
              ) : null}
              <br />
              <div className="text-muted font-weight-bold">
                Confirme sua nova senha
              </div>
              <input
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6`}
                name="confirmPassword"
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
              />
              {formik.errors.confirmPassword ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.confirmPassword}</div>
                </div>
              ) : null}
            </div>

            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                Enviar
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancelar
                </button>
              </Link>
            </div>
          </form>
        </div>
      }
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ResetPassword));
