
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import Autocomplete from '../../../_metronic/layout/components/autocomplete/Autocomplete';

const daysWeeks = [
  {
    value: '1',
    label: 'Domingo',
  },
  {
    value: '2',
    label: 'Segunda',
  },
  {
    value: '3',
    label: 'Terça',
  },
  {
    value: '4',
    label: 'Quarta',
  },
  {
    value: '5',
    label: 'Quinta',
  },
  {
    value: '6',
    label: 'Sexta',
  },
  {
    value: '7',
    label: 'Sábado',
  },
];

const repeatTask = [
  {
    value: 'no_repetition',
    label: 'Não se repete',
  },
  {
    value: 'daily',
    label: 'Diária',
  },
  {
    value: 'weekly',
    label: 'Semanal',
  },
]

const endIn = [
  {
    value: 'never',
    label: 'Nunca',
  },
  {
    value: 'in',
    label: 'Em',
  },
];

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'baseline',
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    width: '48%',
    '&:not(:last-of-type)': {
      marginRight: 26,
    },
    '&.margin-top-16': {
      marginTop: 16,
    },
    '&.full-width': {
      width: '100%',
    },
    '@media (max-width: 1024px)': {
      width: '100%',
      '&:not(:last-of-type)': {
        marginRight: 0,
      },
      '&.margin-top-16': {
        marginTop: 0,
      },
    },
  },
  resize: {
    fontSize: 16,
  },
}));

export const Scheduling = ({ className, style, basicInformation, handleChange, handleChangeSelect, handleChangeWeekDays }) => {
  const classes = useStyles();

  const endInComponent = () => {
    return (
      <>
        <Autocomplete
          label="Terminar em"
          options={endIn}
          value={endIn.find(item => item.value === basicInformation.finishRepeatIn)}
          onChange={handleChangeSelect('finishRepeatIn', 'basicInformation')}
          required
        />
        {basicInformation.finishRepeatIn === 'in' && <>
          <br /><br />
          <TextField
            id="stop-date"
            label="Data de encerramento"
            type="date"
            value={basicInformation.closing_date}
            onChange={handleChange('closing_date', 'basicInformation')}
            className={`${classes.textField} full-width`}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            required
          />
          <br />
        </>}
      </>
    )
  }

  return (
    <>
    <Autocomplete label="Repetição" options={repeatTask} onChange={handleChangeSelect('schedule_type', 'basicInformation')} value={repeatTask.find(item => item.value === basicInformation.schedule_type)} required />
    {['no_repetition'].includes(basicInformation.schedule_type) && <>
      <br />
      <div className={classes.container}>
        <TextField
          id="initial-date"
          label="Data"
          type="date"
          value={basicInformation.schedule_date}
          onChange={handleChange('schedule_date', 'basicInformation')}
          className={`${classes.textField} margin-top-16`}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          required
        />
        <TextField
          id="standard-start-time"
          label="Hora"
          className={classes.textField}
          value={basicInformation.execution_time}
          onChange={handleChange('execution_time', 'basicInformation')}
          margin="normal"
          type="time"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            step: 300, // 5 min
            classes: {
              input: classes.resize,
            },
          }}
          style={{ fontSize: '16px !important'}}
          required
        />
      </div>
      <br />
    </>}
    {['daily'].includes(basicInformation.schedule_type) && <>
      <br />
      <TextField
        id="standard-execution-time"
        label="Hora de execução"
        className={`${classes.textField} full-width`}
        value={basicInformation.execution_time}
        onChange={handleChange('execution_time', 'basicInformation')}
        margin="normal"
        type="time"
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          step: 300, // 5 min
          classes: {
            input: classes.resize,
          },
        }}
        required
      />
      <br /><br />
      {endInComponent()}
    </>}
      <br />
      {basicInformation.schedule_type === 'weekly' && <>
        <br />
        <Autocomplete isMulti label="Dias da Semana" options={daysWeeks} value={basicInformation.schedule_days} onChange={handleChangeWeekDays} required />
        <br />
        <TextField
        id="standard-execution-time"
        label="Hora de execução"
        className={`${classes.textField} full-width`}
        value={basicInformation.execution_time}
        onChange={handleChange('execution_time', 'basicInformation')}
        margin="normal"
        type="time"
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          step: 300, // 5 min
          classes: {
            input: classes.resize,
          },
        }}
        required
      />
      <br /><br />
      {endInComponent()}
      <br />
      </>}
    </>
  )
};
