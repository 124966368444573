/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Snackbar from '../snackbar/Snackbar';
import Autocomplete from '../autocomplete/Autocomplete';
import AutocompleteNew from '../autocomplete/AutocompleteNew';
import Swal from 'sweetalert2';
import Api from '../../../../app/services/Api';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  textField: {
    width: '40%',
    margin: theme.spacing(3),
  },
  parentLabel: {
    fontSize: 10,
    marginLeft: 5,
  },
  closeButton: {
    color: 'red !important',
    fontWeight: 'bold',
  },
}));

export const phoneMaskInput = (value) => {
  if (!value) return '';
  const x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
  return !x[2] ? x[1] : `(${x[1]}) ${x[2] + (x[3] ? `-${x[3]}` : '')}`;
};


const UsuarioModal = ({ open, setOpen, busca, setBusca, usuario, loggedUser }) => {
  const classes = useStyles();
  const [groupsList, setGroupsList] = useState([]);
  const [levelList, setLevelList] = useState(loggedUser.user_level > 0 ? [] : [{ value: 0, label: '0' }, { value: 1, label: '1' }]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [messageSnackbar] = useState('');
  const [variantSnackbar] = useState(false);
  const [values, setValues] = useState({
    id: null,
    name: '',
    email: '',
    group_id: { value: 0, label: 'Nada selecionado' },
    parent_id: null,
    user_max_level: null,
    user_level: null,
    phone: '',
    password: '',
    checkPassword: ''
  });

  useEffect(() => {
    if (usuario) setValues({
      id: usuario.id,
      name: usuario.name,
      email: usuario.email,
      group_id: { value: usuario.group_id, label: usuario.group_name },
      parent_id: usuario.parent_id !== null ? { id: usuario.parent_id, label: `${usuario.parent_name} - ${usuario.parent_email}` } : null,
      user_level: { value: usuario.user_level, label: usuario.user_level },
      user_max_level: null,
      phone: phoneMaskInput(usuario.phone)
    });
    else setValues({
      id: null,
      name: '',
      email: '',
      group_id: { value: 0, label: 'Nada selecionado' },
      parent_id: null,
      user_max_level: null,
      user_level: null,
      phone: '',
      password: '',
      checkPassword: ''
    });
  }, [open]);

  useEffect(() => {
    const fetchInitalData = async () => {
      const { status, data } = await Api.get('/groups/select-list');
      if (status !== 200) {
        alert('Houve um erro!');
        return setOpen(false);
      }
      setGroupsList((data.map((i) => ({ label: i.name, value: i.id }))));

      if (usuario?.id) {
        const responseUser = await Api.get(`/users/${usuario.id}`);
        if (responseUser.status !== 200) {
          alert('Houve um erro!');
          return setOpen(false);
        }
        if (responseUser.data.max_level !== null) {
          setValues({
            id: usuario.id,
            name: usuario.name,
            email: usuario.email,
            group_id: { value: usuario.group_id, label: usuario.group_name },
            parent_id: usuario.parent_id !== null ? { id: usuario.parent_id, label: `${usuario.parent_name} - ${usuario.parent_email}` } : null,
            phone: phoneMaskInput(usuario.phone),
            user_max_level: { value: responseUser.data.max_level, label: responseUser.data.max_level,
            user_level: usuario.user_level
          } });
          const level = [];
          for (let i = 0; i <= responseUser.data.max_level + 1; i++) {
            if (loggedUser.user_level > 0 && i === 0) continue;
            level.push({ value: i, label: i });
          }
          setLevelList(level);
        }
      }
    };
    fetchInitalData();
  }, [usuario]);

  const handleChangeSingle = (value) => {
    setValues({ ...values, group_id: value });
  }

  const handleChangeLevel = (value) => {
    setValues({ ...values, user_max_level: value });
  }

  const handleChangeParent = (value) => {
    setValues({ ...values, parent_id: value });
    const level = [];
    for (let i = 0; i <= value.user_max_level + 1; i++) {
      level.push({ value: i, label: i });
    }
    setLevelList(level);
  }

  const handleChange = name => event => {
    setValues({ ...values, [name]: name === 'phone' ? phoneMaskInput(event.target.value) : event.target.value });
  };

  const handleRemoveParent = event => {
    event.preventDefault();
    setValues({ ...values, parent_id: null });
    setLevelList([]);
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    if (values.password !== '' && values.password !== values.checkPassword) {
      return Swal.fire({
        title: 'Atenção!',
        text: 'A senha precisa ser igual a confirmação de senha',
        icon: 'warning',
      });
    }

    if (values.group_id === 0 || values.group_id === null || values.group_id.value === 0) {
      return Swal.fire({
        title: 'Atenção!',
        text: 'Você precisa selecionar um grupo',
        icon: 'warning',
      });
    }

    if (values.phone.length < 14 || values.phone.length > 15) {
      return Swal.fire({
        title: 'Atenção!',
        text: 'Formato de telefone inválido',
        icon: 'warning',
      });
    }

    const newUsuario = {
      ...values,
      checkPassword: undefined,
      id: undefined,
      user_max_level: undefined,
      group_id: values.group_id.value,
      parent_id: values.parent_id ? values.parent_id.id : null,
      user_level: values.parent_id ? values.user_max_level.value : null,
      phone: values.phone.replace('(', '').replace(')', '').replace(' ', '').replace('-', '')
    };
    // return console.log(newUsuario);
    if (values.id === null) {
      const { status } = await Api.post('/new-user', { ...newUsuario });
      if (status === 409) {
        return Swal.fire({
          title: 'Atenção!',
          text: 'Este e-mail já está em uso, por favor utilize outro.',
          icon: 'warning',
        });
      }
      setBusca({ ...busca });
      setValues({
        id: null,
        name: '',
        email: '',
        group_id: { value: 0, label: 'Nada selecionado' },
        parent_id: null,
        user_level: null,
        phone: '',
        password: '',
        checkPassword: ''
      });
      return setOpen(false);
    }

    const { status } = await Api.put(`/users/${usuario.id}`, { ...newUsuario });
    if (status !== 200) {
      return Swal.fire({
        title: 'Atenção!',
        text: 'Houve um problema na comunicação com a API, tente novamente mais tarde.',
        icon: 'warning',
      });
    }
    setBusca({ ...busca });
    return setOpen(false);
  }

  return <Modal show={open} size="lg">
    <Modal.Header closeButton>
      <Modal.Title>{values.id !== null ? 'Editar Usuário' : 'Novo Usuário'}</Modal.Title>
    </Modal.Header>
    <form onSubmit={handleSubmit}>
      <Modal.Body>
        <TextField
          id="standard-name"
          label="Nome"
          className={classes.textField}
          value={values.name}
          onChange={handleChange('name')}
          margin="normal"
          required
        />
        <TextField
          id="standard-email"
          label="E-mail"
          type="email"
          className={classes.textField}
          value={values.email}
          onChange={handleChange('email')}
          margin="normal"
          required
        />
        <TextField
          id="standard-telefone"
          label="Telefone"
          className={classes.textField}
          value={values.phone}
          onChange={handleChange('phone')}
          margin="normal"
          required
        />
        <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal" style={{ width: '40%', margin: 20 }}>
          <Autocomplete
            options={[{ label: 'Nada selecionado', value: 0 }, ...groupsList]}
            onChange={handleChangeSingle}
            value={values.group_id}
            label="Grupo"
            placeholder="Selecione um grupo..."
          />
        </div>
        {loggedUser.user_level === 0 &&
          <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal" style={{ width: '40%', margin: 20 }}>
            {values.parent_id === null ?
              <AutocompleteNew
                handleChange={handleChangeParent}
                endpoint="/users/autocomplete"
                label="Usuário Pai"
                variant="3"
              /> :
              <div>
                <p className={classes.parentLabel}>Usuário Pai</p>
                <p>{values?.parent_id?.label || ''} <a href="#ck" className={classes.closeButton} onClick={(ev) => handleRemoveParent(ev)}>X</a></p>
              </div>
            }
          </div>
        }
        {levelList.length > 0 && loggedUser.id !== values.id &&
          <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal" style={{ width: '40%', margin: 20 }}>
            <Autocomplete
              options={levelList}
              onChange={handleChangeLevel}
              value={values.user_level}
              label="Usuário Level"
              placeholder="Selecione um level..."
            />
          </div>
        }
        <TextField
          id="standard-senha"
          label="Senha"
          type="password"
          className={classes.textField}
          value={values.password}
          onChange={handleChange('password')}
          margin="normal"
          required={values.id === null}
        />
        <TextField
          id="standard-confirmar-senha"
          label="Confirmar Senha"
          type="password"
          className={classes.textField}
          value={values.checkPassword}
          onChange={handleChange('checkPassword')}
          margin="normal"
          required={values.id === null}
        />
        <Snackbar open={showSnackbar} setOpen={setShowSnackbar} message={messageSnackbar} variant={variantSnackbar} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="contained" color="secondary" className={classes.button} onClick={() => setOpen(false)}>Fechar</Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          type="submit"
          disabled={usuario && JSON.stringify(usuario) === JSON.stringify(values)}
        >{values.id !== null ? 'Salvar' : 'Adicionar'}</Button>
      </Modal.Footer>
    </form>
  </Modal>

};

export default UsuarioModal;
