/* eslint-disable no-restricted-imports */

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { ActionsColumnFormatter } from '../../pages/ActionColumnFormatter';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
}));

const typeAction = {
  variable: 'Variável',
  tag: 'Tag',
  delay: 'Delay',
  task: 'Tarefa',
};

const actionTask = {
  enable: 'Habilitado',
  disable: 'Desabilitar',
  execute: 'Executar',
};

export const ActionTable = ({ actions, handleAddActions, }) => {
  const classes = useStyles();

  const actionValue = (value) => {
    if(['enable', 'disable', 'execute'].includes(value)) {
      return actionTask[value];
    }
    return value;
  };

  const handleDragEnd = (results) => {
    let tempAction = [...actions.addedActions];
    let newActions = tempAction;
    if(actions.addedActions.length > 1) {
      let [selectedRow] = tempAction.splice(results.source.index, 1);
      tempAction.splice(results.destination.index, 0, selectedRow);
      newActions = tempAction.map((action, index) => ({
        ...action,
        order: index + 1,
      }));
    }
    handleAddActions(newActions, 'dragDrop');
  };

  return (
    <DragDropContext onDragEnd={(results) => handleDragEnd(results)}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Ordem</TableCell>
            <TableCell>Tipo</TableCell>
            <TableCell>Item</TableCell>
            <TableCell>Valor</TableCell>
            <TableCell>Ações</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <Droppable droppableId="tbody ">
          {(provided) => (
            <TableBody innerRef={provided.innerRef} {...provided.droppableProps}>
              {actions.addedActions.map((item, index) => (
                <Draggable draggableId={item.name} index={index} key={item.name}>
                  {(provided) => (
                    <TableRow innerRef={provided.innerRef} {...provided.draggableProps}>
                      <TableCell component="th" scope="row">
                        {item.order}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {typeAction[item.action_type]}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {item.variable_id || item.tag_id || item.action_task_id ? item.label : `Atraso de ${item.value} ms`}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {item.value || item.variable_value_id || item.task_action_type ?  actionValue(item.valueLabel) : '—'}
                      </TableCell>
                      <TableCell>
                      <ActionsColumnFormatter
                        hideHistoric
                        hideRun
                        hideEdit
                        handleRemove={() =>  handleAddActions(item, 'delete')}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row" {...provided.dragHandleProps}>
                        <SVG src={toAbsoluteUrl("/media/svg/icons/General/DragReorder.svg")} />
                    </TableCell>
                    </TableRow>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </TableBody>
          )}
        </Droppable>
      </Table>
    </DragDropContext>
  );
};
