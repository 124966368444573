// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

export function ActionsColumnFormatter({
    handleEdit,
    handleRemove,
    handleHistoric,
    handleRun,
    handleEnviroments,
    handleVariables,
    handleTag,
    handlePermission,
    hideEdit,
    hideRemove,
    labelEdit,
    hideHistoric,
    hideRun,
    showTag,
    showEnviroments,
    showVariables,
    showPermission,
  }) {
  labelEdit = labelEdit || 'Editar'
  return (
    <>
      {hideEdit !== true && <a
        title={labelEdit}
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
        onClick={handleEdit}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            title={labelEdit}
            alt={labelEdit}
          />
        </span>
      </a>}

      <> </>

      {hideRemove !== true && <a
        title="Deletar"
        className="btn btn-icon btn-light btn-hover-danger btn-sm"
        onClick={handleRemove}
      >
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} title="Deletar" alt={"Editar"} />
        </span>
      </a>}

      <> </>

      {hideHistoric !== true && <a
        title="Histórico"
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
        onClick={handleHistoric}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")}
            title="Histórico"
            alt={"Histórico"}
          />
        </span>
      </a>}

      {hideRun !== true && <a
        title="Executar"
        className="btn btn-icon btn-light btn-hover-primary btn-sm"
        onClick={handleRun}
      >
        <span className="svg-icon svg-icon-md svg-icon-success">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Media/Play.svg")}
            title="Executar"
            alt={"Executar"}
          />
        </span>
      </a>}

      {showTag && <a
        title="Tags"
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
        onClick={handleTag}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Price1.svg")} title="Tags" alt={"Tags"} />
        </span>
      </a>}

      <> </>

      {showEnviroments && <a
        title="Tags"
        className="btn btn-icon btn-light btn-hover-primary btn-sm"
        onClick={handleEnviroments}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/Usb-storage.svg")} title="Vincular Ambientes" alt={"Vincular Ambientes"} />
        </span>
      </a>}

      <> </>

      {showVariables && <a
        title="Tags"
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
        onClick={handleVariables}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/Server.svg")} title="Vincular Variáveis" alt={"Vincular Variáveis"} />
        </span>
      </a>}

      <> </>

      {showPermission && <a
        title="Permissions"
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
        onClick={handlePermission}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Safe.svg")} title="Permissions" alt={"Permissions"} />
        </span>
      </a>}
    </>
  );
}
