/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '../autocomplete/AutocompleteNew';
import Button from '@material-ui/core/Button';
import Api from '../../../../app/services/Api'

import ItemList from '../ItemsList'

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  textField: {
    width: '40%',
    margin: theme.spacing(3),
  },
}));


const TagsJoinModal = ({ endpoint, label, open, setOpen }) => {
  const classes = useStyles();
  const [tagList, setTagList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const { data, status } = await Api.get(endpoint);
      if (status !== 200) {
        console.log(data, status);
        return alert('Houve um erro!');
      }
      setTagList(data);
    };
    if (open) {
      fetchData();
    }
  }, [open]);

  const handleRemove = (item) => {
    const newTagList = tagList.filter((i) => i.id !== item.id);
    setTagList(newTagList);
  };

  const handleChange = (item) => {
    const check = tagList.filter((i) => i.id === item.id);
    if (check.length === 0) {
      const newTagList = [...tagList, item];
      setTagList(newTagList);
    }
  };

  const handleSubmit = async () => {
    const tags = tagList.map((i) => i.id);
    const { status, data } = await Api.post(endpoint, { tags });
    if (status !== 204) {
      console.log(data, status);
      return alert('Houve um erro!');
    }
    setOpen(false);
  };

  return <Modal show={open} size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Tags - {label}</Modal.Title>
    </Modal.Header>
      <Modal.Body>
        <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal" style={{ width: '40%', margin: 20 }}>
          <Autocomplete
            handleChange={handleChange}
            endpoint="/tags/autocomplete"
            label="Tags"
            variant="4"
          />
        </div>
        <ItemList variant="2" title="Tags vinculadas" items={tagList} handleRemove={handleRemove} label="label_name" key="id" />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="contained" color="secondary" className={classes.button} onClick={() => setOpen(false)}>Fechar</Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => handleSubmit()}
        >Salvar</Button>
      </Modal.Footer>
  </Modal>

};

export default TagsJoinModal;
