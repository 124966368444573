/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Snackbar from '../snackbar/Snackbar';
import Swal from 'sweetalert2';
import Api from '../../../../app/services/Api';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
}));


const GrupoUsuariosModal = ({ open, setOpen, busca, setBusca, grupo }) => {
  const classes = useStyles();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [messageSnackbar] = useState('');
  const [variantSnackbar] = useState(false);
  const [values, setValues] = useState({
    id: null,
    name: ''
  });

  useEffect(() => {
    if (grupo) setValues({
      id: grupo.id,
      name: grupo.name
    });
    else setValues({
      id: null,
      name: ''
    });
  }, [open]);

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    if (values.id === null) {
      const { status } = await Api.post('/groups', { name: values.name });
      if (status !== 200) {
        return Swal.fire({
          title: 'Atenção!',
          text: 'Houve um problema na comunicação com a API, tente novamente mais tarde.',
          icon: 'warning',
        });
      }
      setBusca({ ...busca });
      setValues({
        id: null,
        name: ''
      });
      return setOpen(false);
    }

    const { status, data } = await Api.put(`/groups/${grupo.id}`, { name: values.name });
    if (status !== 204) {
      console.log(status, data);
      return Swal.fire({
        title: 'Atenção!',
        text: 'Houve um problema na comunicação com a API, tente novamente mais tarde.',
        icon: 'warning',
      });
    }
    setBusca({ ...busca });
    return setOpen(false);
  }

  return <Modal show={open} size="md">
    <Modal.Header closeButton>
      <Modal.Title>{values.id !== null ? 'Editar Grupo de Usuário' : 'Novo Grupo de Usuário'}</Modal.Title>
    </Modal.Header>
    <form onSubmit={handleSubmit}>
      <Modal.Body>
        <TextField
          id="standard-name"
          label="Nome"
          className={classes.textField}
          value={values.name}
          onChange={handleChange('name')}
          margin="normal"
          required
        />
        <Snackbar open={showSnackbar} setOpen={setShowSnackbar} message={messageSnackbar} variant={variantSnackbar} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="contained" color="secondary" className={classes.button} onClick={() => setOpen(false)}>Fechar</Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          type="submit"
          disabled={grupo && JSON.stringify(grupo) === JSON.stringify(values)}
        >{values.id !== null ? 'Salvar' : 'Adicionar'}</Button>
      </Modal.Footer>
    </form>
  </Modal>

};

export default GrupoUsuariosModal;
