import React from 'react';
import { TablePagination } from '@material-ui/core';

const Pagination = ({ busca, setBusca, colSpan, total, perPage, currentPage, to }) => {
  const onChangeRowsPerPage = (ev) => {
    const page = parseInt(ev.target.value, 10);
    setBusca({ ...busca, per_page: page });
  };
  const onChangePage = (ev, page) => {
    const newPage = parseInt(page, 10) + 1;
    setBusca({ ...busca, current_page: newPage });
  };

  React.useEffect(() => {
    // console.log(`total ${total}, perPage ${perPage}, currentPage ${currentPage}`);
  }, [total, perPage, currentPage]);

  return <TablePagination
    colSpan={colSpan}
    count={total || to}
    rowsPerPage={perPage}
    rowsPerPageOptions={[5, 10, 25, 50, 100]}
    page={currentPage - 1}
    labelRowsPerPage="Itens por pagina:"
    onChangeRowsPerPage={onChangeRowsPerPage}
    onChangePage={onChangePage}
    SelectProps={{
      inputProps: { 'aria-label': 'Itens por pagina' },
      native: true,
    }}
  />
};

export default Pagination;

