/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */

import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Tag from '../../_metronic/layout/components/extras/Tag';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ChartBar from './ChartBarExample'
import { ActionsColumnFormatter } from './ActionColumnFormatter'
import {
  Card,
  CardBody,
  CardHeader,
} from "../../_metronic/_partials/controls";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
}));

export default function BuscaTag(props) {
  const [equipamentosList, setEquipamentosList] = useState([]);
  const [variaveisList, setVariaveisList] = useState([]);
  const history = useHistory();

  const tags = useSelector(state => state.application.tags);
  const equipamentos = useSelector(state => state.application.equipaments);
  const variaveis = useSelector(state => state.application.variables);

  const classes = useStyles();

  const mystyle = {
    width: 250,
    height: 200,
    margin: 'auto',
  };

  const checkTagTree = useCallback((tagsId, searchedTagId) => {
    let check = false;
    if (tagsId.includes(searchedTagId)) return true;

    tagsId.map((tagId) => {
      const realTag = tags.filter((i) => i.id === tagId)[0];
      if (realTag.parent_id !== null && !check) check = checkTagTree([realTag.parent_id], searchedTagId);
      return tagId;
    });

    return check;
  }, [tags]);

  const getTagTree = (tag) => {
    let fatherTags = [];
    tags.map((subTag) => {
      if (subTag.id === tag.parent_id) {
        let tagTree = [];
        if (subTag.parent_id !== null) {
          tagTree = [...tagTree, ...getTagTree(subTag)];
        }
        fatherTags = [...fatherTags, subTag, ...tagTree];
      }
      return tag;
    });
    return fatherTags;
  };

  const handleTagClick = (tag) => {
    const machineName = tag.tag_name.toLowerCase().replaceAll(' ', '-').normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const newUrl = `/busca-personalizada/listar/${tag.id}-${machineName}`;
    return history.push(newUrl);
  };

  useEffect(() => {
    const searchTagParam = props.location.pathname.split('/').slice(-1)[0].split('-')[0];
    const searchedTag = tags.filter((tag) => parseInt(tag.id) === parseInt(searchTagParam))[0];
    if (searchedTag) {
      const newListEquipamentos = equipamentos.filter((equipamento) => {
        return checkTagTree(equipamento.tags.map((tag) => tag.id), searchedTag.id);
      });
      const newListVariaveis = variaveis.filter((variavel) => {
        return checkTagTree(variavel.tags.map((tag) => tag.id), searchedTag.id);
      });

      const newEquipamentosList = newListEquipamentos.map((equipamento) => {
        const nestedTags = equipamento.tags.map((tag) => {
          const nestedTag = tags.filter((subTag) => subTag.id === tag.id);
          return nestedTag.length > 0 ? nestedTag[0] : {}
        });
        return {
          id: equipamento.id,
          name: equipamento.name,
          tags: nestedTags,
          consumo: [65, 80, 80, 91, 65],
        };
      });

      const newVariaveisList = newListVariaveis.map((variavel) => {
        const nestedTags = variavel.tags.map((tag) => {
          const nestedTag = tags.filter((subTag) => subTag.id === tag.id);
          return nestedTag.length > 0 ? nestedTag[0] : {}
        });
        return {
          id: variavel.id,
          name: variavel.name,
          tags: nestedTags,
        };
      });

      setVariaveisList(newVariaveisList);
      setEquipamentosList(newEquipamentosList);
    }

  }, [props.location.pathname,tags, equipamentos, variaveis]);

  return (
    <>
      {equipamentosList.length > 0 && <Card>
        <CardHeader title="Equipamentos" />
        <CardBody>
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Ações</TableCell>
                  <TableCell>Equipamento</TableCell>
                  <TableCell>Tags</TableCell>
                  <TableCell align="center">Consumo de energia</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {equipamentosList.map(equipamento => (
                  <TableRow key={equipamento.id}>
                    <TableCell><ActionsColumnFormatter /></TableCell>
                    <TableCell component="th" scope="row">{equipamento.name}</TableCell>
                    <TableCell>
                      {equipamento.tags.filter((tag) => !tag.system_var).map((tag) => {
                        const newLabel = getTagTree(tag).map((tag) => tag.tag_name).reverse();
                        newLabel.push(tag.tag_name);
                        return <Tag onClick={() => handleTagClick(tag)} title={newLabel.join(' > ')} key={tag.id} />
                      })}
                    </TableCell>
                    <TableCell align="center"> <div style={mystyle}><ChartBar range={[75, 80]} title={['Jan', 'Fev', 'Mar', 'Abr', 'Mai']} values={equipamento.consumo} /></div></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </CardBody>
      </Card>}

      {variaveisList.length > 0 && <Card>
        <CardHeader title="Variáveis" />
        <CardBody>
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Ações</TableCell>
                  <TableCell>Variável</TableCell>
                  <TableCell>Tags</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {variaveisList.map(variavel => (
                  <TableRow key={variavel.id}>
                    <TableCell><ActionsColumnFormatter /></TableCell>
                    <TableCell component="th" scope="row">{variavel.name}</TableCell>
                    <TableCell>
                      {variavel.tags.filter((tag) => !tag.system_var).map((tag) => {
                        const newLabel = getTagTree(tag).map((tag) => tag.tag_name).reverse();
                        newLabel.push(tag.tag_name);
                        return <Tag onClick={() => handleTagClick(tag)} title={newLabel.join(' > ')} key={tag.id} />
                      })}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </CardBody>
      </Card>}
    </>
  );
}
