/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Pagination from '../components/Pagination/Pagination';
import Paper from '@material-ui/core/Paper';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../_metronic/_partials/controls";
import Loading from '../components/Loading/Loading';
import Api from '../services/Api';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  centerRow: {
    textAlign: 'center',
  },
  textField: {
    flex: 1,
    marginLeft: '15px',
  },
}));

export default function HistoricoSistema() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [busca, setBusca] = useState({
    per_page: 10,
    current_page: 1,
    date_in: '',
    date_out: '',
    date_in_raw: '',
    date_out_raw: ''
  });
  const { query } = useLocation();

  const classes = useStyles();

  const handleBuscaLimpar = () => {
    setBusca({ per_page: busca.per_page, current_page: 1,  date_in: '', date_out: '', date_in_raw: '', date_out_raw: '' });
  };

  const handleBuscaDataInicio = (event) => {
    const date = format(new Date(event.target.value), 'dd/MM/yyyy HH:mm:ss');
    setBusca({ ...busca, current_page: 1, date_in: date, date_in_raw: event.target.value });
  };

  const handleBuscaDataFim = (event) => {
    const date = format(new Date(event.target.value), 'dd/MM/yyyy HH:mm:ss');
    setBusca({ ...busca, current_page: 1, date_out: date, date_out_raw: event.target.value });
  };

  useEffect(() => {
    const fetchInitalData = async () => {
      setLoading(true);
      if (query && query.user && !busca.filter_users) {
        setBusca({ ...busca, current_page: 1, filter_users: [query.user.id] });
      }
      const { status, data } = await Api.get('/historic-system', { params: busca });
      if (status !== 200) {
        return alert('teve um problema');
      }

      setList(data);
      setLoading(false);
    };
    fetchInitalData();
  }, [busca]);

  if (loading) return <Loading />

  return (
    <>
      <Card>
        <CardHeader title="Histórico de ações">
          <CardHeaderToolbar>
            <TextField
              id="standard-date-in"
              className={classes.textField}
              onChange={handleBuscaDataInicio}
              value={busca.date_in_raw}
              type="date"
            />
            <TextField
              id="standard-date-out"
              className={classes.textField}
              onChange={handleBuscaDataFim}
              value={busca.date_out_raw}
              type="date"
            />
            {((busca.filter_groups && busca.filter_groups.length > 0) || (busca.filter_parent && busca.filter_parent.length > 0) || busca.date_in !== '' || busca.date_out !== '') && <button
              type="button"
              className="btn btn-secondary"
              onClick={handleBuscaLimpar}
            >
              Limpar Busca
            </button>}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Ação</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Usuário</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.data && list.data.length > 0 ? list.data.map(item => (
                  <TableRow key={item.id}>
                    <TableCell component="th" scope="row">{item.id}</TableCell>
                    <TableCell>{item.action}</TableCell>
                    <TableCell>{format(new Date(item.created_at), 'dd/MM/yyyy HH:mm:ss')}</TableCell>
                    <TableCell>{item.user_name || 'Sistema'}</TableCell>
                  </TableRow>
                )) :  <TableRow><TableCell colSpan={4} style={{ textAlign: 'center' }}>Nenhuma informação encontrada</TableCell></TableRow>}
              </TableBody>
              <TableFooter>
                {list.pagination &&
                  <TableRow><Pagination busca={busca} setBusca={setBusca} colSpan={4} {...list.pagination} /></TableRow>
                }
              </TableFooter>
            </Table>
          </Paper>
        </CardBody>
      </Card>
    </>
  );
}
