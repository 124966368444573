/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import { FormControlLabel, Switch } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { AutocompleteTask, Conditional, Delay, Tag, Variable } from '../../../../app/components/InsertOrEditTask';
import Autocomplete from '../autocomplete/Autocomplete';

const typeSequenceActions = [
  {
    id: 1,
    label: 'Variável',
    value: 'variable',
  },
  {
    id: 2,
    label: 'Tag',
    value: 'tag',
  },
  {
    id: 3,
    label: 'Tarefa',
    value: 'task',
  },
  {
    id: 4,
    label: 'Delay',
    value: 'delay',
  },
];

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  form: {
    padding: theme.spacing(3),
  },
  title: {
    fontSize: '1.1rem',
    fontWeight: 'normal',
  },
  button: {
    margin: theme.spacing(1),
  },
  textField: {
    marginBottom: theme.spacing(3),
    '&.width-48': {
      width: '48%',
      marginTop: theme.spacing(3),
    },
    '&.no-margin-top': {
      marginTop: 0,
    },
    '@media (max-width: 1024px)': {
      width: '100%',
      '&.width-48': {
        width: '100%',
      },
    },
  },
  filterText: {
    color: 'blue',
  },
  closeFilter: {
    color: 'red',
    fontWeight: 'bold',
  },
  select : {
    marginLeft: 26,
    width: '48%',
    zIndex: 100,
    '@media (max-width: 1024px)': {
      width: '100%',
      marginLeft: 0,
    },
  },
}));

const TarefasModal = ({ open, setOpen,  actions, handleAddActions, conditional, handleChange, handleChangeSelect, handleEnabledSwitch }) => {
  const classes = useStyles();

  const identifyTypeOfAction = () => {
    const orderAction = actions.addedActions.length + 1;

    if(actions.action_type.value === 'tag') {
      return handleAddActions({
        order: orderAction,
        action_type: actions.action_type.value,
        name: `${actions.tag_id.label} ${actions.tag_id.id} ${actions.value}`,
        label: `${actions.tag_id.label}`,
        tag_id: actions.tag_id.id,
        value: actions.value,
        valueLabel: actions.value,
        condition_value: actions.condition_value ? actions.condition_value : actions.condition_variable_value_id && actions.condition_variable_value_id.label,
        condition_operation: actions.condition_operation ? actions.condition_operation.value : null,
        condition_equipment_id: actions.condition_equipment_id ? actions.condition_equipment_id.value : null,
        condition_variable_id: actions.condition_variable_id ? actions.condition_variable_id.value : null,
        condition_variable_value_id: actions.condition_variable_value_id ? actions.condition_variable_value_id.id : null,
      });
    }

    if(actions.action_type.value === 'variable') {
      return handleAddActions({
        order: orderAction,
        action_type: actions.action_type.value,
        name: `${actions.variable_id.label} ${actions.variable_value_id.value}`,
        variable_id: actions.variable_id.value,
        label: actions.variable_id.label,
        value: actions.value ? actions.value : actions.variable_value_id.label,
        variable_value_id: actions.variable_value_id.id,
        valueLabel: actions.variable_value_id ? actions.variable_value_id.label : actions.value,
        condition_value: actions.condition_value ? actions.condition_value : actions.condition_variable_value_id && actions.condition_variable_value_id.label,
        condition_operation: actions.condition_operation ? actions.condition_operation.value : null,
        condition_equipment_id: actions.condition_equipment_id ? actions.condition_equipment_id.value : null,
        condition_variable_id: actions.condition_variable_id ? actions.condition_variable_id.value : null,
        condition_variable_value_id: actions.condition_variable_value_id ? actions.condition_variable_value_id.id : null,
      });
    }

    if(actions.action_type.value === 'delay') {
      return handleAddActions({
        order: orderAction,
        action_type: actions.action_type.value,
        name: `${actions.action_type.value} ${actions.value}`,
        value: actions.value,
        valueLabel: actions.value,
        condition_value: actions.condition_value ? actions.condition_value : actions.condition_variable_value_id && actions.condition_variable_value_id.label,
        condition_operation: actions.condition_operation ? actions.condition_operation.value : null,
        condition_equipment_id: actions.condition_equipment_id ? actions.condition_equipment_id.value : null,
        condition_variable_id: actions.condition_variable_id ? actions.condition_variable_id.value : null,
        condition_variable_value_id: actions.condition_variable_value_id ? actions.condition_variable_value_id.id : null,
      });
    }

    if(actions.action_type.value === 'task') {
      return handleAddActions({
        order: orderAction,
        action_type: actions.action_type.value,
        name: `${actions.action_task_id.label} ${actions.action_task_id.value} ${actions.task_action_type.label}`,
        action_task_id: actions.action_task_id.value,
        label: actions.action_task_id.label,
        task_action_type: actions.task_action_type.value,
        valueLabel: actions.task_action_type.label,
        condition_value: actions.condition_value ? actions.condition_value : actions.condition_variable_value_id && actions.condition_variable_value_id.label,
        condition_operation: actions.condition_operation ? actions.condition_operation.value : null,
        condition_equipment_id: actions.condition_equipment_id ? actions.condition_equipment_id.value : null,
        condition_variable_id: actions.condition_variable_id ? actions.condition_variable_id.value : null,
        condition_variable_value_id: actions.condition_variable_value_id ? actions.condition_variable_value_id.id : null,
      });
    }
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    identifyTypeOfAction();
    setOpen(false);
  };

  const closeModal = () => {
    handleAddActions('', 'close');
    setOpen(false);
  };

  return (
    <Modal show={open} size="lg" onHide={closeModal} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Ações</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div>
            <Autocomplete
              options={typeSequenceActions}
              value={typeSequenceActions.find(item => item.value === actions.action_type) }
              onChange={handleChangeSelect('action_type', 'actions')}
              label="Tipo"
              required
            />
          </div>
            {actions.action_type.value === 'variable' &&
              <>
                <Variable
                  actions={actions}
                  handleChange={handleChange}
                  handleChangeSelect={handleChangeSelect}
                />
              </>
            }
            {actions.action_type.value === 'tag' &&
              <>
                <Tag
                  actions={actions}
                  handleChange={handleChange}
                  handleChangeSelect={handleChangeSelect}
                />
              </>
            }

            { actions.action_type.value === 'task' && <>
              <AutocompleteTask
                actions={actions}
                handleChangeSelect={handleChangeSelect}
              />

            </>}
            { actions.action_type.value === 'delay' &&
              <Delay
                className={`${classes.textField} no-margin-top`}
                actions={actions}
                handleChange={handleChange}
              />
            }
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <FormControlLabel
                control={<Switch checked={actions.enableConditional} onChange={handleEnabledSwitch('enableConditional', 'actions')} />}
                label="Incluir condicional?"
              />
            </div>
            { actions.enableConditional &&
            <>
              <Conditional
                actions={actions}
                handleChange={handleChange}
                handleChangeSelect={handleChangeSelect}
              />
            </>
            }
        </Modal.Body>

        <Modal.Footer>
          <Button variant="contained" color="secondary" className={classes.button} onClick={() => closeModal()}>Fechar</Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            type="submit"
          >
            Adicionar Ação
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
};

export default TarefasModal;
